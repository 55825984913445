<template>
  <!-- Меню онбординга (только пункты которые относятся к созданию нового проекта) -->
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
    v-if="activeProjectId === null || activeProjectId === undefined"
  >
    <a-menu-item @click="toggleCollapsed" key="vibes_home">
      <router-link to="/">
        <sdFeatherIcons type="home" />
        <span>
          Главная страница
        </span>
      </router-link>
    </a-menu-item>

<!--    <a-menu-item @click="toggleCollapsed" key="new_project">-->
<!--      <router-link to="/new_project">-->
<!--        <sdFeatherIcons type="plus-circle" />-->
<!--        <span>-->
<!--          Создать проект-->
<!--        </span>-->
<!--      </router-link>-->
<!--    </a-menu-item>-->

<!--    <a-menu-item-group key="old">-->
<!--      <template v-slot:title>-->
<!--        <p class="sidebar-nav-title">Помощь и поддержка</p>-->
<!--      </template>-->

<!--      <a-menu-item @click="toggleCollapsed" key="marathon">-->
<!--        <router-link to="/marathon">-->
<!--          <sdFeatherIcons type="book" />-->
<!--          <span>-->
<!--            Бесплатный марафон-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--      <a-menu-item @click="toggleCollapsed" key="production">-->
<!--        <router-link to="/production">-->
<!--          <sdFeatherIcons type="star" />-->
<!--          <span>-->
<!--            Запуск под ключ-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--    </a-menu-item-group>-->


  </a-menu>


  <a-menu
  v-model:openKeys="openKeys"
  v-model:selectedKeys="selectedKeys"
  :mode="mode"
  :theme="darkMode ? 'dark' : 'light'"
  v-else>
    <a-menu-item @click="toggleCollapsed" key="vibes_home">
      <router-link to="/">
        <sdFeatherIcons type="home" />
        <span>
          Главная страница
        </span>
      </router-link>
    </a-menu-item>

      <a-menu-item @click="toggleCollapsed" key="chat">
          <router-link to="/chat/all/">
              <sdFeatherIcons type="message-square" />
              <span>
          Диалоги
        </span>
          </router-link>
      </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="crm">
      <router-link to="/crm">
        <sdFeatherIcons type="columns" />
        <span>
          Канбан
        </span>
      </router-link>
    </a-menu-item>

    <!--<a-menu-item @click="toggleCollapsed" key="test">
      <router-link to="/settings/test">
        <sdFeatherIcons type="activity" />
        <span>
          Аналитика
        </span>
      </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="test">
      <router-link to="/settings/test">
        <sdFeatherIcons type="send" />
        <span>
          Запустить рассылку
        </span>
      </router-link>
    </a-menu-item> -->


    <a-menu-item-group key="settings">
      <template v-slot:title>
        <p class="sidebar-nav-title">Настройки</p>
      </template>

      <a-menu-item @click="toggleCollapsed" key="settings/users/manage">
        <router-link to="/settings/users/manage">
          <sdFeatherIcons type="settings" />
          <span>
            Добавление сотрудников
          </span>
        </router-link>
      </a-menu-item>

      <a-menu-item @click="toggleCollapsed" key="users">
        <router-link to="/users">
          <sdFeatherIcons type="users" />
          <span>
          База пользователей
        </span>
        </router-link>
      </a-menu-item>



      <!--      <a-menu-item @click="toggleCollapsed" key="settings">-->
<!--        <router-link to="/settings">-->
<!--          <sdFeatherIcons type="settings" />-->
<!--          <span>-->
<!--            Общие настройки-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--      <a-menu-item @click="toggleCollapsed" key="settings/packages">-->
<!--        <router-link to="/settings/packages">-->
<!--          <sdFeatherIcons type="package" />-->
<!--          <span>-->
<!--            Тарифы-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--      <a-menu-item @click="toggleCollapsed" key="settings/chatbot">-->
<!--        <router-link to="/settings/chatbot">-->
<!--          <sdFeatherIcons type="terminal" />-->
<!--          <span>-->
<!--            Чат-бот-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->


<!--      <a-menu-item @click="toggleCollapsed" key="settings/channels">-->
<!--        <router-link to="/settings/channels">-->
<!--          <sdFeatherIcons type="inbox" />-->
<!--          <span>-->
<!--            Закрытые каналы-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->


<!--      <a-menu-item @click="toggleCollapsed" key="settings/flows">-->
<!--        <router-link to="/settings/flows">-->
<!--          <sdFeatherIcons type="edit-3" />-->
<!--          <span>-->
<!--            Прогрев-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--      <a-menu-item @click="toggleCollapsed" key="settings/payments">-->
<!--        <router-link to="/settings/payments">-->
<!--          <sdFeatherIcons type="credit-card" />-->
<!--          <span>-->
<!--            Платежные системы-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->
    </a-menu-item-group>


<!--    <a-menu-item-group key="old">-->
<!--      <template v-slot:title>-->
<!--        <p class="sidebar-nav-title">Помощь и поддержка</p>-->
<!--      </template>-->

<!--      <a-menu-item @click="toggleCollapsed" key="support">-->
<!--        <router-link to="/support">-->
<!--          <sdFeatherIcons type="help-circle" />-->
<!--          <span>-->
<!--            Поддержка-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--      <a-menu-item @click="toggleCollapsed" key="faq">-->
<!--        <router-link to="/faq">-->
<!--          <sdFeatherIcons type="book" />-->
<!--          <span>-->
<!--            База знаний-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->


<!--      <a-menu-item @click="toggleCollapsed" key="marathon">-->
<!--        <router-link to="/marathon">-->
<!--          <sdFeatherIcons type="send" />-->
<!--          <span>-->
<!--            Бесплатный марафон-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--      <a-menu-item @click="toggleCollapsed" key="production">-->
<!--        <router-link to="/production">-->
<!--          <sdFeatherIcons type="star" />-->
<!--          <span>-->
<!--            Запуск под ключ-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->


<!--      &lt;!&ndash; <a-menu-item @click="toggleCollapsed" key="old_dashboard">-->
<!--        <router-link to="/old/business">-->
<!--          <sdFeatherIcons type="settings" />-->
<!--          <span>-->
<!--            Примеры компонентов-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item> &ndash;&gt;-->
<!--    </a-menu-item-group>-->

<!--    <a-menu-item-group key="old">-->
<!--      <template v-slot:title>-->
<!--        <p class="sidebar-nav-title">Оплата подписки</p>-->
<!--      </template>-->

<!--      <a-menu-item @click="toggleCollapsed" key="subscription">-->
<!--        <router-link to="/subscription">-->
<!--          <sdFeatherIcons type="dollar-sign" />-->
<!--          <span>-->
<!--            Подписка на сервис-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </a-menu-item>-->

<!--    </a-menu-item-group>-->


  </a-menu>
</template>
<script>
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import versions from '../demoData/changelog.json';

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref('inline');
    const { events } = toRefs(props);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value;

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ['home'],
      openKeys: ['dashboard'],
      preOpenKeys: ['dashboard'],
    });

    const activeProjectId = ref(null);

    activeProjectId.value = localStorage.getItem('activeProjectId') || null;


    watchEffect(() => {
      console.log(router.value.matched);
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          //
          // это я что-то переписал чтобы подсвечивало активным пункт меню даже если на подуровень ниже провалились
          // пример: страница /settings/payments и страница /settings/payments/yoomoney
          // роуты разные а пункт в меню к ним один относится
          //
          state.selectedKeys = [router.value.matched[2].name, router.value.matched[1].name];
          state.openKeys = [router.value.matched[2].name, router.value.matched[1].name, router.value.matched[0].name];
          state.preOpenKeys = [router.value.matched[1].name];
          //
          // так было в оригинале:
          //
          // state.selectedKeys = [router.value.matched[2].name];
          // state.openKeys = [router.value.matched[1].name];
          // state.preOpenKeys = [router.value.matched[1].name];
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else {
          state.selectedKeys = [router.value.matched[1].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        }
      }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      versions,
      activeProjectId
    };
  },
};
</script>
