<template>
  <sdPageHeader title="Платежная система Qiwi"> </sdPageHeader>
  <Main>
    <sdModal
      :type="localState.modalType"
      title="Отключить платежную систему"
      :visible="localState.visible"
      :footer="null"
      :onCancel="handleCancel"
    >
      <div class="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
              <p>Подтвердите, что вы действительно хотите отключить платежную систему Qiwi.</p>

              <p><b>После этого мы удалим данные вашей платежной системы из нашей базы и этот способ оплаты станет недоступен для пользователей.</b></p>

              <p><b>Если вы захотите перепривязать платежную систему, процесс активации придется пройти заново</b></p>

              <sdButton htmlType="submit" size="default" type="danger" key="submit" @click="confirmDelete" :disabled="isLoading">
                {{ isLoading ? 'Загрузка...' : 'Подтвердить отключение'}}
              </sdButton>
            </a-form>
          </BasicFormWrapper>
        </AddUser>
      </div>
    </sdModal>

    <a-row :gutter="25">
      <a-col :sm="16" :xs="16">
        <sdCards>
          <!-- Статус: new // Показывается при первом подключении системы когда настройки не установлены -->
          <a-form
            layout="vertical"
            labelAlign="left"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :model="formState"
            ref="formRefStep1" :rules="rulesStep1"
          >
            <h3>Инструкция по настройке Qiwi:</h3>

            <p>1. Нужен кошелек со статусом "Основной" или "Профессиональный". Верификацию можно пройти онлайн для граждан РФ. <a href="https://qiwi.com/support/information/subject5/identifikatsiya-v-qiwi-koshelke" target="_blank">Инструкция как пройти верификацию.</a></p>

            <p>2. Зайдите на страницу <a target="_blank" href="https://p2p.qiwi.com/">p2p.qiwi.com</a> и нажмите на кнопку "Начать прием платежей"</p>

            <p>3. Перейдите на вкладку "API" и нажмите на кнопку "Создать пару ключей и настроить"</p>

            <p>4. <b>Название пары</b> - введите любое значение, например название вашего проекта</p>

            <p>5. <b>Обязательно поставьте галочку</b> напротив пункта "Использовать эту пару ключей для серверных уведомлений об изменении статусов счетов"</p>

            <a-form-item name="result_url" label='6. Скопируйте этот URL в поле "URL сервера для уведомлений"'>
              <a-input v-model:value="formState.result_url" placeholder="" disabled/>
            </a-form-item>

            <p>7. Нажмите на кнопку "Создать" в интерфейсе Qiwi</p>

            <a-form-item name="public_key" label='8. Скопируйте публичный ключ в это поле:'>
              <a-input v-model:value="formState.public_key" placeholder="Например: 48e7qUxn9T7RyYE1MVZswX1FRSbE6iyCj2gCRwwF3Dnh5XrasNTx3BGPiMsyXQFNKQhvukniQG8RTVhYm3iPs8h2XwP54CT2G2esiFNALr1W957vFoZH2DnciKLsWzcH2HgZGt2V5z6Zeob7enEEpeECE6xsVKzeZ5ragov2dEQazHhk4sCgqqK8QSs" />
            </a-form-item>


            <a-form-item name="private_key" label='9. Скопируйте секретный ключ в это поле:'>
              <a-input v-model:value="formState.private_key" placeholder="Например: eyJ2ZXJzaW9uIjoiUDJQIiwiZGF0YSI6eyJwYXlpbl9tZXJjaGFudF9zaXRlX3VpZCI6ImhrNXFzMy0wMCIsInVzZXJfaWQiOiI3OTk5NDQyMTg4MyIsInNlY3JldCI6ImM3Y2E3YTliNDI5N2Q4ODI5Mjc0YWU3MTgzZjAxZWU3ZTg4MDhiZWE2YzNkODk2MzFkNjkzOGZlMmYyNDA1Nz" />
            </a-form-item>

            <p>10. Сохраните настройки 👇</p>

            <a-row>
              <a-col :lg="{ span: 16, offset: 0 }" :md="{ span: 15, offset: 0 }" :xs="{ span: 24, offset: 0 }">
                <div class="sDash_form-action">
                  <sdButton class="sDash_form-action__btn" type="primary" size="large" @click="saveNew" :disabled="isLoading">
                    {{ isLoading ? 'Загрузка...' : 'Сохранить' }}
                  </sdButton>

                  <sdButton class="sDash_form-action__btn" type="danger" outlined size="large" @click="deleteMethod" style="margin-left: 20px;" v-if="qiwiSetupState == 'active'">
                    Удалить платежную систему
                  </sdButton>

                </div>
              </a-col>
            </a-row>
          </a-form>





        </sdCards>
      </a-col>
      <a-col :sm="8" :xs="8">
        <!-- @todo: Тут будет ссылка на видео / инструкцию по подключению платежки -->
      </a-col>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>

</style>
<script>
import { BasicFormWrapper, Main } from '../../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';
import { useRouter } from 'vue-router';

const Qiwi = {
  name: 'Qiwi',
  components: {
    BasicFormWrapper,
    PlusOutlined,
    MinusOutlined,
    Main,
    SpinerWraperStyle
  },
  setup() {
    const { state, dispatch } = useStore();

    const router = useRouter();


    const systemName = 'qiwi';

    const isLoading = ref(false);

    const formState = reactive({
      account_number: '',
      api_secret: '',
      result_url: ''
    });

    const formRefStep1 = ref();
    const rulesStep1 = {
      public_key: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 189,
          max: 189,
          message: 'Публичный ключ состоит из 189 символов. Внимательно проверьте, правильно ли вы скопировали его из Qiwi.',
          trigger: 'blur',
        }
      ],
      private_key: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 219,
          max: 219,
          message: 'Секретный ключ состоит из 219 символов. Внимательно проверьте, правильно ли вы скопировали его из Qiwi.',
          trigger: 'blur',
        }
      ]
    };

    formState.result_url = process.env.VUE_APP_WEBHOOK_BASE_URL+'payment/qiwi/result/'+activeProjectId();

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const labelCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };
    const wrapperCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };

    const QiwiResultUrl = ref('');

    // если только зашел на страницу - new
    // если нажал сохранить - pending
    // если все настройки сохранились - active
    const qiwiSetupState = ref('new');

    const qiwiSettings = ref({});

    axios.get('/settings/payments/system', {params: {
      project_id: activeProjectId(),
      system: systemName,
    }}).then(response => {
      qiwiSettings.value = response.data.settings;
      qiwiSetupState.value = response.data.state;

      if(response.data.settings.settings.hasOwnProperty('private_key'))
        formState.private_key = response.data.settings.settings.private_key;

      if(response.data.settings.settings.hasOwnProperty('public_key'))
        formState.public_key = response.data.settings.settings.public_key;
    });

    const saveNew = function() {
      formRefStep1.value
        .validate()
        .then(() => {
          isLoading.value = true;
          axios
            .post('/settings/payments/qiwi/save', {...formState, project_id: activeProjectId()})
            .then(response => {
              console.log(response);

              qiwiSettings.value = response.data.settings;
              qiwiSetupState.value = response.data.state;
            })
            .catch(error => {
              console.log('error :>> ', error.response)
            })
            .finally(() => {
              isLoading.value = false;
            })
        }).catch(error => {
          // alert('Validation not passed!');
          console.log('error', error);
        });
    };



    const deleteMethod = function() {
      localState.visible = true;
    };

    const handleCancel = function() {
      localState.visible = false;
    };

    const confirmDelete = function() {
      axios.post('/settings/payments/system/delete', {
        project_id: activeProjectId(),
        system: systemName
      }).then(response => {
        localState.visible = false;
        router.push('/settings/payments');
      });
    };

    return {
      isLoading,

      qiwiSetupState,

      qiwiSettings,

      saveNew,
      deleteMethod,
      handleCancel,
      confirmDelete,

      formState,
      rulesStep1,
      formRefStep1,

      labelCol,
      wrapperCol,

      localState
    };
  },
};

export default Qiwi;
</script>
