importScripts("https://www.gstatic.com/firebasejs/8.2.6/firebase-app.js");
importScripts("https://www.gstatic.com/firebasejs/8.2.6/firebase-messaging.js");

try {
    firebase.initializeApp({
      apiKey: "AIzaSyBAwtiqiMG_2VPVzPfBlnK5imb_NHSKNwU",
      authDomain: "tgcrm-66291.firebaseapp.com",
      projectId: "tgcrm-66291",
      storageBucket: "tgcrm-66291.appspot.com",
      messagingSenderId: "1066948724179",
      appId: "1:1066948724179:web:3dd6589444b3c8db751b26"
    });
    var messaging = firebase.messaging();

    self.addEventListener("notificationclick", function (event) {
        const target = event.notification.data.click_action || "/";
        event.notification.close();

        // This looks to see if the current is already open and focuses if it is
        event.waitUntil(
            clients
                .matchAll({
                    type: "window",
                    includeUncontrolled: true,
                })
                .then(function (clientList) {
                    // clientList always is empty?!
                    for (var i = 0; i < clientList.length; i++) {
                        var client = clientList[i];
                        if (client.url === target && "focus" in client) {
                            return client.focus();
                        }
                    }

                    return clients.openWindow(target);
                })
        );
    });

    messaging.onBackgroundMessage((payload) => {
        console.log(
            "[firebase-messaging-sw.js] Received background message ",
            payload
        );
        // Customize notification here
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
            body: payload.notification.body,
        };

        self.registration.showNotification(
            notificationTitle,
            notificationOptions
        );
    });
} catch (e) {
    console.log(e);
}
