<template>
  <sdPageHeader title="Платежная система YooMoney"> </sdPageHeader>
  <Main>
    <sdModal
      :type="localState.modalType"
      title="Отключить платежную систему"
      :visible="localState.visible"
      :footer="null"
      :onCancel="handleCancel"
    >
      <div class="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
              <p>Подтвердите, что вы действительно хотите отключить платежную систему YooMoney.</p>

              <p><b>После этого мы удалим данные вашей платежной системы из нашей базы и этот способ оплаты станет недоступен для пользователей.</b></p>

              <p><b>Если вы захотите перепривязать платежную систему, процесс активации придется пройти заново</b></p>

              <sdButton htmlType="submit" size="default" type="danger" key="submit" @click="confirmDelete" :disabled="isLoading">
                {{ isLoading ? 'Загрузка...' : 'Подтвердить отключение'}}
              </sdButton>
            </a-form>
          </BasicFormWrapper>
        </AddUser>
      </div>
    </sdModal>

    <a-row :gutter="25">
      <a-col :sm="16" :xs="16">
        <sdCards>
          <!-- Статус: new // Показывается при первом подключении системы когда настройки не установлены -->
          <a-form
            layout="vertical"
            labelAlign="left"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :model="formState"
            ref="formRefStep1" :rules="rulesStep1"
            v-if="yoomoneySetupState == 'new'"
          >
            <h3>Инструкция по настройке YooMoney:</h3>

            <p>1. Нужен кошелек со статусом "Идентифицированный". Верификацию можно пройти онлайн через Сбер ID или в офисах Мегафон и Связной. <a href="https://yoomoney.ru/id/methods" target="_blank">Инструкция как пройти верификацию.</a></p>

            <p>2. Зайдите в кошелек и перейдите на страницу <a target="_blank" href="https://yoomoney.ru/transfer/myservices/http-notification">yoomoney.ru/transfer/myservices/http-notification</a></p>

            <a-form-item name="account_number" label='3. Скопируйте номер кошелька из YooMoney (можно посмотреть нажав на аватар в правом верхнем углу): '>
              <a-input v-model:value="formState.account_number" placeholder="410018869631111" />
            </a-form-item>


            <a-form-item name="api_secret" label='4. Скопируйте поле "Секрет для проверки подлинности" из YooMoney:'>
              <a-input v-model:value="formState.api_secret" placeholder="Например: Hr7z7BSUsHkAHi7V7ZvRfVte" />
            </a-form-item>

            <a-row>
              <a-col :lg="{ span: 16, offset: 0 }" :md="{ span: 15, offset: 0 }" :xs="{ span: 24, offset: 0 }">
                <div class="sDash_form-action">
                  <sdButton class="sDash_form-action__btn" type="primary" size="large" @click="saveNew" :disabled="isLoading">
                    {{ isLoading ? 'Загрузка...' : 'Продолжить' }}&nbsp;&nbsp;<sdFeatherIcons type="arrow-right" size="16" />
                  </sdButton>
                </div>
              </a-col>
            </a-row>
          </a-form>

          <a-form
            layout="vertical"
            labelAlign="left"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :model="formState2"
            v-if="yoomoneySetupState == 'pending'">
            <h3>Остался последний шаг!</h3>

            <p>1. Вернитесь в YooMoney на эту страницу: <a target="_blank" href="https://yoomoney.ru/transfer/myservices/http-notification">yoomoney.ru/transfer/myservices/http-notification</a></p>

            <a-form-item name="api_secret" label='2. Скопируйте эту ссылку 👇 в поле "Куда отправлять (URL сайта)" и сохраните настройки'>
              <a-input v-model:value="formState2.result_url" placeholder="" disabled />
            </a-form-item>

            <p>3. Нажмите на кнопку "Протестировать" в YooMoney.</p>

            <p>4. Если вы все сделали правильно, в течение 30 сек. автоматически проверим подключение платежки и выведем уведомление.</p>

            <br><br>

            <small>Если что-то не получается, попробуйте <a href="#" @click="getBack">вернуться назад</a> и перепроверить указанные настройки.</small>

          </a-form>

          <a-form
            layout="vertical"
            labelAlign="left"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :model="formState"
            :rules="rulesStep1"
            ref="formRefStep3"
            v-if="yoomoneySetupState == 'active'">
            <p>Настройки кошелька YooMoney редактируются на этой странице: <a target="_blank" href="https://yoomoney.ru/transfer/myservices/http-notification">yoomoney.ru/transfer/myservices/http-notification</a></p>

            <a-form-item name="account_number" label='Номер кошелька YooMoney'>
              <a-input v-model:value="formState.account_number" placeholder="410018869631111" />
            </a-form-item>

            <a-form-item name="api_secret" label='Секрет для проверки подлинности YooMoney'>
              <a-input v-model:value="formState.api_secret" placeholder="Например: Hr7z7BSUsHkAHi7V7ZvRfVte" />
            </a-form-item>

            <a-form-item label='URL для уведомлений о платежах (нужно вставить в настройки YooMoney)'>
              <a-input v-model:value="formState2.result_url" placeholder="" disabled />
            </a-form-item>

            <a-row>
              <a-col :lg="{ span: 16, offset: 0 }" :md="{ span: 15, offset: 0 }" :xs="{ span: 24, offset: 0 }">
                <div class="sDash_form-action">
                  <sdButton class="sDash_form-action__btn" type="primary" size="large" @click="saveActive" :disabled="isLoading">
                    {{ isLoading ? 'Загрузка...' : 'Сохранить настройки' }}
                  </sdButton>

                  <sdButton class="sDash_form-action__btn" type="danger" outlined size="large" @click="deleteMethod" style="margin-left: 20px;">
                    Удалить платежную систему
                  </sdButton>

                </div>
              </a-col>
            </a-row>
          </a-form>


        </sdCards>
      </a-col>
      <a-col :sm="8" :xs="8">
        <!-- @todo: Тут будет ссылка на видео / инструкцию по подключению платежки -->
      </a-col>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>

</style>
<script>
import { BasicFormWrapper, Main } from '../../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';
import { useRouter } from 'vue-router';

const Yoomoney = {
  name: 'Yoomoney',
  components: {
    BasicFormWrapper,
    PlusOutlined,
    MinusOutlined,
    Main,
    SpinerWraperStyle
  },
  setup() {
    const { state, dispatch } = useStore();

    const router = useRouter();


    const systemName = 'yoomoney';

    const isLoading = ref(false);

    const formState = reactive({
      account_number: '',
      api_secret: '',
    });

    const formRefStep1 = ref();
    const rulesStep1 = {
      account_number: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 12,
          max: 18,
          message: 'Номер кошелька YooMoney обычно состоит из 12-16 символов. Внимательно проверьте реквизиты, которые вы указали.',
          trigger: 'blur',
        }
      ],
      api_secret: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 24,
          max: 24,
          message: 'Секретный ключ состоит из 24 символов. Внимательно проверьте, правильно ли вы скопировали его из YooMoney.',
          trigger: 'blur',
        }
      ]
    };

    const formState2 = reactive({
      result_url: '',
    });

    const formRefStep3 = ref();

    formState2.result_url = process.env.VUE_APP_WEBHOOK_BASE_URL+'payment/yoomoney/result/'+activeProjectId();

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const labelCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };
    const wrapperCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };

    const yoomoneyResultUrl = ref('');

    // если только зашел на страницу - new
    // если нажал сохранить - pending
    // если все настройки сохранились - active
    const yoomoneySetupState = ref('new');

    const yoomoneySettings = ref({});

    axios.get('/settings/payments/system', {params: {
      project_id: activeProjectId(),
      system: systemName,
    }}).then(response => {
      yoomoneySettings.value = response.data.settings;
      yoomoneySetupState.value = response.data.state;

      if(response.data.settings.settings.hasOwnProperty('account_number'))
        formState.account_number = response.data.settings.settings.account_number;

      if(response.data.settings.settings.hasOwnProperty('api_secret'))
        formState.api_secret = response.data.settings.settings.api_secret;
    });

    const saveNew = function() {
      formRefStep1.value
        .validate()
        .then(() => {
          isLoading.value = true;
          axios
            .post('/settings/payments/yoomoney/save', {...formState, project_id: activeProjectId()})
            .then(response => {
              console.log(response);

              yoomoneySettings.value = response.data.settings;
              yoomoneySetupState.value = response.data.state;
            })
            .catch(error => {
              console.log('error :>> ', error.response)
            })
            .finally(() => {
              isLoading.value = false;
            })
        }).catch(error => {
          // alert('Validation not passed!');
          console.log('error', error);
        });
    };

    const saveActive = function() {
      formRefStep3.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios
            .post('/settings/payments/yoomoney/save', {...formState, project_id: activeProjectId()})
            .then(response => {
              console.log(response);

              yoomoneySettings.value = response.data.settings;
              yoomoneySetupState.value = response.data.state;
            })
            .catch(error => {
              console.log('error :>> ', error.response)
            })
            .finally(() => {
              isLoading.value = false;
            })
        }).catch(error => {
          // alert('Validation not passed!');
          console.log('error', error);
        });

    };

    const getBack = function() {
      yoomoneySetupState.value = 'new';
    };

    var autoupdateStatus = setInterval(function() {
      if(yoomoneySetupState.value != 'pending')
        return;

      axios.get('/settings/payments/system', {params: {
        project_id: activeProjectId(),
        system: systemName,
      }}).then(response => {
        if(response.data.state == 'active' && yoomoneySetupState.value == 'pending') {
          alert('Способ приема платежей YooMoney активирован!');

          yoomoneySetupState.value = 'active';
          yoomoneySettings.value = response.data.settings;

          clearInterval(autoupdateStatus);
        }
      });
    }, 5000);

    const deleteMethod = function() {
      localState.visible = true;
    };

    const handleCancel = function() {
      localState.visible = false;
    };

    const confirmDelete = function() {
      axios.post('/settings/payments/system/delete', {
        project_id: activeProjectId(),
        system: systemName
      }).then(response => {
        localState.visible = false;
        router.push('/settings/payments');
      });
    };

    return {
      isLoading,

      yoomoneySetupState,

      yoomoneySettings,

      saveNew,
      getBack,
      saveActive,
      deleteMethod,
      handleCancel,
      confirmDelete,

      formState,
      rulesStep1,
      formRefStep1,

      formState2,

      formRefStep3,

      labelCol,
      wrapperCol,

      localState
    };
  },
};

export default Yoomoney;
</script>
