import Styled from 'vue3-styled-components';


const BannerWrapper = Styled.figure`
    .ant-card{
        margin-bottom: 70px !important;
        @media only screen and (max-width: 1199px){
            margin-bottom: 50px !important;
        }
        @media only screen and (max-width: 991px){
            margin-bottom: 30px !important;
        }
    }
`;


const Figure3 = Styled.figure`
    // position: relative;
    min-height: 290px;
    margin-bottom: 0;
    padding: 18px 0 0;
    &.theme-wide{
        padding: 0;
        min-height: 100%;
        width: 100%;
        figcaption{
            h2{
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 12px;
            }
            p{
                margin-bottom: 18px;
            }
        }
    }
    &.theme-3{
        padding: 0px 0 30px;
        img{
            bottom: 0px;
        }
        figcaption{
            h2{
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 18px;
            }
        }
    }
    h2{
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 25px;
    }
    p{
        color: #fff;
        opacity: 1;
    }
    img {
        position: absolute;
        bottom: 0px;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0px;
        max-width: 350px;
        @media only screen and (max-width: 1599px){
            max-width: 150px;
        }
    }
    button{
        color: ${({ theme }) => theme['primary-color']} !important;
        &:focus{
            background-color: #fff !important;
        }
        &.ant-btn-lg{
            height: 44px;
            font-size: 15px;
            font-weight: 500;
        }
    }
`;

const Figure4 = Styled.figure`
    // position: relative;
    min-height: 290px;
    margin-bottom: 0;
    padding: 18px 0 0;
    &.theme-wide{
        padding: 0;
        min-height: 100%;
        width: 100%;
        figcaption{
            h2{
                font-size: 24px;
                line-height: 1.3;
                font-weight: 600;
                margin-bottom: 12px;
            }
            p{
                margin-bottom: 18px;
            }
        }
    }
    &.theme-4{
        padding: 0px 0 30px;
        img{
            bottom: 0px;
        }
        figcaption{
            h2{
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 18px;
            }
        }
    }
    h2{
        font-size: 24px;
        line-height: 1.3;
        font-weight: 600;
        color: #fff;
        margin-bottom: 25px;
    }
    p{
        color: #fff;
        opacity: 1;
    }
    img {
        position: absolute;
        bottom: 0px;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0px;
        max-width: 350px;
        @media only screen and (max-width: 1599px){
            max-width: 150px;
        }
    }
    button{
        color: ${({ theme }) => theme['primary-color']} !important;
        &:focus{
            background-color: #fff !important;
        }
        &.ant-btn-lg{
            height: 44px;
            font-size: 15px;
            font-weight: 500;
        }
    }
`;


const Figure5 = Styled.figure`
    // position: relative;
    min-height: 290px;
    margin-bottom: 0;
    padding: 18px 0 0;
    &.theme-wide{
        padding: 0;
        min-height: 100%;
        width: 100%;
        figcaption{
            h2{
                font-size: 24px;
                line-height: 1.3;
                font-weight: 600;
                margin-bottom: 12px;
            }
            p{
                margin-bottom: 18px;
            }
        }
    }
    &.theme-5{
        padding: 0px 0 30px;
        img{
            bottom: 0px;
        }
        figcaption{
            h2{
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 18px;
            }
        }
    }
    h2{
        font-size: 24px;
        line-height: 1.3;
        font-weight: 600;
        color: #fff;
        margin-bottom: 25px;
    }
    p{
        color: #fff;
        opacity: 1;
    }
    img {
        position: absolute;
        bottom: 0px;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0px;
        max-width: 350px;
        @media only screen and (max-width: 1599px){
            max-width: 150px;
        }
    }
    button{
        color: ${({ theme }) => theme['primary-color']} !important;
        &:focus{
            background-color: #fff !important;
        }
        &.ant-btn-lg{
            height: 44px;
            font-size: 15px;
            font-weight: 500;
        }
    }
`;

export {
  Figure3,
  Figure4,
  Figure5,
  BannerWrapper,
};
