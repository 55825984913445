const periodUnits = {
  days: 'дней',
  months: 'месяцев',
  years: 'лет',
  forever: 'навсегда'
};

const paymentMethods = {
  yoomoney: {
    name: 'YooMoney (для физ. лиц)',
    description: 'Самый быстрый способ запуска первых продаж. Нужен идентифицированный кошелек.',
    about_link: 'https://yoomoney.ru/',
    image: require('@/static/img/payment_methods/yoomoney.svg'),
    disabled: false
  },
  yookassa: {
    name: 'YooKassa (для ИП и юр. лиц)',
    description: '30+ методов оплаты. Обязательно заключение договора и установка онлайн кассы.',
    about_link: 'https://yookassa.ru/',
    image: require('@/static/img/payment_methods/yookassa.svg'),
    disabled: true
  },
  robokassa: {
    name: 'Robokassa (для ИП и юр. лиц)',
    description: '20+ методов оплаты. Обязательно заключение договора и установка онлайн кассы.',
    about_link: 'https://robokassa.ru/',
    image: require('@/static/img/payment_methods/robokassa.svg'),
    disabled: true
  },
  enot: {
    name: 'Enot.io (для физ. лиц)',
    description: 'Прием платежей для физических лиц без сложных договоров и документов.',
    about_link: 'https://enot.io/',
    image: require('@/static/img/payment_methods/enot.svg'),
    disabled: true
  },
  tome: {
    name: 'Tome.ru (для самозанятых)',
    description: 'Прием платежей для самозанятых. Автоматическая отправка чеков в ФНС.',
    about_link: 'https://tome.ru/',
    image: require('@/static/img/payment_methods/tome.svg'),
    disabled: true
  },

  qiwi: {
    name: 'Qiwi (для физ. лиц)',
    description: 'Оплата картами РФ и Qiwi. Нужен идентифицированный кошелек, можно онлайн через Госуслуги.',
    about_link: 'https://p2p.qiwi.com/',
    image: require('@/static/img/payment_methods/qiwi.svg'),
    disabled: false
  }
};

export { periodUnits, paymentMethods };
