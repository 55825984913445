<template>
  <div>
    <sdPageHeader title="База знаний">
      <!-- <template v-slot:buttons>
        <div class="page-header-actions">
          <sdCalendarButton />
          <sdExportButton />
          <sdShareButton />
          <sdButton size="small" type="primary">
            <sdFeatherIcons type="plus" size="14" />
            Add New
          </sdButton>
        </div>
      </template> -->
    </sdPageHeader>
    <Main>
      <!-- <KnowledgeBaseTop /> -->
      <KnowledgebaseArticleWrap>
        <div class="knowledgebase-article-container">
          <ArticleTabWrap class="sDash_article-tab">
            <div class="sDash_article-tab__menu">
              <nav>
                <ul>
                  <li v-for="cat in categories" :key="cat.id">
                    <a :class="{active: activeCategoryId == cat.id}" @click="setActiveCategory(cat.id)">{{ cat.title }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <ArticleTabContentWrap v-if="!isLoading">

                <div class="sDash_article-tab-content">
                  <a-row>
                    <a-col :md="8" :sm="12" :xs="24" v-for="cat in categoryOverview" :key="cat.id">
                      <div class="sDash_article-tab-signle">
                        <h2 class="sDash_article-tab-signle__title">{{ cat.title }}</h2>
                        <ul class="sDash_article-tab-signle__list">
                          <li v-for="question in cat.questions" :key="question.id">
                            <router-link to="/page/knowledgebase/single/1">{{ question.title }}</router-link>
                          </li>
                        </ul>
                        <router-link to="/page/knowledgebase/single/1" class="btn-link">
                          Все статьи <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col>
                    <!-- <a-col :md="8" :sm="12" :xs="24">
                      <div class="sDash_article-tab-signle">
                        <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
                        <ul class="sDash_article-tab-signle__list">
                          <li>
                            <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
                          </li>
                          <li>
                            <router-link to="#">Switch between accounts</router-link>
                          </li>
                          <li>
                            <router-link to="#">Change your email</router-link>
                          </li>
                          <li>
                            <router-link to="#">Reactivate your account</router-link>
                          </li>
                        </ul>
                        <router-link to="/page/knowledgebase/single/1" class="btn-link">
                          See more <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col>
                    <a-col :md="8" :sm="12" :xs="24">
                      <div class="sDash_article-tab-signle">
                        <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
                        <ul class="sDash_article-tab-signle__list">
                          <li>
                            <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
                          </li>
                          <li>
                            <router-link to="#">Switch between accounts</router-link>
                          </li>
                          <li>
                            <router-link to="#">Change your email</router-link>
                          </li>
                          <li>
                            <router-link to="#">Reactivate your account</router-link>
                          </li>
                        </ul>
                        <router-link to="/page/knowledgebase/single/1" class="btn-link">
                          See more <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col>
                    <a-col :md="8" :sm="12" :xs="24">
                      <div class="sDash_article-tab-signle">
                        <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
                        <ul class="sDash_article-tab-signle__list">
                          <li>
                            <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
                          </li>
                          <li>
                            <router-link to="#">Switch between accounts</router-link>
                          </li>
                          <li>
                            <router-link to="#">Change your email</router-link>
                          </li>
                          <li>
                            <router-link to="#">Reactivate your account</router-link>
                          </li>
                        </ul>
                        <router-link to="/page/knowledgebase/single/1" class="btn-link">
                          See more <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col>
                    <a-col :md="8" :sm="12" :xs="24">
                      <div class="sDash_article-tab-signle">
                        <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
                        <ul class="sDash_article-tab-signle__list">
                          <li>
                            <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
                          </li>
                          <li>
                            <router-link to="#">Switch between accounts</router-link>
                          </li>
                          <li>
                            <router-link to="#">Change your email</router-link>
                          </li>
                          <li>
                            <router-link to="#">Reactivate your account</router-link>
                          </li>
                        </ul>
                        <router-link to="/page/knowledgebase/single/1" class="btn-link">
                          See more <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col>
                    <a-col :md="8" :sm="12" :xs="24">
                      <div class="sDash_article-tab-signle">
                        <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
                        <ul class="sDash_article-tab-signle__list">
                          <li>
                            <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
                          </li>
                          <li>
                            <router-link to="#">Switch between accounts</router-link>
                          </li>
                          <li>
                            <router-link to="#">Change your email</router-link>
                          </li>
                          <li>
                            <router-link to="#">Reactivate your account</router-link>
                          </li>
                        </ul>
                        <router-link to="/page/knowledgebase/single/1" class="btn-link">
                          See more <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col> -->
                  </a-row>
                </div>
              </ArticleTabContentWrap>
            </div>
          </ArticleTabWrap>
          <PopularArticleWrap v-if="!isLoading">
            <div class="sDash_popular-article sDash_popular-article-container">
              <h2 class="sDash_popular-article__title">Популярные статьи</h2>
              <div v-masonry="containerId" transition-duration="0.3s" item-selector=".item" class="ant-masonry">
                <div class="sDash_popular-article__box">
                  <a-row>
                    <a-col
                      :md="8"
                      :sm="12"
                      :xs="24"
                      v-masonry-tile
                      class="item"
                      v-for="(article, index) in categoryPopular"
                      :key="article.id"
                    >
                      <div :class="`sDash_popular-article__single theme-${articleTypes[index]}`">
                        <p>{{ article.title }}</p>
                        <router-link class="btn-link" to="/page/knowledgebase/single/1">
                          Читать
                          <ArrowRightOutlined />
                        </router-link>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
          </PopularArticleWrap>
          <CtaWrap>
            <div class="sDash_knowledgebase-cta">
              <h2 class="sDash_knowledgebase-cta__title">Не нашли ответ на свой вопрос?</h2>
              <p>Напишите нам в Telegram и мы постараемся вам помочь.</p>
              <a href="https://t.me/vibes_manager" target="_blank">
                <sdButton class="btn-rqSubmit" type="primary" size="large">
                  Написать в Telegram
                </sdButton>
              </a>
            </div>
          </CtaWrap>
        </div>
      </KnowledgebaseArticleWrap>
    </Main>
  </div>
</template>

<script>
import { Main } from '@/view/styled';

import KnowledgeBaseTop from './overview/knowledgebase/KnowledgeTop';
import { KnowledgebaseArticleWrap, ArticleTabWrap, PopularArticleWrap, CtaWrap } from './style';
import articles from '@/demoData/article.json';
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import { ArticleTabContentWrap } from './style';
import axios from '@/config/axios';
import { computed, onMounted, ref, watchEffect, reactive, watch } from 'vue';

export default {
  name: 'KnowledgeBase',
  components: {
    Main,
    KnowledgeBaseTop,
    KnowledgebaseArticleWrap,
    ArticleTabWrap,
    PopularArticleWrap,
    CtaWrap,
    ArrowRightOutlined,
    ArticleTabContentWrap
  },
  setup() {
    const categories = ref([]);
    const categoryOverview = ref([]);
    const categoryPopular = ref([]);
    const activeCategoryId = ref(null);
    const isLoading = ref(true);

    const articleTypes = ref(['primary', 'danger', 'success', 'secondary', 'warning', 'info']);

    axios.get('/faq/categories', {})
      .then(response => {
        categories.value = response.data.category;

        isLoading.value = false;

        if(response.data.category.length > 0)
          setActiveCategory(response.data.category[0].id);
      });

    const setActiveCategory = function(id) {
      activeCategoryId.value = id;

      isLoading.value = true;

      axios.get('/faq/categories/overview', {
        params: {
          id: id
        }
      }).then(response => {
        categoryOverview.value = response.data.questions;
        categoryPopular.value = response.data.popular;

        isLoading.value = false;
      });
    };

    return {
      articles,
      categories,
      activeCategoryId,
      setActiveCategory,
      categoryOverview,
      isLoading,
      categoryPopular,
      articleTypes
    };
  },
};
</script>
