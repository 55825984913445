import activeProjectId from '@/config/helpers'

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const plural = function(forms, n) {
  let idx;
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0; // many
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1; // few
  } else {
    idx = 2; // one
  }
  return forms[idx] || '';
}

const getAvatarUrl = function(user) {
  if(user.first_name !== undefined || user.last_name !== undefined)
    return process.env.VUE_APP_STATIC_BASE_URL + 'static/avatar/?id='+user.telegram_id+'&project='+activeProjectId()+'&title='+user.title+'&first_name='+user.first_name+'&last_name='+user.last_name;

  return process.env.VUE_APP_STATIC_BASE_URL + 'static/avatar/?id='+user.telegram_id+'&project='+activeProjectId()+'&title='+user.title+'&first_name='+user.telegram_first_name+'&last_name='+user.telegram_last_name;
}

const getFileUrl = function(file_id) {
  return process.env.VUE_APP_STATIC_BASE_URL + 'static/file/?project='+activeProjectId()+'&id='+file_id;
}

const randomPassword = function(length = 12) {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%_ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = length;
  var password = "";
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber +1);
  }

  return password;
}

const debounce = (func, delay = 600, immediate = false) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, delay)
    if (callNow) func.apply(context, args)
  }
}


export { ellipsis, plural, getAvatarUrl, getFileUrl, randomPassword, debounce };
