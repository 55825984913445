<template>
  <sdPageHeader title="Создать проект"> </sdPageHeader>
  <Main>
    <a-row :gutter="25">
      <a-col :sm="24" :xs="24">
        <sdCards headless>
          <a-row>
            <a-col :sm="9" :xs="9" style="border-right: 1px solid #F1F2F6;">
              <ul class="custom-step-numbers">
                <li class="" :class="{'step-current': currentStep == 1, 'step-done': currentStep > 1}">1</li>
                <li class="" :class="{'step-current': currentStep == 2, 'step-done': currentStep > 2}">2</li>
                <li class="" :class="{'step-current': currentStep == 3, 'step-done': currentStep > 3}">3</li>
                <li class="" :class="{'step-current': currentStep == 4, 'step-done': currentStep > 4}">4</li>
              </ul>

              <div class="wizard-item" v-if="currentStep == 1">
                <h2>Мастер создания проекта</h2>

                <p>Сейчас мы зададим вам несколько вопросов и поможем создать ваш чат-бот для приема платежей.</p>

                <p>Процесс займет около 10 минут. Понадобится доступ к Telegram.</p>
                <img :src="require('@/static/img/wizards/1.svg')" alt="">
              </div>

              <div class="wizard-item" v-if="currentStep == 2">
                <h2>Сейчас нужно будет зайти в Telegram и создать чат-бот</h2>

                <p>Не пугайтесь, это не сложно! Чат-бот нужен для того, чтобы вы могли собирать контакты своих пользователей и вести с ними дальнейшую коммуникацию.</p>

                <p>Откройте Telegram и найдите бота <a href="https://t.me/BotFather">@BotFather</a>, введите в него команду /newbot и следуйте дальнейшим инструкциям.</p>

                <p style="font-weight: 500; text-align: center; margin-top: 40px;">Если вы не понимаете как создать бота, посмотрите нашу короткую видео-инструкцию (81 сек.):</p>

                <sdButton size="default" shape="circle" :transparented="true" type="primary">
                  <sdFeatherIcons type="play-circle" size="14" /> <span>Смотреть видео-инструкцию</span>
                </sdButton>
              </div>

              <div class="wizard-item" v-if="currentStep == 3">
                <h2>Создайте закрытый канал и добавьте своего бота в админы</h2>

                <p>Создайте закрытый канал (или чат) к которому вы планируете выдавать доступ. Обязательно укажите настройки приватности - канал должен быть закрытым.</p>

                <p>Добавьте в администраторы своего бота и выдайте ему разрешение “Добавлять подписчиков”.</p>

                <p style="font-weight: 500; text-align: center; margin-top: 40px;">Если вы не понимаете как создать закрытый канал, посмотрите нашу короткую видео-инструкцию (113 сек.):</p>

                <sdButton size="default" shape="circle" :transparented="true" type="primary">
                  <sdFeatherIcons type="play-circle" size="14" /> <span>Смотреть видео-инструкцию</span>
                </sdButton>
              </div>

              <div class="wizard-item" v-if="currentStep == 4">
                <h2>Почти готово!</h2>

                <p>Мы сохранили ваш проект, но пока что он не активирован: вы не можете принимать платежи и выводить деньги.</p>

                <p>Вам нужно завершить настройку проекта и подать заявку на активацию. Подробнее см. инструкцию.</p>

                <p style="font-weight: 500; text-align: center; margin-top: 40px;">Как завершить настройку проекта и подать заявку на активацию (5 мин 44 сек.):</p>

                <sdButton size="default" shape="circle" :transparented="true" type="primary">
                  <sdFeatherIcons type="play-circle" size="14" /> <span>Смотреть видео-инструкцию</span>
                </sdButton>
              </div>

            </a-col>
            <a-col :sm="15" :xs="15">
              <div class="current-step-content" v-if="currentStep == 1">
                <h2>1. Общая информация о вашем проекте</h2>



                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 40px;">
                    <a-form name="account" :model="formStateStep1" ref="formRefStep1" :rules="rulesStep1">
                      <a-form-item name="project_name" label="Название проекта">
                        <a-input v-model:value="formStateStep1.project_name" placeholder="Например: Закрытый клуб по созданию закрытых клубов" />
                      </a-form-item>
                      <!-- <a-form-item name="category" label="Категория проекта">
                        <a-input type="text" v-model:value="formStateStep1.category"  placeholder="Выберите категорию, которая максимально соответствует вашему продукту" />
                      </a-form-item> -->
                      <a-form-item name="category" type="text" label="Категория проекта">
                        <a-select v-model:value="formStateStep1.category" placeholder="Выберите категорию...">
                          <a-select-option v-for="cat in categories" :key="cat.id">
                            {{ cat.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item name="link" label="Ссылка на ваш Telegram канал или другую соцсеть через которую вы будете продвигать подписку">
                        <a-input type="text" v-model:value="formStateStep1.link" placeholder="Если у вас нет социальных сетей, прикрепите ссылку на лендинг вашего продукта" />
                      </a-form-item>
                      <a-form-item name="description" label="Расскажите в двух предложениях о вашем продукте">
                        <a-textarea type="text" v-model:value="formStateStep1.description" placeholder="Например: Я фуд-блогер, у меня в Instagram 2.5 млн., в Telegram 150к, хочу для своей аудитории запустить закрытый клуб с рецептами и кулинарными лайфхаками. " />
                      </a-form-item>
                      <sdButton class="btn-next" type="primary" @click="nextStep"  :disabled="isLoading">
                        <span v-if="isLoading">Загрузка...</span>
                        <span v-else>
                          Далее&nbsp;&nbsp;
                          <sdFeatherIcons type="arrow-right" size="16" />
                        </span>
                      </sdButton>
                    </a-form>
                  </div>
                </BasicFormWrapper>
              </div>

              <div class="current-step-content" v-if="currentStep == 2">
                <h2>2. Скопируйте сюда токен от вашего чат-бота в Telegram</h2>

                <BasicFormWrapper class="basic-form-inner">
                  <div class="atbd-form-checkout" style="margin-top: 20px;">
                    <a-form name="token" :model="formStateStep2" ref="formRefStep2" :rules="rulesStep2">
                      <a-form-item name="token" label="Токен вашего чат-бота в Telegram (подробнее см. инструкцию слева)">
                        <a-input v-model:value="formStateStep2.token" placeholder="Например: 5135492573:AAGbFEfZFbb7Ph8rpyAJDQNs4MAVMKFDOVQ" :disabled="isTokenValidated" />
                      </a-form-item>
                      <sdButton class="btn-next" type="primary"  @click="validateToken" :disabled="formStateStep2.token == '' || isLoading" v-if="!isTokenValidated">
                        <span v-if="isLoading">Загрузка...</span>
                        <span v-else>
                        Проверить токен&nbsp;&nbsp;
                        <sdFeatherIcons type="arrow-right" size="16" />
                      </span>
                      </sdButton>
                    </a-form>
                  </div>
                </BasicFormWrapper>


                <div v-if="isTokenValidated === true" style="margin-top: 30px;">
                  <h2>Это ваш чат-бот?</h2>

                  <div class="bot-preview">
                    <img :src="'https://ui-avatars.com/api/?background=355DFF&name='+tokenData.first_name+'&size=128&color=FFFFFF'" alt="" />
                    <p>
                      <span class="title">{{ tokenData.first_name }}</span>
                      <span class="username"><a :href="'https://t.me/'+tokenData.username">{{ '@'+tokenData.username }}</a></span>
                    </p>
                  </div>

                  <div style="margin-top: 20px;">
                    <sdButton size="default" type="success" @click="nextStep">
                      Да
                    </sdButton>
                    <sdButton size="default" type="danger" style="margin-left: 10px;" @click="resetToken">
                      Нет
                    </sdButton>
                  </div>
                </div>
              </div>

              <div class="current-step-content" v-if="currentStep == 3">
                <h2>3. Как только вы подключите закрытый канал, мы автоматически найдем его</h2>

                <p>Если вы планируете продавать доступ к нескольким разным каналам, вы сможете добавить их позднее в настройках проекта.</p>

                <!-- <p><b>Если вы уже добавили бота и система не находит канал более 5 минут, попробуйте добавить в него любой новый пост.</b></p> -->

                <SpinerWraperStyle v-if="updateChannelsList.length == 0">
                  <a-spin indicator="activity" />
                </SpinerWraperStyle>

                <a-row v-if="updateChannelsList.length > 0">
                  <a-col :md="8" v-for="item in updateChannelsList" :key="item.id">
                    <sdUserCard>
                      <div class="card team-card">
                        <sdCards headless>
                          <figure>
                            <img :src="'https://ui-avatars.com/api/?background=355DFF&name='+item.title+'&size=128&color=FFFFFF'" alt="" />
                            <figcaption>
                              <sdHeading class="card__name" as="h6">
                                <a to="#">{{ item.title }}</a>
                              </sdHeading>
                              <span class="card__designation">{{ item.id }}</span>
                              <div class="card__social">
                                <sdButton size="small" type="primary" :outlined="true" @click="setSelectedChannel(item)">
                                  Выбрать
                                </sdButton>
                              </div>
                            </figcaption>
                          </figure>
                        </sdCards>
                      </div>
                    </sdUserCard>
                  </a-col>
                </a-row>

              </div>

              <div class="current-step-content" v-if="currentStep == 4">
                <h2>4. Осталось несколько шагов до начала приема платежей!</h2>

                <p style="color: red; font-style: italic;">Здесь будет чек-лист из этапов которые нужно сделать чтобы магазин полноценно заработал.</p>

                <p>После нажатия на кнопку Готово вы попадете в панель проекта и сможете сделать все дальнейшие необходимые действия для активации!</p>

                <p><b>Обязательно</b> нажмите на кнопку ниже, это нужно для корректного сохранения проекта 👇</p>

                <sdButton size="default" type="primary" @click="saveProject" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Завершить настройку' }}
                </sdButton>
              </div>

            </a-col>
          </a-row>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>
.bot-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.bot-preview img {
  border-radius: 50%;
  width: 90px;
}

.bot-preview p {
  display: block;
}

.bot-preview p span.title {
  color: #272B41;
  font-weight: bold;
  font-size: 18px;
  display: block;
}

.bot-preview p span.username {
  color: #616268;
  font-size: 16px;
  display: block;
  margin-top: 0px;
}


  .custom-step-numbers {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    list-style-type: none;
    gap: 20px;
  }

  .custom-step-numbers li {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: 0 none;
    box-shadow: 10px 0 20px #9299b815;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    font-family: Inter,sans-serif;
  }

  .custom-step-numbers li.step-current {
    background: #355DFF;
    color: #ffffff !important;
  }

  .custom-step-numbers li.step-done {
    background: #20C997;
    color: #ffffff !important;
  }


  .wizard-item {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }

  .wizard-item h2 {
    margin-bottom: 20px;
  }

  .wizard-item p {
    color: #5A5F7D;
    text-align: left;
    margin-top: 10px;
  }

  .wizard-item img {
    max-width: 200px;
    margin-top: 30px;
  }

  .current-step-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
</style>
<script>
import { BasicFormWrapper, Main } from '../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

const NewProject = {
  name: 'NewProject',
  components: {
    BasicFormWrapper,
    PlusOutlined,
    MinusOutlined,
    Main,
    SpinerWraperStyle
  },
  setup() {
    const { state, dispatch } = useStore();

    const categories = ref([]);

    axios.get('/onboarding/project_categories').then(response => {
      categories.value = response.data.categories;
    });

    const isLoading = ref(false);

    const currentStep = ref(1);

    const formRefStep1 = ref();
    const formStateStep1 = reactive({
      project_name: '',
      category: null,
      link: '',
      description: '',
    });

    const rulesStep1 = {
      project_name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      category: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

              console.log(value);

            return Promise.resolve();
          },
          message: 'Пожалуйста, выберите категорию',
          trigger: 'blur'
        }
      ],
      link: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      description: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ]
    };

    const formRefStep2 = ref();
    const formStateStep2 = reactive({
      token: '',
    });
    const rulesStep2 = {
      token: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 40,
          max: 48,
          message: 'Проверьте длину токена',
          trigger: 'blur',
        }
      ]
    };

    const nextStep = () => {

      if(currentStep.value == 1) {
        formRefStep1.value
          .validate()
          .then(() => {
            currentStep.value = 2;
          }).catch(error => {
            // alert('Validation not passed!');
            console.log('error', error);
          });
      }


      if(currentStep.value == 2) {
        currentStep.value = 3;

        getUpdateTimer = setInterval(() => {
          getUpdate();
        }, getUpdateTimeout);
      }


    };



    const validateToken = () => {
      formRefStep2.value
        .validate()
        .then(() => {
          isLoading.value = true;
          axios
            .post('/onboarding/validate_tg_token', formStateStep2)
            .then(response => {
              console.log(response);
              if(response.data.status === true) {
                isTokenValidated.value = true;
                tokenData.value = response.data.result;
              } else {
                alert('Вы указали неверный токен! Проверьте правильность и попробуйте еще раз.');
              }
            })
            .catch(error => {
              console.log('error :>> ', error.response)
            })
            .finally(() => {
              isLoading.value = false;
            })
        }).catch(error => {
          // alert('Validation not passed!');
          console.log('error', error);
        });
    };

    const isTokenValidated = ref(false);
    const tokenData = ref({});

    const resetToken = () => {
      tokenData.value = {};
      isTokenValidated.value = false;
      formStateStep2.token = '';
    };

    const updateChannelsList = ref([]); // сюда сохраняем последний апдейт (который сейчас будем показывать пользователю)
    const savedUpdate = ref({}); // сюда сохраняем апдейт на который пользователь сказал "Да"

    const getUpdateTimeout = 5000;

    var getUpdateTimer = null;

    const getUpdate = () => {
      console.log('Calling get update method');

      axios
        .post('/onboarding/get_tg_updates', formStateStep2)
        .then(response => {
          console.log(response);

          if(response.data.status === true) {
            if(response.data.result.length > 0) {

              console.log('Got answer!');

              response.data.result.forEach((value, index) => {
                  if(value.hasOwnProperty('my_chat_member')) {
                    console.log('Valid update!');
                    console.log('-------->', value);

                    if(!updateChannelsList.value.some(data => data.id === value.my_chat_member.chat.id))
                      updateChannelsList.value.push(value.my_chat_member.chat);
                    else console.log('already in array');
                  }
              });
            }
          }

        })
        .catch(error => {
          console.log('error :>> ', error)
        })
        .finally(() => {

        })
    };

    const setSelectedChannel = (item) => {
      clearInterval(getUpdateTimer);
      savedUpdate.value = item;
      currentStep.value = 4;
    };

    const saveProject = () => {
      isLoading.value = true;

      axios.post('/onboarding/save_project', {
        name: formStateStep1.project_name,
        project_category_id: formStateStep1.category,
        onboarding_link: formStateStep1.link,
        onboarding_description: formStateStep1.description,
        tg_bot_token: formStateStep2.token,
        tg_private_channel_id: savedUpdate.value.id
      }).then(response => {
        console.log(response);

        if(response.data.status === 'error') {
          alert(response.data.message);
          return;
        }

        if(response.data.status === 'ok') {
          localStorage.setItem('activeProjectId', response.data.project.id);
          document.location.href = '/';
        }
      }).finally(() => {
        isLoading.value = false;
      });
    };


    return {
      currentStep,
      isLoading,

      formStateStep1,
      formRefStep1,
      rulesStep1,

      formStateStep2,
      formRefStep2,
      rulesStep2,

      validateToken,
      resetToken,
      isTokenValidated,
      tokenData,

      updateChannelsList,
      savedUpdate,
      setSelectedChannel,


      nextStep,

      categories,

      saveProject

    };
  },
};

export default NewProject;
</script>
