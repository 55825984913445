<template>
  <div>
    <sdPageHeader title="Подписка на сервис">
      <template v-slot:buttons>
        <!-- <div class="page-header-actions">
          <sdCalendarButton />
          <sdExportButton />
          <sdShareButton />
          <sdButton size="small" type="primary">
            <sdFeatherIcons type="plus" size="14" />
            Add New
          </sdButton>
        </div> -->
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="25" type="flex" justify="center">
        <a-col :xxl="6" :lg="8" :sm="12" :xs="24">
          <PricingCard style="margin-bottom: 30px">
            <Badge class="pricing-badge" type="dark">Быстрый старт</Badge>
            <sdHeading class="pricing-title" as="h3">
              Бесплатно
            </sdHeading>
            <span class="package-user-type">До ₽ 30 000 в мес.</span>
            <ListGroup>
              <List text="Создание чат-ботов" />
              <List text="Прогрев аудитории" />
              <List text="Прием платежей" />
              <List text="Базовая аналитика" />
            </ListGroup>
            <sdButton class="btn-outlined" size="default" :outlined="true" type="white">
              Активный тариф
            </sdButton>
          </PricingCard>
        </a-col>
        <a-col :xxl="6" :lg="8" :sm="12" :xs="24">
          <PricingCard style="margin-bottom: 30px">
            <Badge class="pricing-badge" type="primary">Лайт</Badge>
            <sdHeading class="price-amount" as="h3">
              <sup class="currency">₽</sup>1 990
              <sub class="pricing-validity">в месяц</sub>
            </sdHeading>
            <span class="package-user-type">До ₽ 200 000 в мес.</span>
            <ListGroup>
              <List text="Создание чат-ботов" />
              <List text="Прогрев аудитории" />
              <List text="Прием платежей" />
              <List text="Базовая аналитика" />
              <List text="Отправка рассылок" />
              <List text="Можно убрать логотип при оплате на год" />
            </ListGroup>
            <sdButton size="default" type="primary">
              Выбрать тариф
            </sdButton>
          </PricingCard>
        </a-col>
        <a-col :xxl="6" :lg="8" :sm="12" :xs="24">
          <PricingCard style="margin-bottom: 30px">
            <Badge class="pricing-badge" type="secondary">Бизнес</Badge>
            <sdHeading class="price-amount" as="h3">
              <sup class="currency">₽</sup>4 990
              <sub class="pricing-validity">в месяц</sub>
            </sdHeading>
            <span class="package-user-type">До ₽ 500 000 в мес.</span>
            <ListGroup>
              <List text="Создание чат-ботов" />
              <List text="Прогрев аудитории" />
              <List text="Прием платежей" />
              <List text="Аналитика PRO" />
              <List text="Отправка рассылок" />
              <List text="Ежедневные отчеты в Telegram" />
              <List text="Можно убрать логотип при оплате на год" />
            </ListGroup>
            <sdButton size="default" type="secondary">
              Выбрать тариф
            </sdButton>
          </PricingCard>
        </a-col>
        <a-col :xxl="6" :lg="8" :sm="12" :xs="24">
          <PricingCard style="margin-bottom: 30px">
            <Badge class="pricing-badge" type="success">Ультра</Badge>
            <sdHeading class="price-amount" as="h3">
              <sup class="currency">₽</sup>14 990
              <sub class="pricing-validity">в месяц</sub>
            </sdHeading>
            <span class="package-user-type">Безлимит</span>
            <ListGroup>
              <List text="Создание чат-ботов" />
              <List text="Прогрев аудитории" />
              <List text="Прием платежей" />
              <List text="Аналитика PRO" />
              <List text="Отправка рассылок" />
              <List text="Ежедневные отчеты в Telegram" />
              <List text="Можно убрать логотип Vibes" />
              <List text="Выделенная поддержка" />
              <List text="Кастомные интеграции и API" />
            </ListGroup>
            <sdButton size="default" type="success">
              Выбрать тариф
            </sdButton>
          </PricingCard>
        </a-col>
      </a-row>
      <br />

    </Main>
  </div>
</template>

<script>
import { Main } from '@/view/styled';
import List from '@/components/pricing/pricing';
import { PricingCard, ListGroup, Badge } from '@/view/pages/style';

export default {
  name: 'Subscription',
  components: {
    Main,
    List,
    PricingCard,
    ListGroup,
    Badge,
  },
  data() {
    return {};
  },
};
</script>
