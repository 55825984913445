<template>
  <div>
    <Main>
      <MaintananceWrapper>
        <img :src="require(`@/static/img/pages/maintenance.svg`)" alt="maintenance" />
        <sdHeading as="h3">Страница в разработке</sdHeading>
        <p>
          Ой, вы попали на страницу которой еще не существует 🙄 <br />
          Пожалуйста, попробуйте позже.
        </p>
      </MaintananceWrapper>
    </Main>
  </div>
</template>

<script>
import { Main } from '@/view/styled';
import { MaintananceWrapper } from '@/view/pages/style';
export default {
  name: 'Production',
  components: {
    Main,
    MaintananceWrapper,
  },
  data() {
    return {};
  },
};
</script>
