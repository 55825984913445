<template>
  <a-upload
    v-model:file-list="fileList"
    name="image"
    list-type="picture"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="actionUrl"
    :before-upload="beforeUpload"
    :data="uploadData"
    :headers="uploadHeaders"
    @change="handleChange"
  >
    <div v-if="elementData.public_url">
      <video controls v-if="elementData.public_url" style="width: 200px;">
        <source :src="elementData.public_url"/>
      </video>
      <br>
      <sdButton class="add-message-btn-container" size="small"  :transparented="true"  type="primary" :disabled="loading">
        <sdFeatherIcons type="upload" size="14" />&nbsp;&nbsp;
        {{ (loading) ? 'Загрузка...' : 'Заменить файл'}}
      </sdButton>
    </div>
    <div v-else>
      <sdButton class="add-message-btn-container" size="small"  :transparented="true"  type="primary" :disabled="loading">
        <sdFeatherIcons type="upload" size="14" />&nbsp;&nbsp;
        {{ (loading) ? 'Загрузка...' : 'Загрузить видео (полноразмерное)'}}
      </sdButton>
    </div>
  </a-upload>
</template>
<script>
import { computed, onMounted, ref, watchEffect, reactive, watch } from 'vue';
import draggable from 'vuedraggable'
import { message } from 'ant-design-vue';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import activeProjectId from '@/config/helpers'


function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}


export default {
  name: 'TgInputVideoUpload',
  components: {
    draggable,
    PlusOutlined,
    LoadingOutlined
  },
  props: {
    element: {}
  },
  setup(props) {
    const elementData = ref({});
    elementData.value = props.element;

    const fileList = ref([
      // {
      //   uid: '-1',
      //   name: 'xxx.png',
      //   status: 'done',
      //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      //   thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // },
      //
    ]);

    const actionUrl = ref(process.env.VUE_APP_API_BASE_URL + 'file_manager/upload/video');
    const uploadData = ref({
      project_id: activeProjectId()
    });
    const uploadHeaders = ref({
      'Authorization': 'Bearer '+localStorage.getItem('accessToken')
    });

    const loading = ref(false);

    const handleChange = (info) => {
      console.log(info);
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }
      if (info.file.status === 'done') {
        loading.value = false;
        elementData.value.public_url = info.file.response.url;
        elementData.value.inner_file_id = info.file.response.inner_file_id;
        elementData.value.name = info.file.response.name;
      }
      if (info.file.status === 'error') {
        loading.value = false;
        // @todo: обработка разных типов ошибок
        message.error('Ошибка загрузки');
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'video/mp4';
      if (!isJpgOrPng) {
        message.error('Поддерживаются только файлы формата mp4, вес файла не более 50 мб. Если ваше видео весит больше, создайте текстовое сообщение и прикрепите в него ссылку на YouTube.');
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error('Видео должно быть меньше 50 мб. Если ваше видео весит больше, создайте текстовое сообщение и прикрепите в него ссылку на YouTube.');
      }
      return isJpgOrPng && isLt2M;
    };


    watch(() => elementData, (first, second) => {
      alert('Changed!');
    });


    return {
      elementData,

      fileList,

      handleChange,
      beforeUpload,

      loading,

      actionUrl,
      uploadData,
      uploadHeaders
    }
  }
};
</script>

<style lang="scss">
.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 220px !important;
  height: 220px !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
