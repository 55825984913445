<template>
  <AuthWrapper>
    <p class="auth-notice">Уже зарегистрированы? <router-link to="/auth/login">Войти</router-link></p>
    <div class="auth-contents">
      <a-form name="register" :model="formState" ref="formRef" :rules="rules" @change="formChange" @finish="register" layout="vertical">
        <sdHeading as="h3">Регистрация в <span class="color-secondary">панели проекта</span> </sdHeading>

        <div v-if="registerError" style="padding-top: 0px; padding-bottom: 20px">
          <sdAlerts
            :outlined="true"
            :closable="false"
            :showIcon="true"
            message=""
            :description="registerError"
            type="error"

          />
        </div>


        <a-form-item label="Как вас зовут?" name="username">
          <a-input v-model:value="formState.username" placeholder="Иванов Иван" />
        </a-form-item>
        <!-- <a-form-item name="username" label="Username">
          <a-input v-model:value="formState.username" placeholder="Username" />
        </a-form-item> -->
        <a-form-item name="email" label="Ваш Email">
          <a-input type="email" v-model:value="formState.email" placeholder="hello@vibes.band" />
        </a-form-item>
        <a-form-item label="Придумайте пароль" name="password">
          <a-input type="password" v-model:value="formState.password" placeholder="Пароль..." />
        </a-form-item>
        <a-form-item label="Повторите пароль" name="c_password">
          <a-input type="password" v-model:value="formState.c_password" placeholder="Пароль..." />
        </a-form-item>

        <div class="auth-form-action">
          <a-checkbox @change="onChange" :checked="formState.accept">
            Регистрируя аккаунт, вы подтверждаете акцепт <a href="#">агентского-договора оферты</a>, а также безусловно соглашаетесь с <a href="#">политикой обработки персональных данных</a> и <a href="#">правилами использования платформы</a>.
          </a-checkbox>
        </div>
        <a-form-item>
          <sdButton class="btn-create" htmlType="submit" type="primary" size="large"  :disabled="!formState.accept || isLoading">
            {{ isLoading ? 'Загрузка...' : 'Cоздать аккаунт' }}
          </sdButton>
        </a-form-item>

        <!-- <p class="form-divider">
          <span>Or</span>
        </p> -->
        <!-- <ul class="social-login signin-social">
          <li>
            <a class="google-signup" href="#">
              <img :src="require('@/static/img/google.png')" alt="" />
              <span>Sign up with Google</span>
            </a>
          </li>
          <li>
            <a class="facebook-sign" href="#">
              <FacebookOutlined />
            </a>
          </li>
          <li>
            <a class="twitter-sign" href="#">
              <TwitterOutlined />
            </a>
          </li>
        </ul> -->
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { AuthWrapper } from './style';
import { defineComponent, reactive, ref, toRaw } from 'vue';
import axios from '@/config/axios'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const SignUp = {
  name: 'SignUp',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();

    const values = ref(null);
    const checked = ref(false);
    const handleSubmit = value => {
      values.value = value;
    };
    const isLoading = /*computed(() => state.auth.loading)*/ref(false);
    const router = useRouter();

    const formRef = ref();

    const registerError = ref(false);

    const rules = {
      username: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        },
        {
          validator: (rule, value, callback) => {
            var regexp = /[a-zA-Zа-яА-Я]+\s+[a-zA-Zа-яА-Я]+/g;

            if (regexp.test(value))
              return Promise.resolve();

            return Promise.reject('Please input both first and last name');

          },
          message: 'Введите имя и фамилию',
          trigger: 'blur'
        }
      ],
      email: [
        {
          type: 'email',
          required: true,
          message: 'Пожалуйста, проверьте правильность ввода',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          min: 8,
          max: 512,
          message: 'Минимальная длина пароля - 8 символов',
          trigger: 'blur'
        }
      ],
      c_password: [
        {
          required: true,
          message: 'Пароли не совпадают',
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            if(value !== formState.password)
              return Promise.reject('Passwords dont match');

            return Promise.resolve();
          }
        }
      ],
    };

    const register = async () => {
      isLoading.value = true;

      formRef.value
        .validate()
        .then(() => {
          axios.get('/sanctum/csrf-cookie').then(response => {
            axios
              .post('/auth/register', formState)
              .then(response => {
                if(response.data.status === 'ok') {
                  const { accessToken } = response.data

                  // ? Set access token in localStorage so axios interceptor can use it
                  // Axios Interceptors: https://github.com/axios/axios#interceptors
                  localStorage.setItem('accessToken', accessToken)

                  dispatch('checkAuth').then(() => {
                    router.push('/auth/verify/phone');
                  });
                } else {
                  registerError.value = response.data.message;
                }

                return response
              })
              .catch(error => {
                // TODO: Next Update - Show notification
                console.error('Oops, Unable to Register!')
                console.log('error :>> ', error)

                if(error.response) {
                  registerError.value = error.response.data.message;
                } else {
                  registerError.value = error;
                }
              })
          }).finally(()=>{
              isLoading.value = false
          });
        })
        .catch(error => {
        });

      return;
    };

    const onChange = check => {
      formState.accept = ! formState.accept;

    };

    const formState = reactive({
      username: '',
      // username: '',
      email: '',
      password: '',
      c_password: '',
      accept: false
    });
    return {
      onChange,
      handleSubmit,
      formState,
      isLoading,
      register,
      rules,
      formRef,
      registerError
    };
  },
};

export default SignUp;
</script>
