<template>
  <div class="notification select-project" v-if="isLoaded && projects.length > 0">
    <sdPopover placement="bottomLeft" action="click">
      <template v-slot:content>
        <AtbdTopDropdwon class="atbd-top-dropdwon">
          <sdHeading as="h5" class="atbd-top-dropdwon__title">
            <span class="title-text">Выбор проекта</span>
            <a-badge class="badge-success" :count="state.auth.user.notifications.count" />
          </sdHeading>
          <perfect-scrollbar
            :options="{
              wheelSpeed: 1,
              swipeEasing: true,
              suppressScrollX: true,
            }"
          >

          <ul class="atbd-top-dropdwon__nav">
            <li  v-for="p in projects" :key="p.id">
              <a to="#" @click="setActiveProject(p.id)">
                <figure class="atbd-top-dropdwon__content">
                  <img :src="'https://ui-avatars.com/api/?background=355DFF&name='+p.name+'&size=128&color=FFFFFF'" alt="" style="border-radius: 50%;" />
                  <figcaption>
                    <sdHeading as="h5">{{ p.name }}</sdHeading>
                    <div>
                      <span class="atbd-top-dropdwonText">{{ '@'+p.tg_bot_username }}</span>
                    </div>
                  </figcaption>
                </figure>
              </a>
            </li>
          </ul>
          </perfect-scrollbar>
<!--          <router-link class="btn-seeAll" to="/new_project">-->
<!--            Добавить новый проект-->
<!--          </router-link>-->
        </AtbdTopDropdwon>
      </template>
      <a-badge>
        <a to="#" class="head-example" style="display: flex; align-items: center;">
          <sdFeatherIcons type="briefcase" size="20" />
          <span style="line-height: 20px;
            margin-left: 8px;
            font-size: 13px;
            padding-top: 2px;
            text-overflow: ellipsis;
            overflow:hidden;
            white-space:nowrap;
            max-width: 150px;">{{ (activeProject === null || activeProject === undefined) ? 'Выбор проекта...' : activeProject.name }}</span>
        </a>
      </a-badge>
    </sdPopover>
  </div>
</template>
<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { AtbdTopDropdwon } from './auth-info-style';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import axios from '@/config/axios'

export default {
  name: 'Notification',
  components: {
    AtbdTopDropdwon,
    PerfectScrollbar,
  },
  setup() {
    const { dispatch, state } = useStore();

    const isLoaded = ref(false);
    const projects = ref([]);

    const activeProjectId = ref(null);
    const activeProject = ref(null);

    activeProjectId.value = localStorage.getItem('activeProjectId') || null;
    console.log('Loaded local storage ID ------->', activeProjectId.value);

    if(activeProjectId.value !== null && activeProjectId.value !== undefined) {
      axios.get('/auth/can_access_project', {
        params: {
          project_id: activeProjectId.value
        }
      }).then((response) => {
        if(response.data.status === 'error') {
          // alert('Can not access project!');

          localStorage.removeItem('activeProjectId');
          document.location.href = '/';
        }
      });
    }


    axios.get('/auth/projects').then((response) => {
      console.log(response);

      isLoaded.value = true;
      projects.value = response.data.projects;

      activeProject.value = response.data.projects.find(p => p.id == activeProjectId.value);

      console.log('Loaded active project ------->');
      console.log(activeProject.value);

      if(activeProjectId.value === null && projects.value.length > 0) {
        setActiveProject(projects.value[0].id);
      }
    });

    const setActiveProject = (id) => {
      if(id !== null && id !==undefined && id == localStorage.getItem('activeProjectId')) {
        console.log('Prevent duplicated event');
        return;
      }

      localStorage.setItem('activeProjectId', id);
      activeProjectId.value = id;
      activeProject.value = projects.value.find(p => p.id == activeProjectId.value);

      document.location.href = document.location.href;
    };

    return {
      state,

      isLoaded,
      activeProjectId,
      activeProject,
      projects,

      setActiveProject
    }
  }
};
</script>
<style scoped>
.ps {
  height: 260px;
}
</style>
