<template>
  <div>
    <div>
      <draggable v-model="messages" handle=".handle">
        <template #item="{element, index}">
          <div class="message-container">
            <div class="message-flex">
              <div class="message-content">
                <div class="message-label">Сообщение №{{ index+1 }}</div>
                <div v-if="element.type == 'text'">
                  <a-textarea v-model:value="messages[index].text" placeholder="Текстовое сообщение в чат-боте" rows="4"/>
                </div>

                <div v-if="element.type == 'image'">
                  <TgInputImageUpload :element="element"/>
                </div>

                <div v-if="element.type == 'voice'">
                  <TgInputVoiceUpload :element="element"/>
                </div>

                <div v-if="element.type == 'videoNote'">
                  <TgInputVideoNoteUpload :element="element"/>
                </div>

                <div v-if="element.type == 'video'">
                  <TgInputVideoUpload :element="element"/>
                </div>

              </div>
              <div class="message-options">
                <div class="message-trigger handle">
                  <!-- <sdFeatherIcons type="menu" size="14" /> -->
                  <font-awesome-icon
                    :icon="faArrowsAltV"
                    size="20"
                    color="#A7A7A7"
                  />
                </div>

                <div class="message-remove"  @click="removeMessage(index)">
                  <sdFeatherIcons type="trash-2" size="20" color="#FF4D4F" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <sdDropdown placement="bottomLeft" trigger="click">
            <a to="#">
              <sdButton class="add-message-btn-container" size="small" :outlined="true" type="white">
                <sdFeatherIcons type="plus" size="14" /> &nbsp;&nbsp;Добавить сообщение
              </sdButton>

            </a>
            <template #overlay>
              <a to="#" @click="addNewMessage('text')"><sdFeatherIcons type="message-square" size="12" />Текстовое сообщение</a>
              <a to="#" @click="addNewMessage('image')"><sdFeatherIcons type="image" size="12" />Изображение</a>
              <a to="#" @click="addNewMessage('voice')"><sdFeatherIcons type="music" size="12" />Голосовое сообщение</a>
              <a to="#" @click="addNewMessage('videoNote')"><sdFeatherIcons type="circle" size="12" />Видео (кружочек)</a>
              <a to="#" @click="addNewMessage('video')"><sdFeatherIcons type="video" size="12" />Видео (полноразмерное)</a>
            </template>
          </sdDropdown>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import draggable from 'vuedraggable'

import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'


import TgInputImageUpload from './TgInputImageUpload'
import TgInputVoiceUpload from './TgInputVoiceUpload'
import TgInputVideoNoteUpload from './TgInputVideoNoteUpload'
import TgInputVideoUpload from './TgInputVideoUpload'

export default {
  name: 'TgInput',
  components: {
    draggable,
    TgInputImageUpload,
    TgInputVoiceUpload,
    TgInputVideoNoteUpload,
    TgInputVideoUpload
  },
  props: {
    preloadData: []
  },
  setup(props) {
    const messages = ref([
      {
        type: 'text',
        text: ''
      },
      // {
      //   type: 'image',
      //   telegram_file_id: '',
      //   inner_file_id: '',
      //   name: '',
      //   public_url: ''
      // }
    ]);

    const addNewMessage = function(type) {
      if(type == 'text')
        messages.value.push({
          type: 'text',
          text: ''
        });

      if(type == 'image')
        messages.value.push({
          type: 'image',
          inner_file_id: '',
          name: '',
          public_url: ''
        });

      if(type == 'voice')
        messages.value.push({
          type: 'voice',
          inner_file_id: '',
          name: '',
          public_url: ''
        });

      if(type == 'videoNote')
        messages.value.push({
          type: 'videoNote',
          inner_file_id: '',
          name: '',
          public_url: ''
        });

      if(type == 'video')
        messages.value.push({
          type: 'video',
          inner_file_id: '',
          name: '',
          public_url: ''
        });
    };

    const loadMessages = function(data) {
      messages.value = data;
    };

    const removeMessage = function(index) {
      messages.value = messages.value.filter((item, i) => {
        return index != i;
      });
    };

    if(props.preloadData !== undefined) {
      loadMessages(props.preloadData);
    }


    return {
      messages,
      addNewMessage,
      loadMessages,
      removeMessage,

      faArrowsAltV
    }
  }
};
</script>

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}

.message-container {
  margin-top: 20px;
}

.add-message-btn-container {
  margin-top: 20px;
}

.message-label {
  font-weight: 500;
  margin-bottom: 10px;
}

.message-options {
  text-align: center;
  padding-top: 22px;
}

.message-remove {
  margin-top: 20px;
}

.message-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.message-flex .message-content {
  flex-grow: 1;
}

.message-trigger {
  cursor: move;
}

</style>
