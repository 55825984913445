<template>
  <div>
    <sdPageHeader title="Добро пожаловать!">
      <template v-slot:buttons>
        <!-- <div class="page-header-actions">
          <sdCalendarButton />
          <sdExportButton />
          <sdShareButton />
          <sdButton size="small" type="primary">
            <sdFeatherIcons type="plus" size="14" />
            Add New
          </sdButton>
        </div> -->
      </template>
    </sdPageHeader>
    <Main>
      <a-spin indicator="activity" size="large" />
      <center><h1>Система загружается. Пожалуйста, подождите</h1></center>
<!--      <a-row :gutter="25">-->
<!--        <a-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="10">-->
<!--          <BannerWrapper>-->
<!--            <sdCards-->
<!--              :bodyStyle="{-->
<!--                background: '#272B41',-->
<!--                borderRadius: '10px',-->
<!--                minHeight: '270px',-->
<!--              }"-->
<!--              :headless="true"-->
<!--            >-->
<!--              <Figure3 class="theme-3">-->
<!--                <img :src="require('@/static/img/onboarding/img1.png')" alt="" />-->
<!--                <figcaption>-->
<!--                  <h2>Готовы зарабатывать?</h2>-->
<!--                  <p>Давайте создадим и настроим ваш первый<br> проект на платформе Vibes.</p>-->

<!--                  <router-link :to="{name: 'new_project'}">-->
<!--                    <sdButton size="large" type="white">-->
<!--                      Начать работу-->
<!--                    </sdButton>-->
<!--                  </router-link>-->
<!--                </figcaption>-->
<!--              </Figure3>-->
<!--            </sdCards>-->
<!--          </BannerWrapper>-->
<!--        </a-col>-->

<!--        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="7">-->
<!--          <BannerWrapper>-->
<!--            <sdCards-->
<!--              :bodyStyle="{-->
<!--                background: 'linear-gradient(132.74deg, #E8505B 1.06%, #EAC360 96.82%)',-->
<!--                borderRadius: '10px',-->
<!--                minHeight: '270px',-->
<!--              }"-->
<!--              :headless="true"-->
<!--            >-->
<!--              <Figure4 class="theme-4">-->
<!--                <img :src="require('@/static/img/onboarding/img2.png')" alt="" />-->
<!--                <figcaption>-->
<!--                  <h2>Пройдите бесплатный онлайн марафон</h2>-->
<!--                  <p>Расскажем, как работает бизнес на каналах по подписке и как быстро запустить свой.</p>-->
<!--                  <sdButton size="large" type="white">-->
<!--                    Пройти марафон-->
<!--                  </sdButton>-->
<!--                </figcaption>-->
<!--              </Figure4>-->
<!--            </sdCards>-->
<!--          </BannerWrapper>-->
<!--        </a-col>-->

<!--        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="7">-->
<!--          <BannerWrapper>-->
<!--            <sdCards-->
<!--              :bodyStyle="{-->
<!--                background: 'linear-gradient(132.74deg, #5C50E8 1.06%, #EA60A2 96.82%)',-->
<!--                borderRadius: '10px',-->
<!--                minHeight: '270px',-->
<!--              }"-->
<!--              :headless="true"-->
<!--            >-->
<!--              <Figure5 class="theme-5">-->
<!--                <img :src="require('@/static/img/onboarding/img3.png')" alt="" />-->
<!--                <figcaption>-->
<!--                  <h2>Нужен запуск под ключ?</h2>-->
<!--                  <p>Наши эксперты создадут и упакуют закрытый канал за 14 дней.</p>-->
<!--                  <sdButton size="large" type="white">-->
<!--                    Узнать подробнее-->
<!--                  </sdButton>-->
<!--                </figcaption>-->
<!--              </Figure5>-->
<!--            </sdCards>-->
<!--          </BannerWrapper>-->
<!--        </a-col>-->
<!--      </a-row>-->
    </Main>
  </div>
</template>

<script>
import { Main } from '../../../styled';
import { Figure3, BannerWrapper, Figure4, Figure5 } from '../style';

export default {
  name: 'OnboardingHome',
  components: {
    Main, Figure3, BannerWrapper, Figure4, Figure5
  },
};
</script>
