<template>
  <ArticleTabContentWrap>
    <div class="sDash_article-tab-content">
      <a-row>
        <a-col :md="8" :sm="12" :xs="24">
          <div class="sDash_article-tab-signle">
            <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
            <ul class="sDash_article-tab-signle__list">
              <li>
                <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
              </li>
              <li>
                <router-link to="#">Switch between accounts</router-link>
              </li>
              <li>
                <router-link to="#">Change your email</router-link>
              </li>
              <li>
                <router-link to="#">Reactivate your account</router-link>
              </li>
            </ul>
            <router-link to="/page/knowledgebase/single/1" class="btn-link">
              See more <ArrowRightOutlined />
            </router-link>
          </div>
        </a-col>
        <a-col :md="8" :sm="12" :xs="24">
          <div class="sDash_article-tab-signle">
            <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
            <ul class="sDash_article-tab-signle__list">
              <li>
                <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
              </li>
              <li>
                <router-link to="#">Switch between accounts</router-link>
              </li>
              <li>
                <router-link to="#">Change your email</router-link>
              </li>
              <li>
                <router-link to="#">Reactivate your account</router-link>
              </li>
            </ul>
            <router-link to="/page/knowledgebase/single/1" class="btn-link">
              See more <ArrowRightOutlined />
            </router-link>
          </div>
        </a-col>
        <a-col :md="8" :sm="12" :xs="24">
          <div class="sDash_article-tab-signle">
            <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
            <ul class="sDash_article-tab-signle__list">
              <li>
                <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
              </li>
              <li>
                <router-link to="#">Switch between accounts</router-link>
              </li>
              <li>
                <router-link to="#">Change your email</router-link>
              </li>
              <li>
                <router-link to="#">Reactivate your account</router-link>
              </li>
            </ul>
            <router-link to="/page/knowledgebase/single/1" class="btn-link">
              See more <ArrowRightOutlined />
            </router-link>
          </div>
        </a-col>
        <a-col :md="8" :sm="12" :xs="24">
          <div class="sDash_article-tab-signle">
            <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
            <ul class="sDash_article-tab-signle__list">
              <li>
                <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
              </li>
              <li>
                <router-link to="#">Switch between accounts</router-link>
              </li>
              <li>
                <router-link to="#">Change your email</router-link>
              </li>
              <li>
                <router-link to="#">Reactivate your account</router-link>
              </li>
            </ul>
            <router-link to="/page/knowledgebase/single/1" class="btn-link">
              See more <ArrowRightOutlined />
            </router-link>
          </div>
        </a-col>
        <a-col :md="8" :sm="12" :xs="24">
          <div class="sDash_article-tab-signle">
            <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
            <ul class="sDash_article-tab-signle__list">
              <li>
                <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
              </li>
              <li>
                <router-link to="#">Switch between accounts</router-link>
              </li>
              <li>
                <router-link to="#">Change your email</router-link>
              </li>
              <li>
                <router-link to="#">Reactivate your account</router-link>
              </li>
            </ul>
            <router-link to="/page/knowledgebase/single/1" class="btn-link">
              See more <ArrowRightOutlined />
            </router-link>
          </div>
        </a-col>
        <a-col :md="8" :sm="12" :xs="24">
          <div class="sDash_article-tab-signle">
            <h2 class="sDash_article-tab-signle__title">Introduction to Theme</h2>
            <ul class="sDash_article-tab-signle__list">
              <li>
                <router-link to="/page/knowledgebase/single/1">Log in and out of Themes</router-link>
              </li>
              <li>
                <router-link to="#">Switch between accounts</router-link>
              </li>
              <li>
                <router-link to="#">Change your email</router-link>
              </li>
              <li>
                <router-link to="#">Reactivate your account</router-link>
              </li>
            </ul>
            <router-link to="/page/knowledgebase/single/1" class="btn-link">
              See more <ArrowRightOutlined />
            </router-link>
          </div>
        </a-col>
      </a-row>
    </div>
  </ArticleTabContentWrap>
</template>

<script>
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import { ArticleTabContentWrap } from '../style';
export default {
  name: 'ArticleTheme',
  components: {
    ArrowRightOutlined,
    ArticleTabContentWrap,
  },
};
</script>
