<template>
    <div v-if="message !== null && message !== undefined">
        <div class="atbd-chatbox__message">
            <div class="message-forwarded-from" v-if="message.is_forwarded">
              <sdFeatherIcons type="corner-up-right" size="12" color="#a1a1a1" /><span>Переслано от {{ message.forward_sender_name }}</span>
            </div>
            <MessageList class="message-box" v-if="message.type == 'text'"  @click="onMsgClicked">
              <div v-html="getMessageMarkdown(message)"></div>
              <div v-if="message.is_edited" class="message-edited-note">
                изменено
              </div>
            </MessageList>

            <MessageList class="message-box message-box-with-photo" v-if="message.type == 'photo'">
                <img :src="getFileUrl(message.file_id)" class="message-box-photo" v-img-fallback="require('@/static/img/fallback.jpg')"/>
              <div class="message-box-caption" v-if="message.text != ''" v-html="getMessageMarkdown(message)" @click="onMsgClicked"></div>
                <div v-if="message.is_edited" class="message-edited-note">
                изменено
                </div>
            </MessageList>

            <MessageList class="message-box message-box-with-photo" v-if="message.type == 'video'">
                <video controls :poster="getFileUrl(message.thumb_file_id)" class="message-box-photo">
                  <source :src="getFileUrl(message.file_id)"/>
                </video>
                <div class="message-box-caption" v-if="message.text != ''" v-html="getMessageMarkdown(message)" @click="onMsgClicked"></div>
                <div v-if="message.is_edited" class="message-edited-note">
                изменено
                </div>
            </MessageList>

            <div v-if="message.type == 'audio' || message.type == 'voice'">
                <MessageList class="message-box message-box-with-audio" v-if="message.text != ''">
                    <audio controls :src="getFileUrl(message.file_id)"></audio>
                    <div class="message-box-caption" v-if="message.text != ''" v-html="getMessageMarkdown(message)" @click="onMsgClicked"></div>
                    <div v-if="message.is_edited" class="message-edited-note">
                    изменено
                    </div>
                </MessageList>
                <div v-else>
                    <audio controls :src="getFileUrl(message.file_id)"></audio>
                </div>
            </div>

            <MessageList v-if="message.type == 'sticker'" class="message-box message-box-sticker">
                <video autoplay loop muted :poster="getFileUrl(message.file_id)">
                    <source :src="getFileUrl(message.file_id)"/>
                </video>
            </MessageList>

            <MessageList v-if="message.type == 'video_note'" class="message-box message-box-videoNote">
                <video controls :poster="getFileUrl(message.thumb_file_id)" class="message-box-photo"><source :src="getFileUrl(message.file_id)"/></video>
            </MessageList>

            <MessageList v-if="message.type == 'document' && message.additional_fields !== null" class="message-box message-box-document">
                <div class="document-wrap">
                    <img v-if="message.thumb_file_id !== null" class="document-thumb" :src="getFileUrl(message.thumb_file_id)" v-img-fallback="require('@/static/img/fallback.jpg')"/>
                    <img :src="require(`@/static/img/file_type/${getFileIcon(message)}.svg`)" v-else class="document-icon"/>
                    <div class="document-data">
                        <b v-if="message.additional_fields.file_name !== undefined" class="document-name">{{ message.additional_fields.file_name }}</b>
                        <div class="document-footer">
                            <span class="document-size" v-if="message.additional_fields.file_size !== undefined">{{ humanFileSize(message.additional_fields.file_size) }}</span>
                            <a :href="getFileUrl(message.file_id)" class="document-download" target="_blank">Скачать</a>
                        </div>
                    </div>
                </div>
                <div class="message-box-caption" v-if="message.text !== ''" v-html="getMessageMarkdown(message)"></div>
                <div v-if="message.is_edited" class="message-edited-note">
                изменено
                </div>
            </MessageList>

        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { textRefactor, limitTextLength } from "../../../../components/utilities/utilities";
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {BlockSpan, MessageList} from "../style";
import { EntityMessage } from "../../../../utility/tgEntitiesParser";
import {getFileUrl} from "../../../../utility/utility";

export default {
    name: 'SingleMessage',
    components: {
        BlockSpan,
        MessageList
    },
    props: {
        message: {}
    },
    setup(props) {

        const humanFileSize = function(bytes, si=false, dp=1) {
            const thresh = si ? 1000 : 1024;

            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }

            const units = si
                ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['кб', 'мб', 'гб', 'тб', 'пб', 'EiB', 'ZiB', 'YiB'];
            let u = -1;
            const r = 10**dp;

            do {
                bytes /= thresh;
                ++u;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


            return bytes.toFixed(dp) + ' ' + units[u];
        }

        const getFileIcon = function(file)
        {
            console.log(file.additional_fields.mime_type);

            switch(file.additional_fields.mime_type) {
                case 'image/svg+xml':
                    return 'SVG';

                case 'application/vnd.xmind.workbook':
                    return 'XMIND';

                case 'application/pdf':
                    return 'PDF';

                case 'image/jpeg':
                case 'image/jpg':
                    return 'JPEG';

                case 'image/png':
                    return 'PNG';

                case 'application/postscript':
                    return (file.additional_fields.mime_type.includes('.eps')) ? 'EPS' : 'AI';

                case 'application/zip':
                    return 'ZIP';

                case 'text/php':
                    return 'PHP';

                case 'text/html':
                    return 'HTML';

                default: return 'DEFAULT';
            }

            return 'DEFAULT';
        }

        const getMessageMarkdown = function(msg)
        {
            if(msg.text_entities === null || msg.text_entities === undefined)
                return msg.text;

            if(msg.text_entities.length === 0)
                return msg.text;

            const formatter = new EntityMessage(msg.text, msg.text_entities);

            return formatter.html;
        }

        const onMsgClicked = function(e)
        {
            var target = e.target;

            if(e.target.classList.contains('tg-spoiler')) {
                e.target.classList.toggle('tg-spoiler-shown');
            }
        }


        return {
            faArrowsAltV,
            textRefactor,
            limitTextLength,
            humanFileSize,
            getFileIcon,
            getMessageMarkdown,
            onMsgClicked,
            getFileUrl
        }
    }
};
</script>

<style scoped>
.slot {
    background-color: #aaa;
    padding: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-black {
    color: #000;
}

.message-container {
    margin-top: 20px;
}

.add-message-btn-container {
    margin-top: 20px;
}

.message-label {
    font-weight: 500;
    margin-bottom: 10px;
}

.message-options {
    text-align: center;
    padding-top: 22px;
}

.message-remove {
    margin-top: 20px;
}

.message-flex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.message-flex .message-content {
    flex-grow: 1;
}

.message-trigger {
    cursor: move;
}

</style>
