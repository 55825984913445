<template>
  <div v-if="lastMessage !== null && lastMessage !== undefined">
      <BlockSpan class="author-chatText">
          <div v-if="lastMessage.type == 'text'">
              {{ limitTextLength(lastMessage.text, 35) }}
          </div>
          <div v-if="lastMessage.type == 'photo'" style="color: #355DFF; display: flex; align-items: center; gap: 7px;">
              <sdFeatherIcons type="image" size="14" color="#355DFF" /><span style="line-height: 20px; display: inline-block;">Фотография</span>
          </div>
          <div v-if="lastMessage.type == 'document'" style="color: #355DFF; display: flex; align-items: center; gap: 7px;">
              <sdFeatherIcons type="file" size="14" color="#355DFF" /><span style="line-height: 20px; display: inline-block;">Файл</span>
          </div>
          <div v-if="lastMessage.type == 'sticker'" style="color: #355DFF; display: flex; align-items: center; gap: 7px;">
              <sdFeatherIcons type="heart" size="14" color="#355DFF" /><span style="line-height: 20px; display: inline-block;">Стикер</span>
          </div>
          <div v-if="lastMessage.type == 'video_note'" style="color: #355DFF; display: flex; align-items: center; gap: 7px;">
              <sdFeatherIcons type="play-circle" size="14" color="#355DFF" /><span style="line-height: 20px; display: inline-block;">Видеосообщение</span>
          </div>
          <div v-if="lastMessage.type == 'video'" style="color: #355DFF; display: flex; align-items: center; gap: 7px;">
              <sdFeatherIcons type="video" size="14" color="#355DFF" /><span style="line-height: 20px; display: inline-block;">Видео</span>
          </div>
          <div v-if="lastMessage.type == 'voice' || lastMessage.type == 'audio'" style="color: #355DFF; display: flex; align-items: center; gap: 7px;">
              <sdFeatherIcons type="mic" size="14" color="#355DFF" /><span style="line-height: 20px; display: inline-block;">Аудиосообщение</span>
          </div>

      </BlockSpan>

  </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { textRefactor, limitTextLength } from "../../../../components/utilities/utilities";
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {BlockSpan} from "../style";

export default {
  name: 'LastMessageSnippet',
  components: {
    BlockSpan
  },
  props: {
    lastMessage: {}
  },
  setup(props) {

    return {
      faArrowsAltV,
        textRefactor,
        limitTextLength
    }
  }
};
</script>

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}

.message-container {
  margin-top: 20px;
}

.add-message-btn-container {
  margin-top: 20px;
}

.message-label {
  font-weight: 500;
  margin-bottom: 10px;
}

.message-options {
  text-align: center;
  padding-top: 22px;
}

.message-remove {
  margin-top: 20px;
}

.message-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.message-flex .message-content {
  flex-grow: 1;
}

.message-trigger {
  cursor: move;
}

</style>
