<template>
  <sdPageHeader title="Общие настройки"> </sdPageHeader>
  <Main>
    <a-row :gutter="25">
      <a-col :sm="24" :xs="24">
        <sdCards>
          <a-form
            layout="horizontal"
            labelAlign="left"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :model="formState"
          >
            <a-form-item label="Название проекта">
              <a-input v-model:value="formState.name" placeholder="Например: Закрытый клуб CS:GO" />
            </a-form-item>
            <a-form-item label="Описание проекта">
              <a-textarea v-model:value="formState.onboarding_description" placeholder="Например: " />
            </a-form-item>
            <a-form-item label="Ссылка на сайт проекта">
              <a-input v-model:value="formState.onboarding_link" placeholder="" />
            </a-form-item>
            <a-form-item label="Токен бота Telegram">
              <a-input v-model:value="formState.tg_bot_token" placeholder="" disabled/>
            </a-form-item>
            <a-form-item label="Статус проекта">
              <div v-if="project.is_active == 0">
                Ваш проект не активирован. Закончите настройку и подайте заявку на модерацию, нажав на кнопку ниже.<br>

                <sdButton type="primary" outlined="true" size="small">Активировать проект</sdButton>
              </div>
              <div v-else>
                Проект активен. Вы можете принимать платежи и выводить деньги.
              </div>
            </a-form-item>

            <a-row>
              <a-col :lg="{ span: 16, offset: 8 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
                <div class="sDash_form-action">
                  <sdButton class="sDash_form-action__btn" type="primary" size="large">
                    Сохранить
                  </sdButton>
                </div>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>

</style>
<script>
import { BasicFormWrapper, Main } from '../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

const GeneralSettings = {
  name: 'GeneralSettings',
  components: {
    BasicFormWrapper,
    PlusOutlined,
    MinusOutlined,
    Main,
    SpinerWraperStyle
  },
  setup() {
    const { state, dispatch } = useStore();

    const project = ref({});

    // @todo: валидация

    const formState = reactive({
      name: '',
      onboarding_description: '',
      onboarding_link: '',
      tg_bot_token: ''
    });

    const labelCol = {
      lg: 8,
      md: 9,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 15,
      xs: 24,
    };


    axios.get('/auth/project', {
      params: {id: activeProjectId()}
    }).then(response => {
      project.value = response.data.project;

      formState.name = project.value.name;
      formState.onboarding_description = project.value.onboarding_description;
      formState.onboarding_link = project.value.onboarding_link;
      formState.tg_bot_token = project.value.tg_bot_token;
    })



    return {
      project,

      formState,
      labelCol,
      wrapperCol
    };
  },
};

export default GeneralSettings;
</script>
