<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Управление пользователями" subtitle="Test" description="Test 2">
        <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Добавить пользователя</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="24">

        <a-spin indicator="activity" size="large" v-if="isLoading"/>

        <div v-else>
        <sdCards headless>
          <UserTableStyleWrapper>
            <div class="contact-table">
              <TableWrapper class="table-responsive">
                <a-table
                  :dataSource="usersTableData"
                  :columns="packagesTableColumns"
                />
              </TableWrapper>
            </div>
          </UserTableStyleWrapper>
        </sdCards>
        </div>
      </a-col>
      <sdModal
        :type="localState.modalType"
        title="Добавить нового пользователя"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal" v-show="!showCreatedUser">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
                <a-form-item label="Имя, Фамилия" name="name">
                  <a-input v-model:value="addFormState.name" placeholder="Иван Иванов" />
                </a-form-item>

                <a-form-item label="Телефон (нужен для срочных уведомлений)" name="phone">
                  <a-input type="text" v-model:value="addFormState.phone" placeholder="+79991112233" />
                </a-form-item>

                <a-form-item label="Email (для авторизации)" name="email">
                  <a-input type="email" v-model:value="addFormState.email" placeholder="hello@tgpodbor.ru" />
                </a-form-item>

                <a-form-item label="Пароль" name="password">
                  <a-input type="text" v-model:value="addFormState.password" placeholder="Придумайте надежный пароль" />
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleOk" :disabled="isFormSending">
                  {{ isFormSending ? 'Загрузка...' : 'Создать пользователя'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
        <div v-show="showCreatedUser">
          <p><b>Пользователь успешно добавлен.</b></p>

          <p style="color: red;">Сообщение с данными для входа показывается только один раз. Сохраните их и отправьте пользователю.</p>

          <div style="border: 1px solid red; padding: 20px; margin-top: 15px;">
            <b>Вы были приглашены в TgPodbor CRM.</b><br><br>

            Ваша ссылка для входа: <a :href="createdUserData.login_url" target="_blank">{{ createdUserData.login_url }}</a><br>
            Ваш Email для входа: {{ createdUserData.email }}<br>
            Ваш пароль для входа: {{ createdUserData.password }}
          </div>

          <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleCancel" style="margin-top: 20px;">
            Закрыть
          </sdButton>
        </div>
      </sdModal>
      <sdModal
        :type="localState.modalType"
        title="Редактирование пользователя"
        :visible="localState.editVisible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contactEdit" :rules="rulesEdit" :model="editFormState" ref="editFormRef" >
                <a-form-item label="Имя, Фамилия" name="name">
                  <a-input v-model:value="editFormState.name" placeholder="Иван Иванов" />
                </a-form-item>

                <a-form-item label="Телефон (нужен для срочных уведомлений)" name="phone">
                  <a-input type="text" v-model:value="editFormState.phone" placeholder="+79991112233" />
                </a-form-item>

                <a-form-item label="Email (для авторизации)" name="email">
                  <a-input type="email" v-model:value="editFormState.email" placeholder="hello@tgpodbor.ru" :disabled="true" />
                </a-form-item>

                <a-form-item label="Пароль (оставьте пустым, если не хотите его менять)" name="password">
                  <a-input type="text" v-model:value="editFormState.password" placeholder="Придумайте надежный пароль" />
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleEditOk" :disabled="isFormSending">
                  {{ isFormSending ? 'Загрузка...' : 'Сохранить пользователя'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
  .ant-table-column-title {
    font-size: 14px !important;
  }

  table tbody tr td {
    font-weight: normal !important;
  }
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';
import {message, Modal} from 'ant-design-vue';

import { periodUnits } from '@/config/enum';
import {useRouter} from "vue-router";
import {randomPassword} from "@/utility/utility";

const Manage = {
  name: 'Manage',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {
    const addFormState = reactive({
      name: '',
      email: '',
      password: '',
      phone: ''
    });


    const editFormState = reactive({
      name: '',
      email: '',
      password: '',
      phone: '',
      id: ''
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(true);
    const isFormSending = ref(false);

    const router = useRouter();

    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      phone: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        }
      ],
      email: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: 'Пожалуйста, введите настоящий email',
          trigger: 'blur',
        },
      ],
      password: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 12,
          max: 200,
          message: 'Из соображений безопасности, минимальная длина пароля - 12 символов',
          trigger: 'blur',
        }
      ],
    };

    const rulesEdit = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      phone: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        }
      ]
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
      addFormState.password = randomPassword(16);
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.name = data.name;
      editFormState.id = data.id;
      editFormState.email = data.email;
      editFormState.password = data.password;
      editFormState.phone = data.phone;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const showCreatedUser = ref(false);
    const createdUserData = ref({
      email: '',
      password: '',
      login_url: ''
    });


    const handleOk = values => {
      addFormRef.value
        .validate()
        .then(() => {
          isFormSending.value = true;

          axios.post('/settings/users/add', {
            project_id: activeProjectId(),
            name: addFormState.name,
            email: addFormState.email,
            phone: addFormState.phone,
            password: addFormState.password
          }).then(response => {
            if(response.data.status == 'error') {
              message.error(response.data.message);
              return;
            }

            usersList.value = response.data.users;

            createdUserData.value.email = response.data.email;
            createdUserData.value.password = response.data.password;
            createdUserData.value.login_url = response.data.login_url;

            showCreatedUser.value = true;


            //handleCancel();
          }).finally(() => {
            isFormSending.value = false;
          });
        });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
        .validate()
        .then(() => {
          isFormSending.value = true;

          axios.post('/settings/users/edit', {
            project_id: activeProjectId(),
            id: editFormState.id,
            name: editFormState.name,
            phone: editFormState.phone,
            password: editFormState.password,
          }).then(response => {
            usersList.value = response.data.users;

            handleCancel();
          }).finally(() => {
            isFormSending.value = false;
          });
        });
    };

    const handleCancel = () => {
      onCancel();

      showCreatedUser.value = false;
    };

    const onHandleDelete = id => {
      alert('Удаление тарифов пока недоступно, но мы работаем над этим. Вы можете удалить тариф через техническую поддержку или просто отредактировать его.');
    };

    const usersList = ref([]);
    const pkgs = computed(() => usersList.value);

    const usersTableData = computed(() => {
      return usersList.value
        .sort((a, b) => {
          return a.id - b.id;
        })
        .map(pkg => {
          const { id, name, email, phone } = pkg;

          return {
            key: id,
            id,
            name,
            email,
            phone,
            action: (
                <Action class="table-actions">
                  <sdDropdown
                      class="wide-dropdwon"
                      overlay={
                        <>
                          <a onClick={() => showEditModal(pkg, id)} to="#">
                            <span>Редактировать</span>
                          </a>
                          <a onClick={() => blockUser()} to="#">
                            <span>Заблокировать</span>
                          </a>
                        </>
                      }
                      action={['click']}
                  >
                    <sdButton class="btn-icon" type="info" to="#" shape="circle">
                      <sdFeatherIcons type="more-vertical" size={16} />
                    </sdButton>
                  </sdDropdown>
                </Action>
            ),
          };
        });
    });

    axios.get('/settings/users/check_available', {params: {project_id: activeProjectId()}})
        .then(response => {
          if(response.data.status == 'error') {
            message.error('У вас нет доступа к этой функции');
            router.push('/');
            return;
          }

          axios.get('/settings/users/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            usersList.value = response.data.users;
            isLoading.value = false;
          });
        });

    const packagesTableColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Имя, Фамилия',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];

    const blockUser = function() {
      message.info('Блокировка пользователя через панель невозможна. Пожалуйста, обратитесь к системному администратору.');
    }

    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      packagesTableColumns,
      usersTableData,
      usersList,
      pkgs,

      rules,
      rulesEdit,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading,
      isFormSending,
      blockUser,

      showCreatedUser,
      createdUserData
    };
  },
};

export default Manage;
</script>
