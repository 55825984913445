<template>
  <Main>
    <div v-show="!isMobile" style="margin-top: 20px;">
      <a-row :gutter="30" style="height: 90vh; height: calc(100vh - 110px);">
        <a-col :xxl="6" :xl="6" :lg="8" :md="10" :sm="8" :xs="24" style="height: 100%;">
          <ChatSidebar style="height: 100%;">
            <router-view></router-view>
          </ChatSidebar>
        </a-col>
        <a-col :xxl="18" :xl="18" :lg="16" :md="14" :sm="16" :xs="24" style="height: 100%;">
          <router-view name="child" :key="$route.fullPath" style="height: 100%;"></router-view>
        </a-col>
      </a-row>
    </div>
    <div v-show="isMobile">
      <a-row :gutter="0" style="height: 90vh; overflow: hidden;" :style="{'height': mainViewSize}" v-show="mobileMode == 'list'">
        <a-col :xxl="24" :sm="24" :xs="24" style="height: 100%;">
          <ChatSidebar style="height: 100%;">
            <router-view></router-view>
          </ChatSidebar>
        </a-col>
      </a-row>
      <a-row :gutter="0" style="height: 90vh; position: relative;" :style="{'height': mainViewSize}"  v-show="mobileMode == 'dialog'">
        <div style="position: absolute; left: 3px; top: 11px; width: 40px; height: 40px; font-weight: bold; font-size: 24px; z-index: 1000;" @click="backToList">
          <sdFeatherIcons type="chevron-left" size="36" color="#ADB4D2" />
        </div>

        <a-col :xxl="24" :sm="24" :xs="24" style="height: 100%;">
          <router-view name="child" :key="$route.fullPath" style="height: 100%;"></router-view>
        </a-col>
      </a-row>
    </div>
  </Main>
</template>
<script>
import { UL, Content, ChatSidebar } from './style';
import { Main } from '../../styled';
import {computed, ref, onMounted, watch, nextTick, onBeforeMount} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { firebaseInit } from '@/firebase.js';


const ChatApp = {
  name: 'ChatApp',
  components: { Main, UL, Content, ChatSidebar, PerfectScrollbar },
  setup() {
    const { state } = useStore();
    const route = useRoute();
    const match = computed(() => route.matched[1]);
    const rtl = computed(() => state.themeLayout.rtlData);
    const left = computed(() => (!rtl.value ? 'left' : 'right'));

    const innerWidth = ref(window.innerWidth);
    const innerHeight = ref(window.innerHeight);
    const headerHeight = ref(65);


    const onResize = function() {
      innerWidth.value = window.innerWidth;
      innerHeight.value = window.innerHeight;
    }

    const isMobile = computed(() => innerWidth.value <= 768);

    const mobileMode = ref('list');

    const mainViewSize = computed(function() {
      return (innerHeight.value - headerHeight.value - 1) + 'px';
    });

    onMounted(function() {
      firebaseInit();

      if(route.fullPath !== '/chat/all' && route.fullPath !== '/chat/all/')
        mobileMode.value = 'dialog';

      nextTick(() => {
        window.addEventListener('resize', onResize)

        var item = document.getElementsByClassName('ant-layout-header')[0];

        headerHeight.value = item.offsetHeight;
      });
    });

    onBeforeMount(function() {
      window.removeEventListener('resize', onResize);
    });

    watch(() => route.fullPath, () => {
      console.log(`watch route.fullPath changed to ${route.fullPath}`);

      if(route.fullPath == '/chat/all' || route.fullPath == '/chat/all/')
        mobileMode.value = 'list';
      else
      mobileMode.value = 'dialog';
    });

    const backToList = function() {
      mobileMode.value = 'list';
    }

    return {
      rtl,
      left,
      match,
      isMobile,
      mobileMode,
      backToList,
      innerHeight,
      mainViewSize
    };
  },
};

export default ChatApp;
</script>
<style scoped>
.ps {
  height: 650px;
}

@media screen and (max-width: 768px) {
  body {
    height: 300px !important;
    overflow: hidden !important;
  }
}
</style>
