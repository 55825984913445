import Styled from 'vue3-styled-components';

const ChatWrapper = Styled.div`
    @media screen and (max-width: 1199px) {
    -webkit-overflow-scrolling: touch !important;

    * {
        -webkit-overflow-scrolling: touch !important;
    }
    }

  .create-action{
    padding: 0 25px;
    margin: 25px 0 18px;
    @media only screen and (max-width: 379px){
      padding: 0 20px;
    }
    .btn-add{
      width: 100%;
      height: 44px;
      text-align: center;
      border: 1px solid ${({ theme }) => theme['border-color-light']};
      background: ${({ theme }) => theme['bg-color-light']};
      color: ${({ theme }) => theme['gray-color']};
      svg,
      img,
      i{
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 4px;
      }
    }
  }
`;

const ReadFilterSwitch = Styled.div`
    width: 100%;
    padding: 0 25px;
    margin-top: 15px;
    
    .switch-group {
        background: #F3F6FF;
        border-radius: 6px;
        padding: 2px;
        display: flex;
        flex-direction: row;
        
        button {
            flex-grow: 1;
            flex-basis: 0;
            background: transparent;
            border: none;
            outline: 0;
            padding: 5px;
            color: #212633;
            font-size: 11px;
            font-weight: 500;
            cursor: pointer;
            
            transition: background 0.3s ease-in-out;
            
            &:hover {
                background: rgba(255, 255, 255, 0.5);
            }
            
            &.active {
            background: #FFFFFF;
            border: 1px solid #E2E9FC;
            box-shadow: 0px 2px 6px rgba(209, 215, 234, 0.12), 0px 2px 1px rgba(209, 215, 234, 0.04);
            border-radius: 6px;
            }
        }
    }
`;

const UL = Styled.ul`
  list-style-type: none;
  li {
    display: inline-block;
    padding: 5px !important;
  }
`;

const Content = Styled.div`
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  ul{
    overflow-x: hidden;
  }
  

  
  .chat-link-signle{
    position: relative;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    margin: 0;
    @media(hover: hover) and (pointer: fine) {
        &:before{
          content: '';
          position: absolute;
          width: calc(100% + 20px);
          height: 100%;
          background: #fff;
          right: -10px;
          left: -10px;
          box-shadow: 0 15px 50px ${({ theme }) => theme['light-color']}20;
          border-radius: 4px;
          visibility: hidden;
          opacity: 0;
          transition: 0.3s ease;
          z-index: 1;
        }

        &:hover:before{
          visibility: visible;
          opacity: 1;
        }
    }
    &.chat-link-active {
      background: #EFF0F3;
    }
    a{
      display: flex;
      padding: 20px 24px;
      position: relative;
      z-index: 1;
    }
    .author-figure{
      ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
      flex: .1;
      img{
        max-width: 46px;
        border-radius: 50%;
      }
      
      position: relative;
      
      .dialog-pinned-icon {
        background: #355DFF;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
        position: absolute;
        right: 0;
        bottom: -5px;
      }

    }
    .author-info{
      ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
      position: relative;
      flex: .76;
      
      
      .author-name{
        font-weight: 600;
        color: ${({ theme }) => theme['dark-color']};
            white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
      }
      .author-chatText{
        color: ${({ theme }) => theme['light-color']};
            white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
      }
    }
    .author-chatMeta{
      flex: .18;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-aling: right;
      @media (max-width: 991px){
        align-items: center;
        justify-content: center;
      }
      span{
        float: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
        font-size: 12px;
        color: ${({ theme }) => theme['light-color']};
      }
      .ant-badge{
        margin-top: 8px;
        span{
          color: #fff;
        }
      }
    }
  }
  
    @media screen and (max-width: 1199px) {
    .chat-link-signle {
        cursor: pointer !important;
        
        * { cursor: pointer !important; }
    }
  }
`;

const BlockSpan = Styled.span`
  display: block;
`;

const ForwardList = Styled.div`
    ul {
        li {
            display: flex;
            gap: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 1px solid #ededed;
            align-items: center;
            background: transparent;
            
            transition: background 0.3s ease-in-out;
            cursor: pointer;
            
            &:hover {
                background: #ededed;
            }
            
            &.item-observer {
                padding: 0;
                border: 0;
                height: 3px;
            }
            
            
            img {
                border-radius: 50%;
                width: 32px;
                height: 32px;
            }
        }
    }

`;

const FoldersWrapper = Styled.div`
.folders-list {
    border-top: 1px solid #e8e8e8;
    margin-top: 10px;
    .folder-list-item {        
        .f-i {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 1px solid #e8e8e8;

            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            
            .name {
                flex-grow: 1;
                flex-shrink: 10;
            }
            
            .btns {
                flex-shrink: 1;
            }
        }
    } 
   }
`;

const SingleChatWrapper = Styled.div`




  &.group-chat{
    .chatbox-reply-form{
      input{
        background: #fff !important;
        box-shadow: 0 5px 30px ${({ theme }) => theme['light-color']}15;
      }
    }
  }
  >.ant-card{
    .ant-card-body{
      padding: 25px 0 !important;
      
      height: 95%; 
      height: calc(100% - 80px);
      padding-top: 0px !important;
    }
  }
  .ant-card-head{
    padding: 0 30px !important;
    box-shadow: 0 10px 20px ${({ theme }) => theme['extra-light-color']}15;
    border: 0 none;
    .ant-card-extra{
      .ant-dropdown-trigger{
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 0 !important;
      }
    }
    .ant-card-head-title{
      h1{
        font-weight: 500;
        margin-bottom: 6px;
      }
      p{
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
        color: ${({ theme }) => theme['light-color']};
      }
    }
    
    @media screen and (max-width: 768px) {
        padding-right: 18px !important;
        padding-left: 45px !important;
        
        .ant-card-head-title {
            h1 {
                margin-bottom: 0px !important;
                font-size: 14px !important;
            }
        }
    }
  }
  
  .ant-card-head-wrapper {
    @media screen and (max-width: 768px) {
        flex-flow: row !important;
        
        .ant-card-head-title {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            > div {
                    flex-flow: row !important;
    
            }
        }
    }
  }

  .group-chat-header{
    padding: 8px 0;
    align-items: center;
    width: 100%;
    text-align: center;
    @media only screen and (max-width: 479px){
      flex-flow: column;
    }
    h1{
      @media only screen and (max-width: 479px){
        margin-bottom: 15px !important;
      }
    }
    .members{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        :not(:last-child){
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 4px;
        }
      }
      img{
        max-width: 30px;
      }
      .show-more,
      .add-more{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        span{
          margin: 0;
        }
      }
      .show-more{
        background-color: ${({ theme }) => theme['primary-color']};
        span{
          font-size: 10px;
          font-weight: 500;
          color: #fff;
        }
      }
      .add-more{
        border: 1px dashed ${({ theme }) => theme['border-color-deep']};
        span{
          line-height: .4;
        }
      }
    }
  }

  .atbd-chatbox{
    height: 450px;
    
    height: 90%;
    /*height: calc(100% - 80px);*/
    
    .message-forwarded-from {
     font-size: 12px !important;
     color: #a1a1a1;
     font-weight: 300;
     
     span {
        display: inline-block;
        margin-left: 5px;
     }
    }
    
    overflow-y: auto;
    overflow-x: hidden;
    .time-connector{
      position: relative;
      z-index: 10;
      margin: -14px 0 15px;
      &:after{
        position: absolute;
        width: 100%;
        height: 1px;
        ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme['border-color-light']};
        content: '';
        z-index: -1;
      }
      span{
        text-transform: capitalize;
        font-size: 13px;
        padding: 0 24px;
        background-color: #fff;
        color: ${({ theme }) => theme['light-color']};
      }
      
      @media screen and (max-width: 768px) {
        margin: 0px 0 15px;
        
        span {
            font-size: 11px;
        }
      }
    }


    .wrap-for-select {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-end;

        .message-wrap {
            flex-grow: 1;
        }

        .select-control {
            width: 22px;
            height: 22px;
            border: 2px solid #d9dadd;
            border-radius: 50%;
            transition: all 0.3s linear;
            position: relative;
        }

        position: relative;

        &.selected {
            /*&:before {
                display: block;
                content: ' ';
                position: absolute;
                width: 150%;
                height: 100%;
                left: -200px;
                top: 6px;
                background: rgb(167 179 255 / 13%);
                z-index: 0;
            }*/

            .select-control {
                background: #355DFF;
                border-color: #355dff;

                &:after {
                    display: block;
                    content: ' ';
                    width: 16px;
                    height: 16px;
                    color: #ffffff;
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6666 3.5L5.24998 9.91667L2.33331 7' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    background-size: cover;
                    position: absolute;
                    left: 1px; top: 1px;
                }
            }
        }
    }

    .select-control-wrap {
        display: none;
    }

    &.is-select-mode {
        .select-control-wrap {
            display: block;
        }
    }

    .atbd-chatbox__single{
      padding: 0 25px;

      &.message-deleted {
        opacity: 0.5;
      }


      &:not(:last-child){
        margin-bottom: 30px;
        
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
      }
      &:last-child{
        padding-bottom: 5px;
      }
      .left{
        display: flex;
        align-items: flex-start;
        img{
          max-width: 46px;
          border-radius: 50%;
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
          
          @media screen and (max-width: 768px) {
            max-width: 32px;
          }
        }
        .atbd-chatbox__content{
          margin-top: -4px;
        }
        .message-box{
          background: ${({ theme }) => theme['primary-color']};
        }
      }
      .right{
        float: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
        .atbd-chatbox__actions{
          margin: ${({ theme }) => (theme.rtl ? '0 0 0 15px' : '0 15px 0 0')};
        }
        .atbd-chatbox__name{
          text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
        }
        .message-box{

          ${({ theme }) => (!theme.rtl ? 'border-radius: 15px 15px 0 15px;' : 'border-radius: 15px 15px 15px 0')};
          background: ${({ theme }) => theme['bg-color-deep']};
          color: ${({ theme }) => theme['gray-color']};

          a {
            color: #1890ff !important;
          }
        }

        .atbd-chatbox__contentInner {
            justify-content: flex-end;
        }
      }
    }
    .atbd-chatbox__name{
      font-size: 14px;
      font-weight: 600;
      span{
        font-size: 12px;
        font-weight: 400;
        margin-left: 15px;
        color: ${({ theme }) => theme['light-color']};
      }
      
      
        .message-deleted-tooltip {
        color: red;
        display: inline-block;
        margin-left: 10px;
        line-height: 20px;
      }
      
       @media screen and (max-width: 768px) {
       font-size: 12px;
       margin-bottom: 0px;
        span {
            font-size: 11px;
            margin-left: 8px;
        }
      }

    }
    .atbd-chatbox__contentInner{
      align-items: center;
      .message-box + .message-box{
        margin-top: 5px;
      }
    }
    .message-seen{
      margin-top: 10px;
      &.text-right{
        text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
      }
      img{
        max-width: 20px;
        border-radius: 50%;
      }

      .message-deleted-tooltip {
        color: red;
        display: inline-block;
        margin-left: 10px;
        line-height: 20px;
      }

      .message-seen__time{
        font-size: 12px;
        font-weight: 400;
        color: ${({ theme }) => theme['light-color']};
      }
      
       @media screen and (max-width: 768px) {
       margin-top: 5px;
        .message-seen__time {
            font-size: 11px;
        }
      }
    }
    .group-seen{
      margin-top: 5px;
      text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
      img{
        max-width: 20px;
        &:not(:last-child){
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 3px;
        }
      }
    }
    .atbd-chatbox__actions{
      display: flex;
      align-items: center
      ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
      @media only screen and (max-width: 767px){
        flex-flow: column;
      }
      .ant-dropdown-trigger{
        line-height: 1;
        color: ${({ theme }) => theme['border-color-deep']};
        .anticon-smile{
          color: ${({ theme }) => theme['extra-light-color']};
        }
        &{
          + .ant-dropdown-trigger{
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
            @media only screen and (max-width: 767px){
              margin: 10px 0 0;
            }
          }
        }
      }
    }

  }
`;

const MessageList = Styled.div`
  display: block;
  max-width: 670px;
  font-size: 15px;
  ${({ theme }) => (!theme.rtl ? 'border-radius: 0 15px 15px' : 'border-radius: 15px 0 15px 15px')};
  padding: 18px 20px;
  line-height: 1.67;
  color: #fff;
  white-space: pre-wrap;
  
overflow-wrap: break-word;
word-wrap: break-word;
-ms-word-break: break-all;
word-break: break-all;
word-break: break-word;
-ms-hyphens: auto;
-moz-hyphens: auto;
-webkit-hyphens: auto;
hyphens: auto;
  
  @media screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 1.5;
    max-width: 350px;
    padding: 9px 15px;
  }

  .message-edited-note {
    display: block;
    text-align: right;
    font-size: 10px;
    font-style: italic;
  }

    a {
        color: #ffffff !important;
        text-decoration: underline !important;
    }

    .tg-spoiler {
        cursor: pointer;
        background-image: url(${require(`@/static/img/spoiler-dots-white.png`)});
        background-size: auto min(100%,1.125rem);
        border-radius: 0.5rem;
        color: rgba(255, 255, 255, 0);
        animation: pulse-opacity-light 1.75s linear infinite;
        transition: all 0.3s linear;

        &.tg-spoiler-shown {
            color: #ffffff;
            animation: none !important;
            background: transparent !important;
        }
    }

    @keyframes pulse-opacity-light {
      25% {
        opacity: 1;
      }
      50% {
        opacity: 0.25;
      }
      75% {
        opacity: 1;
      }
    }


  &.message-box-sticker {
    background: none !important;
    max-width: 200px !important;
    padding: 0px !important;

    video, img {
        border-radius: 0px !important;
        width: 100%;
        max-width: none !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
  }

  &.message-box-document {
    background: #ffffff !important;
    border-radius: 15px !important;
    border: 1px solid #e7e9ec !important;

    .message-box-caption {
        color: #5A5F7D;
        padding-top: 15px;

        a {
            color: #5A5F7D !important;
        }
    }


    .document-wrap {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        color: #000000 !important;

        .document-name {
            max-width: 220px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            padding: 0;
            margin: 0;
        }

        .document-thumb {
            width: 80px !important;
            height: 80px !important;
            max-width: none !important;
            border-radius: 15px !important;
            object-fit: cover !important;
            border: 1px solid #e7e9ec !important;
            margin-right: 0px !important;
        }

        .document-icon {
            width: 64px !important;
            height: 64px !important;
            max-width: none !important;
            border-radius: 0px !important;
            margin-right: 0px !important;
        }

        .document-data {
            flex-grow: 1;
        }

        .document-footer {
            display: flex;
            gap: 12px;

            .document-size {
                color: #9b9fa6;
            }
        }

        .document-download {
            color: #1890ff !important;
        }
        
        @media screen and (max-width: 768px) {
            gap: 10px;
            
            .document-name {
                max-width: 175px;
            }
            
            .document-thumb, .document-icon {
                width: 48px !important;
                height: 48px !important;
            }
        }
    }
  }

  &.message-box-with-photo {
    padding: 0 !important;
    max-width: 360px !important;
    border-radius: 15px !important;

    .message-box-photo {
        width: 100%;
        max-width: none !important;
        border-radius: 15px !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        display: block;
    }

    .message-box-caption {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 18px;
    }
    
    @media screen and (max-width: 768px) {
        max-width: 240px !important;
    }
  }

  &.message-box-videoNote {
    background: none !important;
    max-width: 250px !important;
    padding: 0px !important;

    video, img {
        border-radius: 50% !important;
        width: 100%;
        max-width: none !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        border: 3px solid #000000;
    }
  }
`;

const MediumWrap = Styled.div`
    
    .medium-editor-container p {
        margin-bottom: 0em !important;
    }
    
    .medium-editor-container .editor {
        white-space: pre-wrap !important;
    }
    
    .medium-editor-container {
        font-size: 15px !important;
        line-height: 1.67 !important;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 100px;
        max-height: 500px;
        overflow-y: scroll;
        
        .editor {
            min-height: 100px !important;
        }
    }
    
   


`;

const Footer = Styled.div`
  padding: 0 0px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #ffffff;
  z-index: 1000;
  
  .form-reply {
    /*position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #ffffff;
    z-index: 1000;*/
  }
  
  .select-mode-bar {
    background: #EFF0F3;
    border: 1px solid #eaeaea;
    border-radius: 15px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 15px 50px #9299b820;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 35px;
    
    .select-close-btn {
        border: 0px !important;
        outline: 0 !important;
        background: transparent !important;
        border-radius: 50%;
        padding: 0px 6px;
        padding-top: 6px;
        transition: background 0.3s ease-in-out;
        
        &:hover {
            background: rgba(255, 255, 255, 0.6) !important;    
        }
    }
    
    .selected-count {
        font-size: 14px;
        font-weight: 500;
    }
    
    .action-btns {
        display: flex;
        gap: 15px;
    }
  }
  
  .chatbox-reply-form{
    position: relative;
    margin-top: 0px;
    align-items: flex-end;
    gap: 25px;
    
    border-top: 1px solid #EAEAEA;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    
   .chatbox-reply-btns {
    display: flex;
    align-items: flex-end;
    gap: 25px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
   }
        
    .smile-icon{
      position: relative;
      position: absolute;
      ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 25px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      z-index: 9999;
      @media only screen and (max-width: 575px){
        top: 26px;
      }
      emoji-picker{
          position: absolute;
          z-index: 999999999;
          bottom: 0;
          @media only screen and (max-width: 991px){
            right: auto;
            left: 100%;
          }
          @media only screen and (max-width: 479px){
            right: auto;
            left: -35px;
          }
          .emoji-search{
            padding: 8px;
            height: auto;
            border-radius: 5px;
          }
      }
      .ant-dropdown-trigger{
        display: flex;
        align-items: center;
      }
      a{
        display: flex;
      }
      svg,
      i{
        color: ${({ theme }) => theme['extra-light-color']};
      }
    }
    &.hasFile{
      .ant-upload-list{
        &.ant-upload-list-text{
          padding: 15px;
        }
      }

    }
    .ant-upload-list{
      display: flex;
      position: absolute;
      top: -70px;
      ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 0;
    }
    .chatbox-reply-input{
      width: 100%;
      ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
      input, textarea{
        /*padding: ${({ theme }) => (theme.rtl ? '15px 35px 15px 40px' : '15px 35px 15px 40px')};*/
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 50px;
        max-height: 200px;
        border: 0 none;
        border-radius: 0px;
        resize: none;
        /*background: ${({ theme }) => theme['bg-color-normal']};*/
        &::placeholder{
          font-size: 14px;
          color: ${({ theme }) => theme['light-color']};
        }
        &:focus{
          border: 0 none;
          outline: none;
        }
        @media only screen and (max-width: 575px){
          height: 50px;
          margin-bottom: 20px;
        }
      }
    }
    input,
    span{
      ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
    }
    .chatbox-reply-action{
      align-items: center;
      a{
        span{
          display: block;
        }
      }
      .btn-send{
        box-shadow: 0 8px 13px #5F63F220;
      }
      .ant-upload-select,
      button{
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        span{
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
          color: #fff;
        }
      }
      .ant-upload-select {
        background: ${({ theme }) => theme['bg-color-normal']};
        .ant-upload{
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
          svg{
            margin-top: 8px;
            color: ${({ theme }) => theme['light-color']};
          }
        }
      }
      button{
        padding: 0;
        background: ${({ theme }) => theme['primary-color']};
      }
    }

    .ant-upload-select-picture-card{
      margin: 0;
      width: 50px;
      border-radius: 50% !important;
      border: 0 none;
      background-color: ${({ theme }) => theme['bg-color-normal']} !important;
      svg,
      i{
        margin-top: 7px !important;
      }
    }
    .ant-upload-list{
      &.ant-upload-list-text{
        display: block;
        top: auto;
        bottom: 136px;
        background: #fff;
        padding: 0;
        border-radius: 5px;
        box-shadow: 0 5px 20px #9299B803;
        >div{
          display: block;
        }
        .ant-upload-list-item{
          height: 24px;
          border-radius: 4px;
          background-color: ${({ theme }) => theme['bg-color-normal']};
          button{
            width: auto;
            height: auto;
            min-width: auto;
            background-color: ${({ theme }) => theme['bg-color-normal']};
            svg{
              color: ${({ theme }) => theme['info-color']};
            }
          }
        }
        .ant-upload-list-item-info{
          >span{
            display: flex;
            align-items: center;
          }
          .ant-upload-list-item-name {
            font-size: 13px;
          }
          .anticon,
          .ant-upload-list-item-name {
            color: ${({ theme }) => theme['info-color']};
          }
        }
      }
      &.ant-upload-list-picture-card{
        .ant-upload-list-picture-card-container{
          width: 60px;
          height: 50px;
        }
        .ant-upload-list-item{
          width: 60px;
          height: 50px;
          padding: 3px;
          .ant-upload-list-item-actions{
            .anticon-eye{
              display: none;
            }
            .anticon{
              margin: 0;
            }
          }
          .ant-upload-list-item-card-actions-btn{
            width: 25px;
            height: 25px;
            min-width: 25px;
          }
          .ant-upload-list-item-thumbnail{
            font-size: 11px;
          }
          .ant-upload-list-item-name{
            display: none;
          }
        }
      }
    }
  }
`;

const ForwardSearchbar = Styled.div`
    .chatbox-search{
        padding: 0 0px;
        padding-bottom: 15px;
        
        @media only screen and (max-width: 379px){
          padding: 0 0px;
        }
        
        .ant-select-selection-search{
          width: 100% !important;
        }
    
    .chatbox-search-flex {
        display: flex;
        border-radius: 5px;
        background: #F4F5F7;
        border: 0 none;
        gap: 10px;
        height: 46px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        border: 0px solid #F4F5F7;
        transition: box-shadow 0.1s linear;
        
        
        &:focus-within {
            //border: 2px solid red !important;
                box-shadow: 0 0 0 2px rgb(53 93 255 / 20%);
        }
        
        input {
            flex-grow: 1;
            border: 0px !important;
            outline: 0 !important;
            height: 100%;
            color: #272B41;
            font-size: 14px;
            line-height: 1.5715;
            background: transparent !important;
            
            ::placeholder {
                color: #B7B7B7;
            }
            
        }
    }
   
  }
`;

const ChatSidebar = Styled.div`

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 20px #9299B803;
  
  .ant-card-body {
    height: 100%;
  }

  .custom-scrollbar{
    >div{
      @media only screen and (max-width: 1800px){
        ${({ theme }) => (theme.rtl ? 'margin-left: 0 !important;' : 'margin-left: auto !important;')}:;
      }
    }
  }
  @media only screen and (max-width: 991px){
    // max-width: 370px;
    width: 100%;
    margin: 0 auto 40px;
  }
  @media only screen and (max-width: 575px){
    min-height: 580px;
  }
  @media only screen and (max-width: 768px){
    border-radius: 0px !important;
    margin: 0 auto;
  }
  .ant-card-body{
    padding: 28px 0 !important;
    
    @media only screen and (max-width: 768px){
     padding: 10px 0 !important;
     padding-bottom: 0px !important;
    }

  }
  
  
  .chatbox-search{
    padding: 0 25px;
    @media only screen and (max-width: 379px){
      padding: 0 20px;
    }
    .ant-select-selection-search{
      width: 100% !important;
    }
    
    .chatbox-search-flex {
        display: flex;
        border-radius: 23px;
        background: #F4F5F7;
        border: 0 none;
        gap: 10px;
        height: 46px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        border: 0px solid #F4F5F7;
        transition: box-shadow 0.1s linear;
        
        
        &:focus-within {
            //border: 2px solid red !important;
                box-shadow: 0 0 0 2px rgb(53 93 255 / 20%);
        }
        
        input {
            flex-grow: 1;
            border: 0px !important;
            outline: 0 !important;
            height: 100%;
            color: #272B41;
            font-size: 14px;
            line-height: 1.5715;
            background: transparent !important;
            
            ::placeholder {
                color: #B7B7B7;
            }
            
        }
    }
   
  }
  .ant-select-selector{
    height: auto !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input{
    border-radius: 23px;
    background: ${({ theme }) => theme['bg-color-normal']};
    border: 0 none;
    input{
      background: ${({ theme }) => theme['bg-color-normal']};
      height: 46px !important;
    }
  }
  nav{
    padding: 0 25px;
    @media only screen and (max-width: 1199px){
      padding: 0 15px;
    }
    .nav-ul{
      margin: 0 0 12px 0;
      padding: 30px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme['border-color-light']};
      @media (max-width: 480px){
        flex-direction: column;
      }
      
      @media only screen and (max-width: 768px){
        padding-top: 15px !important;
      }
      
      .v-hl-container {

      }
      
      .nav-li{
        padding: 0;
        a{
          position: relative;
          display: block;
          padding: 0 0 18px;
          font-weight: 400;
          color: ${({ theme }) => theme['light-color']};
          &:after{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            content: '';
            opacity: 0;
            visibility: hidden;
            background: ${({ theme }) => theme['primary-color']};
            @media only screen and (max-width: 1199px){
              display: none;
            }
          }
          &.active{
            font-weight: 500;
            color: ${({ theme }) => theme['primary-color']};
            &:after{
              opacity: 1;
              visibility: visible;
            }
          }
          .ant-badge{
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
          }
          @media (max-width: 480px){
            padding-bottom: 10px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
const BackShadowEmoji = Styled.div`
    width: 100%;
    height: 100%;
    background: #11121760;
    position: fixed;
    top: 0;
    ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
    z-index: 9999;
`;

const ReplyTemplatesWindow = Styled.div`
    .reply-window-list {
        border-top: 1px solid #EAEAEA;
        max-height: 300px;
        overflow: scroll;
    
        li {
            display: flex;
            gap: 20px;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #EAEAEA;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 30px;
            padding-right: 30px;
            background: #f9f9f9;
            
            transition: all 0.1s linear;
            
            &.add-new {
                background: #ffffff;
                color: #ababab;
            }
            
            &:hover {
                background: #355DFF;
                color: #ffffff !important;
                
                .reply-template-shortcode {
                    color: #ffffff !important;
                }
            }
            
            .reply-template-text {
                flex-grow: 1;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            
            .reply-template-shortcode {
                font-size: 14px;
                color: #ababab;
                transition: all 0.1s linear;
            }
        }
    }
`;

const DocumentPreviewList = Styled.div`
    max-width: 1000px;
    margin: 0 auto;
    
    .preview-documents {
        li {
            
        }
    }
`;

const CrmCardWrapper = Styled.div`
    .crm-card-icon-text {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
    }
`;

const FolderList = Styled.div`
    margin-top: 15px;
    
    ul {
        li {
            display: flex;
            gap: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 1px solid #ededed;
            align-items: center;
            background: transparent;
            
            transition: background 0.3s ease-in-out;
            cursor: pointer;
            
            &:hover {
                background: #ededed;
            }
            
            .folder-name {
                flex-grow: 1;
            }            
        }
    }

`;

const AntMenuDropdown = Styled.div`
    .ant-menu-item {
        display: block;
        i {
            display: inline-block; 
            margin-right: 10px;
        }
        
        background: #ffffff !important;
        color: #272B41 !important;
        padding-bottom: 0;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0px !important;
        
        &:hover {
            background-color: #f0f5ff !important;
            color: #5752EC !important;
        }
    }
    
    .ant-menu-submenu-title {
        i {
            display: inline-block; 
            margin-right: 10px;
        }
    }
    
    .ant-menu-item-selected {
    
    }
`;

const DialogHeader = Styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
        
    .dialog-header-avatar {
        border-radius: 50%;
    }   
    
    @media screen and (max-width: 768px) {
       .dialog-header-avatar {
           width: 40px;
           height: 40px;
       }   
    }

`;

export {
    ChatWrapper,
    UL,
    Content,
    BlockSpan,
    MessageList,
    Footer,
    ChatSidebar,
    SingleChatWrapper,
    BackShadowEmoji,
    ForwardList,
    ReadFilterSwitch,
    MediumWrap,
    ReplyTemplatesWindow,
    DocumentPreviewList,
    CrmCardWrapper,
    FolderList,
    AntMenuDropdown,
    DialogHeader,
    FoldersWrapper,
    ForwardSearchbar
};
