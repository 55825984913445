<template>
  <InfoWraper>
    <!-- @todo: notifications / settings / faq items -->
    <!-- <Message /> -->
     <!-- <Notification /> -->
    <!-- <Settings />
    <Support /> -->

    <SelectProject/>

<!--    <div class="nav-author">-->
<!--      <sdDropdown placement="bottomRight" :action="['click']">-->
<!--        <template v-slot:overlay>-->
<!--          <NavAuth>-->
<!--            <router-link @click="() => onFlagChangeHandle('ru')" to="#">-->
<!--              <img :src="require('../../../static/img/flag/ru.svg')" alt="" width="20" height="20" />-->
<!--              <span>Русский</span>-->
<!--            </router-link>-->
<!--            &lt;!&ndash; <router-link @click="() => onFlagChangeHandle('en')" to="#">-->
<!--              <img :src="require('../../../static/img/flag/en.png')" alt="" />-->
<!--              <span>English</span>-->
<!--            </router-link>&ndash;&gt;-->
<!--          </NavAuth>-->
<!--        </template>-->
<!--        <a to="#" class="head-example">-->
<!--          <img :src="require(`../../../static/img/flag/${flag}.svg`)" alt="" />-->
<!--        </a>-->
<!--      </sdDropdown>-->
<!--    </div>-->

    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info">
                <img :src="state.auth.user.avatar" width="46" alt="" />
                <figcaption>
                  <sdHeading as="h5">{{ state.auth.user.username }}</sdHeading>
                  <p>{{ state.auth.user.email }}</p>
                </figcaption>
              </figure>
<!--              <ul class="user-dropdwon__links">-->
<!--                <li>-->
<!--                  <a to="#"> <sdFeatherIcons type="user" /> Профиль </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a to="#"> <sdFeatherIcons type="settings" /> Настройки </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a to="#"> <sdFeatherIcons type="dollar-sign" /> Биллинг </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a to="#"> <sdFeatherIcons type="users" /> Активность </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a to="#"> <sdFeatherIcons type="bell" /> Помощь </a>-->
<!--                </li>-->
<!--              </ul>-->
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#"> <LogoutOutlined />Выйти из системы</a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar :src="state.auth.user.avatar" />
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Support from './Support';
import Settings from './Settings';
import Notification from './Notification';
import Message from './Message';

import SelectProject from './SelectProject';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LogoutOutlined } from '@ant-design/icons-vue';

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    NavAuth,
    UserDropDwon,
    Support,
    Settings,
    Notification,
    Message,
    LogoutOutlined,
    SelectProject
  },
  data() {
    return {
      flag: 'ru',
    };
  },
  setup() {
    const { push } = useRouter();
    const { dispatch, state } = useStore();

    const SignOut = e => {
      e.preventDefault();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('userAbility');

      // @todo: invalidate token server side

      window.location = '/auth/login'
    };

    return {
      SignOut,
      state,
    };
  },
  methods: {
    onFlagChangeHandle: function(value) {
      this.flag = value;
    },
  },
};
</script>
