<template>
  <AuthWrapper>
<!--    <p class="auth-notice">Нет аккаунта? <router-link to="/auth/register">Регистрация</router-link></p>-->
    <div class="auth-contents">

      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> Вход в <span class="color-secondary">аккаунт</span> </sdHeading>
        <a-form-item name="username" label="Ваш email">
          <a-input type="email" v-model:value="formState.email" placeholder="Email" />
        </a-form-item>
        <a-form-item name="password" label="Ваш пароль">
          <a-input type="password" v-model:value="formState.password" placeholder="Пароль" />
        </a-form-item>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">Запомнить меня</a-checkbox>
          <!-- <router-link class="forgot-pass-link" to="/auth/forgotPassword"> -->
<!--          <router-link class="forgot-pass-link" to="/auth/login">-->
<!--            Забыли пароль?-->
<!--          </router-link>-->
        </div>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large" @click="login" :disabled="isLoading">
            {{ isLoading ? 'Загрузка...' : 'Войти' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import axios from '@/config/axios.js'
import {message} from "ant-design-vue";

const SignIn = {
  name: 'SignIn',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = /*computed(() => state.auth.loading)*/ref(false);
    const checked = ref(null);
    const router = useRouter();

    const errorMessages = ref();

    const login = async () => {
      isLoading.value = true;

      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios
          .post('/auth/login', {email: formState.email, password: formState.password})
          .then(response => {
            const {accessToken} = response.data

            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', accessToken)

            return response
          })
          .then(() => {
            dispatch('checkAuth').then(() => {
              router.push('/');
            });
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to login!')
            console.log('error :>> ', error.response)
            message.error('Вы ввели неверный пароль');

            errorMessages.value = error.response.data.error
          })
          .finally(() => {
            console.log('123finished!');
           // router.push('/');

          })
      });

      isLoading.value = false;
    };

    const handleSubmit = () => {
      // router.push('/');
      // dispatch('login');
    };

    const onChange = checked => {
      checked.value = checked;
    };

    const formState = reactive({
      // email: 'hello@vibes.band',
      // password: '12345678',
      email: '',
      password: ''
    });


    return {
      isLoading,
      checked,
      handleSubmit,
      onChange,
      formState,
      login,
      errorMessages
    };
  },
};

export default SignIn;
</script>
