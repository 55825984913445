<template>
  <a-row :gutter="30" style="height: 100%;">
    <a-col :xxl="17" :xl="17" :lg="24" :md="24" :sm="24" :xs="24" style="height: 100%;">
      <SingleChatWrapper v-if="!isLoading" style="height: 100%;" ref="dialogContainerRef">

        <sdModal title="Переслать сообщение" :type="'primary'" :width="400" :visible="isForwardModalVisible" :onCancel="hideForwardModal">
          <ForwardList>
            <ForwardSearchbar>
            <div class="chatbox-search">
              <div class="chatbox-search-flex">
                <input type="text" placeholder="Поиск..." v-model="forwardSearchInput.text" @keyup="onChangeForwardSearchInput" @change="onChangeForwardSearchInput"/>
                <sdFeatherIcons type="search" size="16" color="#ADB4D2" v-if="forwardSearchInput.text.length === 0"/>
                <sdFeatherIcons type="x" size="16" color="#ADB4D2" v-else @click="resetForwardSearch" style="cursor: pointer;"/>
              </div>
            </div>
            </ForwardSearchbar>
            <SpinerWraperStyle style="background: transparent !important; padding: 10px !important; margin: 0 auto !important;" v-if="isLoadingForwardList">
              <a-spin indicator="activity" />
            </SpinerWraperStyle>
            <perfect-scrollbar
                ref="forwardScroll"
                v-else
                style="height: 500px;"
                :options="{
            wheelSpeed: 1,
            swipeEasing: true,
            suppressScrollX: true,
          }"
            >
              <ul>
                <li v-for="(item, index) in forwardList"
                    :key="item.id" @click="confirmForward(item)">
                  <img :src="getAvatarUrl(item)" v-img-fallback="require('@/static/img/fallback.jpg')"/>
                  <span v-if="item.type == 'private'">{{ item.first_name }} {{ item.last_name }}</span>
                  <span v-else>{{ item.title }}</span>
                </li>
                <li class="item-observer" v-observe-visibility="handleScrolledForwardModal">&nbsp;&nbsp;</li>
              </ul>
            </perfect-scrollbar>
          </ForwardList>
        </sdModal>

        <sdModal
            :type="'primary'"
            title="Добавить скрипт ответа"
            :visible="isReplyTemplateModalVisible"
            :onCancel="hideReplyTemplateModal"
        >
          <div class="project-modal">
            <BasicFormWrapper>
              <a-form name="contact" :model="addReplyTemplateFormState" ref="addFormRef">
                <a-form-item label="Сообщение со скриптом" name="message">
                  <a-textarea v-model:value="addReplyTemplateFormState.text" placeholder="Введите сообщение..." rows="6"  :disabled="isReplyTemplateFormLoading"/>
                </a-form-item>

                <a-form-item label="Шорткод (необязательно)" name="name">
                  <a-input placeholder="привет" v-model:value="addReplyTemplateFormState.shortcode" :disabled="isReplyTemplateFormLoading"/>
                </a-form-item>

                <small>Шорткод - короткое название для вашей команды. При вводе шорткода в окно набора сообщения, система автоматически предложит вставить ваш шаблон.</small>
                <br><br>
                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleSubmitReplyTemplateForm">
                  {{ isReplyTemplateFormLoading ? 'Загрузка...' : 'Сохранить скрипт'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </div>
        </sdModal>

        <sdModal
          :type="'primary'"
          title="Управление папками"
          :visible="isDialogFoldersModalVisible"
          :onCancel="hideDialogFoldersModal"
          >
          <div class="project-modal">
            <p>Вы можете прикрепить этот диалог к любым папкам. Ваши настройки папок будут видны только вам. Чтобы создать новую папку, нажмите на иконку папки в списке диалогов</p>

            <div><b>Этот диалог добавлен в папки:</b></div>

            <FoldersWrapper>
            <div class="folders-list">
              <div v-for="folder in foldersList" @click="toggleDialogFolder(folder.id)" class="folder-list-item">
                <div v-if="activeFolders.includes(folder.id)" class="f-i">
                  <div class="name">{{ folder.name }}</div>
                  <div class="btns">
                    <a-tooltip>
                      <template #title>Удалить</template>
                      <sdFeatherIcons type="trash" size="16" color="red" style="cursor: pointer;"/>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            </FoldersWrapper>

            <div style="margin-top: 20px;"><b>Диалог можно добавить в папки:</b></div>

            <FoldersWrapper>
            <div class="folders-list">
              <div v-for="folder in foldersList" @click="toggleDialogFolder(folder.id)" class="folder-list-item">
                <div v-if="!activeFolders.includes(folder.id)" class="f-i">
                  <div class="name">{{ folder.name }}</div>
                  <div class="btns">
                    <a-tooltip>
                      <template #title>Добавить</template>
                      <sdFeatherIcons type="plus" size="16" color="#ADB4D2" style="cursor: pointer;"/>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            </FoldersWrapper>
          </div>
        </sdModal>

        <sdModal
            :type="'primary'"
            title="Карточка сделки"
            :visible="isDealStatusModalVisible"
            :onCancel="hideDealStatusModal"
        >
          <div class="project-modal">
            <BasicFormWrapper>
              <form @change="dealInfoChanged" v-if="dialogInfo.deal !== undefined && dialogInfo.deal !== null">
                <a-divider orientation="left">Контакт</a-divider>

                <!--          <div class="crm-card-icon-text">-->
                <!--            <sdFeatherIcons type="send" size="14" />-->
                <!--            <span v-if="dialogInfo.username !== null"><a :href="'https://t.me/'+dialogInfo.username">{{ '@'+dialogInfo.username }}</a></span>-->
                <!--            <span v-else>{{ dialogInfo.telegram_id }}</span>-->
                <!--          </div>-->

                <a-input placeholder="Название компании" v-model:value="dialogInfo.deal.company_name" >
                  <template #prefix>
                    <sdFeatherIcons type="user" size="14" />
                  </template>
                </a-input>

                <a-input placeholder="Телефон" style="margin-top: 20px;" v-model:value="dialogInfo.deal.mobile_phone" >
                  <template #prefix>
                    <sdFeatherIcons type="phone" size="14" />
                  </template>
                </a-input>

                <a-input placeholder="Email" style="margin-top: 20px;" v-model:value="dialogInfo.deal.email" >
                  <template #prefix>
                    <sdFeatherIcons type="at-sign" size="14" />
                  </template>
                </a-input>

                <a-divider orientation="left">Сделка</a-divider>

                <a-select
                    v-model:value="dialogInfo.deal.deal_status_id"
                    style="width: 100%; margin-top: 20px;"
                    :options="dealStatusList"
                    :size="'large'"
                    @change="dealInfoChanged"
                >
                </a-select>

                <a-select
                    v-model:value="dialogInfo.deal.responsible_user_id"
                    style="width: 100%; margin-top: 20px;"
                    :options="responsibleUserList"
                    :size="'large'"
                    @change="dealInfoChanged"
                >
                </a-select>

                <a-input-number
                    style="width: 100%; margin-top: 20px;"
                    :size="'default'"
                    placeholder="Цена"
                    v-model:value="dialogInfo.deal.deal_sum"
                    :min="0"
                    :max="999999999"
                    :formatter="value => `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\₽\s?|(,*)/g, '')"
                />

                <a-divider orientation="left">Комментарии</a-divider>

                <a-textarea
                    placeholder="Комментарий"
                    v-model:value="dialogInfo.deal.comment"
                    name="unresizable-textarea"
                    class="sDash_unresizable"
                    :rows="3"
                />

                <sdButton size="default" type="primary" @click="(e) => { e.preventDefault(); dealInfoChanged(); hideDealStatusModal(); return false; }">
                  <span>Сохранить</span>
                </sdButton>
              </form>
            </BasicFormWrapper>
          </div>
        </sdModal>

        <sdCards style="height: 100%;">
          <template #button>
            <sdDropdown key="1">
              <template #overlay>
                <AntMenuDropdown>
                  <a-menu>
                    <a-menu-item @click="setAllRead">
                      <sdFeatherIcons type="check" size="14" @click="setAllRead" color="#7D7D7D"/>
                      <span>Пометить прочитанным</span>
                    </a-menu-item>
                    <a-menu-item @click="setUnread">
                      <sdFeatherIcons type="eye-off" size="14" @click="setUnread" color="#7D7D7D"/>
                      <span>Пометить непрочитанным</span>
                    </a-menu-item>
                    <a-menu-item @click="setPinned">
                      <sdFeatherIcons type="star" size="14" @click="setPinned" color="#7D7D7D"/>
                      <span v-if="!dialogInfo.is_pinned">Закрепить</span>
                      <span v-else>Открепить</span>
                    </a-menu-item>
                    <a-menu-item v-if="dialogInfo.username !== null" @click="openInNewTab('https://t.me/'+dialogInfo.username)">
                        <sdFeatherIcons type="send" size="14" color="#7D7D7D"/>
                        <span>Открыть аккаунт в Telegram</span>
                    </a-menu-item>
                    <a-menu-item v-if="isMobile || isTablet" @click="showDealStatusModal">
                      <sdFeatherIcons type="columns" size="14" color="#7D7D7D"/>
                      <span>Карточка сделки</span>
                    </a-menu-item>
                    <a-menu-item v-if="isMobile || isTablet" @click="showDialogFoldersModal">
                      <sdFeatherIcons type="folder" size="14" color="#7D7D7D"/>
                      <span>Папки</span>
                    </a-menu-item>
                    <a-sub-menu v-if="!isMobile && !isTablet">
                      <template #title>
                        <sdFeatherIcons type="folder" size="14" color="#7D7D7D"/>
                        <span>Папки</span>
                      </template>
                      <a-menu-item v-for="folder in foldersList" @click="toggleDialogFolder(folder.id)">
                        <sdFeatherIcons type="check" size="14" v-if="activeFolders.includes(folder.id)"/>
                        {{ folder.name }}
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item @click="removeDialog">
                      <sdFeatherIcons type="trash" size="14" @click="removeDialog" color="red"/>
                      <span>Удалить и выйти из чата</span>
                    </a-menu-item>
                  </a-menu>
                </AntMenuDropdown>
              </template>
              <a class="ant-dropdown-link" @click.prevent>
                <sdFeatherIcons size="24" type="more-vertical" />
              </a>
            </sdDropdown>
          </template>
          <template #title>
            <DialogHeader>
              <img :src="getAvatarUrl(dialogInfo)" width="48" class="dialog-header-avatar"/>
              <div>
                <sdHeading as="h5" v-if="dialogInfo.type == 'private'">
                  {{ dialogInfo.first_name }} {{ dialogInfo.last_name }}
                </sdHeading>
                <sdHeading as="h5" v-else>
                  {{ dialogInfo.title }}
                </sdHeading>
                <p>Последняя активность: {{ moment(dialogInfo.last_message.created_at).fromNow() }}</p>
              </div>
            </DialogHeader>
          </template>

          <div v-if="isLoadingMsg">
            <SpinerWraperStyle style="background: transparent !important; ">
              <a-spin indicator="activity" />
            </SpinerWraperStyle>
          </div>
          <div :class="{
            'atbd-chatbox': true,
            'is-select-mode': (isSelectMode)
        }" :style="{'height': dialogChatboxHeight}"  v-else>
            <perfect-scrollbar
                ref="perfectScroll"
                style="height: 100%;"
                :options="{
            wheelSpeed: 1,
            swipeEasing: true,
            suppressScrollX: true,
          }"
            >
              <ul ref="scrollObserveContainer">

                <li v-observe-visibility="handleLoadMore" >&nbsp;&nbsp;</li>
                <li
                    v-for="(item, index) in sortedMessages"
                    :key="item.id"
                    class="atbd-chatbox__single"
                    v-observe-visibility="{
                callback: (isVisible, entry) => handleMessageRead(isVisible, entry, item),
                throttle: 150,
                once: true
            }"
                    :class="{
                'atbd-chatbox__single': true,
                'message-deleted': (item.is_deleted)
            }"

                    :data-id="item.id"
                >
                  <div class="wrap-for-select" :class="{'selected': selectedIds.includes(item.id)}" @click="selectItem(item.id)">
                    <div class="select-control-wrap">
                      <div class="select-control"></div>
                    </div>
                    <div class="message-wrap">
                      <p v-if="index === 0" class="time-connector text-center text-capitalize">
                        <span v-if="moment(item.created_at).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')">Сегодня</span>
                        <span v-else-if="moment(item.created_at).format('MM-DD-YYYY') === moment().subtract(1, 'day').format('MM-DD-YYYY')">Вчера</span>
                        <span v-else>{{ moment(item.created_at).format('DD.MM.YYYY') }}</span>
                      </p>

                      <div v-if="index !== 0">
                        <p v-if="moment(item.created_at).format('MM-DD-YYYY') !== moment(sortedMessages[index-1].created_at).format('MM-DD-YYYY')" class="time-connector text-center text-capitalize">
                          <span v-if="moment(item.created_at).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')">Сегодня</span>
                          <span v-else-if="moment(item.created_at).format('MM-DD-YYYY') === moment().subtract(1, 'day').format('MM-DD-YYYY')">Вчера</span>
                          <span v-else>{{ moment(item.created_at).format('DD.MM.YYYY') }}</span>
                        </p>
                      </div>

                      <div :key="item.id" :style="{ overflow: 'hidden' }">
                        <div :class="item.from_id === 0 ? 'right' : 'left'">
                          <img v-if="item.from_id !== 0" :src="getAvatarUrl(item.from)" alt="" v-img-fallback="require('@/static/img/fallback.jpg')"/>
                          <div class="atbd-chatbox__content">
                            <sdHeading as="h5" class="atbd-chatbox__name">
                              <b v-if="item.from_id !== 0">
                                {{ item.from.telegram_first_name }} {{ item.from.telegram_last_name }}
                              </b>
                              <span v-if="item.from_id !== 0">{{
                                  moment(item.created_at).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')
                                      ? moment(item.created_at).format('HH:mm')
                                      : moment(item.created_at).format('DD.MM в HH:mm')
                                }}</span>
                              <a-tooltip v-if="item.is_deleted && item.from_id !== 0">
                                <template #title>Cообщение удалено {{ moment(item.deleted_at).format('DD.MM в HH:mm') }}</template>
                                <span class="message-deleted-tooltip">
                              <sdFeatherIcons type="trash" size="12"/>
                          </span>
                              </a-tooltip>
                            </sdHeading>

                            <div v-if="item.from_id !== 0" class="atbd-chatbox__contentInner d-flex">
                              <SingleMessage :message="item"></SingleMessage>

                              <div class="atbd-chatbox__actions" v-if="!item.is_deleted">
                                <sdDropdown :action="['hover']" placement="bottomCenter">
                                  <template #overlay>
                                    <div class="atbd-chatbox__messageControl">
                                      <ul>
                                        <li>
                                          <a @click="startSelectMode(item.id)">Выделить</a>
                                        </li>
                                        <li>
                                          <a @click="showForwardModal(item.id)">Переслать</a>
                                        </li>
                                        <li v-show="item.type == 'document' || item.type == 'video' || item.type == 'video_note' || item.type == 'photo' || item.type == 'audio' || item.type == 'voice' || item.type == 'sticker'">
                                          <a :href="getFileUrl(item.file_id)" target="_blank">Скачать</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </template>
                                  <sdFeatherIcons type="more-horizontal" size="16" />
                                </sdDropdown>
                              </div>
                            </div>

                            <div v-else class="atbd-chatbox__contentInner d-flex">
                              <div class="atbd-chatbox__actions" v-if="!item.is_deleted">
                                <sdDropdown :action="['hover']" placement="bottomCenter">
                                  <template #overlay>
                                    <div class="atbd-chatbox__messageControl">
                                      <ul>
                                        <li>
                                          <a @click="deleteMessage(item)">Удалить</a>
                                        </li>
                                        <li>
                                          <a @click="startSelectMode(item.id)">Выделить</a>
                                        </li>
                                        <li>
                                          <a @click="showForwardModal(item.id)">Переслать</a>
                                        </li>
                                        <li v-show="item.type == 'document' || item.type == 'video' || item.type == 'video_note' || item.type == 'photo' || item.type == 'audio' || item.type == 'voice' || item.type == 'sticker'">
                                          <a :href="getFileUrl(item.file_id)" target="_blank">Скачать</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </template>
                                  <sdFeatherIcons type="more-horizontal" size="16" />
                                </sdDropdown>
                              </div>
                              <SingleMessage :message="item"></SingleMessage>
                            </div>

                            <div v-if="item.from_id === 0" class="message-seen text-right">
                    <span class="message-seen__time">{{  moment(item.created_at).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')
                        ? moment(item.created_at).format('HH:mm')
                        : moment(item.created_at).format('DD.MM в HH:mm') }} </span>
                              <a-tooltip v-if="item.is_deleted">
                                <template #title>Cообщение удалено {{ moment(item.deleted_at).format('DD.MM в HH:mm') }}</template>
                                <span class="message-deleted-tooltip">
                              <sdFeatherIcons type="trash" size="12"/>
                          </span>
                              </a-tooltip>
                              <a-tooltip v-if="item.sent_by_user !== null && item.sent_by_user !== undefined">
                                <template #title>{{ item.sent_by_user.name }}</template>
                                <img :src="(item.sent_by_user.avatar_url !== null) ? item.sent_by_user.avatar_url: 'https://ui-avatars.com/api/?background=355DFF&name='+item.sent_by_user.name+'&size=128&color=FFFFFF'" alt="" style="border-radius: 50%; margin-left: 10px" v-img-fallback="require('@/static/img/fallback.jpg')"/>
                              </a-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li ref="lastMessageElem">&nbsp;&nbsp;</li>
              </ul>

            </perfect-scrollbar>
          </div>



          <Footer ref="dialogFooterRef">
            <ReplyTemplatesWindow v-if="replyTemplatesShown">
              <div class="reply-window">
                <ul class="reply-window-list">
                  <li class="add-new" @click="showReplyTemplateModal">
                  <span class="reply-template-text">
                      <sdFeatherIcons type="plus" size="14" />
                      Добавить новый скрипт
                  </span>
                  </li>
                  <li v-for="(item, index) in replyTemplates" @click="useReplyTemplate(item, $event)" class="reply-template-item">
                    <span class="reply-template-text">{{ item.text }}</span>
                    <span class="reply-template-shortcode">{{ item.shortcode }}</span>
                    <span class="reply-template-actions" @click="deleteReplyTemplate(item, $event)">
                      <sdFeatherIcons type="trash-2" size="14" color="#ababab" />
                  </span>
                  </li>
                </ul>
              </div>
            </ReplyTemplatesWindow>
            <ReplyTemplatesWindow v-if="!replyTemplatesShown && suggestedReplyTemplates.length > 0">
              <div class="reply-window">
                <ul class="reply-window-list">
                  <li v-for="(item, index) in suggestedReplyTemplates" @click="useReplyTemplate(item, $event)" class="reply-template-item">
                    <span class="reply-template-text">{{ item.text }}</span>
                  </li>
                </ul>
              </div>
            </ReplyTemplatesWindow>

            <div v-show="isSelectMode">
              <div class="select-mode-bar">
                <a-tooltip>
                  <template #title>Отменить выделение</template>
                  <button @click="stopSelectMode" class="select-close-btn">
                    <sdFeatherIcons type="x" size="24" />
                  </button>
                </a-tooltip>

                <div class="selected-count">
                  {{ selectedIds.length }} {{ plural(['сообщение', 'сообщения', 'сообщений'], selectedIds.length) }}
                </div>
                <div class="action-btns">
                  <sdButton size="default" shape="circle" :raised="true" type="white" @click="showForwardModal(null)">
                    <sdFeatherIcons type="corner-up-right" size="14" /> <span>Переслать</span>
                  </sdButton>
                  <sdButton size="default" shape="circle" :raised="true" type="danger" @click="massDeleteMessages">
                    <sdFeatherIcons type="trash-2" size="14" /> <span>Удалить</span>
                  </sdButton>
                </div>
              </div>
            </div>

            <form class="form-reply" v-show="!isSelectMode" @submit="handleSubmit" >
              <!--          <a>-->
              <!--            <a-upload v-bind="attachment">-->
              <!--            <sdFeatherIcons type="camera" size="18" />Документ-->
              <!--          </a-upload>-->
              <!--          </a>-->
              <div :class="`chatbox-reply-form d-flex`" >
                <div class="chatbox-reply-btns">
                  <div class="chatbox-attach-btn">
                    <sdDropdown :action="['hover']" placement="bottomCenter">
                      <template #overlay>
                        <div class="atbd-chatbox__messageControl">
                          <ul>
                            <li>
                              <a-upload
                                  v-bind="settingsAttachDocument"
                                  v-model:file-list="fileListDocument"
                                  name="image"
                                  ref="uploadFileRef"
                                  :show-upload-list="false"
                                  :action="getActionUrl('document')"
                                  :data="uploadData"
                                  :headers="uploadHeaders"
                                  class="custom-ant-upload"
                              >
                                <a>Документ</a>
                              </a-upload>
                            </li>
                            <li>
                              <a-upload
                                  v-bind="settingsAttachImage"
                                  v-model:file-list="fileListImage"
                                  name="image"
                                  ref="uploadFileRef"
                                  :show-upload-list="false"
                                  :action="getActionUrl('image')"
                                  :data="uploadData"
                                  :headers="uploadHeaders"
                                  class="custom-ant-upload"
                                  :accept="'image/jpeg,image/png'"
                              >
                                <a>Фотография</a>
                              </a-upload>
                            </li>
                            <li>
                              <a-upload
                                  v-bind="settingsAttachVideo"
                                  v-model:file-list="fileListVideo"
                                  name="image"
                                  ref="uploadFileRef"
                                  :show-upload-list="false"
                                  :action="getActionUrl('video')"
                                  :data="uploadData"
                                  :headers="uploadHeaders"
                                  class="custom-ant-upload"
                                  :accept="'video/mp4,video/mov,video/quicktime'"
                              >
                                <a>Видео</a>
                              </a-upload>
                            </li>
                          </ul>
                        </div>
                      </template>
                      <sdFeatherIcons type="paperclip" color="#A8A8A8" size="24" />
                    </sdDropdown>
                  </div>
                  <div class="chatbox-template-btn">
                    <a-tooltip>
                      <template #title>Скрипты ответов</template>
                      <a @click="switchReplyTemplatesMode" class="template-btn">
                        <sdFeatherIcons type="bookmark" color="#A8A8A8" size="24" v-if="!replyTemplatesShown"/>
                        <sdFeatherIcons type="x" color="#A8A8A8" size="24" v-else/>
                      </a>
                    </a-tooltip>
                  </div>
                </div>
                <div class="chatbox-reply-input">
                  <!--              <textarea-->
                  <!--                placeholder="Введите сообщение..."-->
                  <!--                name="chat"-->
                  <!--                id="chat"-->
                  <!--                :style="{ width: '100%' }"-->
                  <!--                v-model="inputValue"-->
                  <!--              />-->

                  <MediumWrap>
                    <medium-editor ref="mediumEditorRef" :options="mediumEditorOptions" :onChange="onChange"/>
                  </MediumWrap>

                  <div>
                    <DocumentPreviewList>
                      <ul class="preview-documents">
                        <li v-for="(item, index) in fileListDocument">
                        <span class="preview-icon">
                            <sdFeatherIcons type="file" size="14" color="#355DFF" />
                        </span>
                          <span class="preview-name">{{ item.name }}
                        <i v-if="item.status == 'uploading'">(загрузка {{Math.round(item.percent)+'%'}})</i>
                        </span>
                          <span class="preview-delete" @click="deleteAttachment('document')">
                          <sdFeatherIcons type="x" size="14" color="#ababab" />
                        </span>
                        </li>
                        <li v-for="(item, index) in fileListImage">
                        <span class="preview-icon">
                            <sdFeatherIcons type="image" size="14" color="#355DFF" />
                        </span>
                          <span class="preview-name">{{ item.name }}
                        <i v-if="item.status == 'uploading'">(загрузка {{Math.round(item.percent)+'%'}})</i>
                        </span>
                          <span class="preview-delete" @click="deleteAttachment('image')">
                          <sdFeatherIcons type="x" size="14" color="#ababab" />
                        </span>
                        </li>
                        <li v-for="(item, index) in fileListVideo">
                        <span class="preview-icon">
                            <sdFeatherIcons type="video" size="14" color="#355DFF" />
                        </span>
                          <span class="preview-name">{{ item.name }}
                        <i v-if="item.status == 'uploading'">(загрузка {{Math.round(item.percent)+'%'}})</i>
                        </span>
                          <span class="preview-delete" @click="deleteAttachment('video')">
                          <sdFeatherIcons type="x" size="14" color="#ababab" />
                        </span>
                        </li>
                      </ul>
                    </DocumentPreviewList>
                  </div>
                </div>
                <div class="chatbox-reply-action">
                  <sdButton @click="handleSubmit" type="primary" class="btn-send" :disabled="isMessageSending || isAnyFileUploading">
                    <sdFeatherIcons v-if="!isMessageSending && !isAnyFileUploading" type="send" size="18" />
                    <sdFeatherIcons v-else type="loader" size="18" />
                  </sdButton>
                </div>
                <!--            <div class="chatbox-reply-action d-flex">-->
                <!--              <a to="#">-->
                <!--                <a-upload v-bind="props">-->
                <!--                  <sdFeatherIcons type="camera" size="18" />-->
                <!--                </a-upload>-->
                <!--              </a>-->
                <!--                <a>-->
                <!--                  <sdFeatherIcons type="paperclip" size="18" />-->
                <!--                </a>-->
                <!--              <sdButton @click="handleSubmit" type="primary" class="btn-send" :disabled="isMessageSending">-->
                <!--                  <sdFeatherIcons v-if="!isMessageSending" type="send" size="18" />-->
                <!--                  <sdFeatherIcons v-else type="loader" size="18" />-->
                <!--              </sdButton>-->
                <!--            </div>-->
              </div>
            </form>
          </Footer>
        </sdCards>
      </SingleChatWrapper>
      <SpinerWraperStyle v-else style="background: transparent !important; ">
        <a-spin indicator="activity" />
      </SpinerWraperStyle>
    </a-col>
    <a-col :xxl="7" :xl="7" :lg="7" :md="11" :sm="11" :xs="24" style="height: 100%;" v-if="!isLoading && dialogInfo.deal !== undefined && dialogInfo.deal !== null" v-show="!isMobile && !isTablet">
      <CrmCardWrapper style="height: 100%;">
        <sdCards style="height: 100%;">
          <form @change="dealInfoChanged">
          <a-divider orientation="left">Контакт</a-divider>

<!--          <div class="crm-card-icon-text">-->
<!--            <sdFeatherIcons type="send" size="14" />-->
<!--            <span v-if="dialogInfo.username !== null"><a :href="'https://t.me/'+dialogInfo.username">{{ '@'+dialogInfo.username }}</a></span>-->
<!--            <span v-else>{{ dialogInfo.telegram_id }}</span>-->
<!--          </div>-->

          <a-input placeholder="Название компании" v-model:value="dialogInfo.deal.company_name" >
              <template #prefix>
                <sdFeatherIcons type="user" size="14" />
              </template>
          </a-input>

          <a-input placeholder="Телефон" style="margin-top: 20px;" v-model:value="dialogInfo.deal.mobile_phone" >
            <template #prefix>
              <sdFeatherIcons type="phone" size="14" />
            </template>
          </a-input>

          <a-input placeholder="Email" style="margin-top: 20px;" v-model:value="dialogInfo.deal.email" >
            <template #prefix>
              <sdFeatherIcons type="at-sign" size="14" />
            </template>
          </a-input>

          <a-divider orientation="left">Сделка</a-divider>

          <a-select
                v-model:value="dialogInfo.deal.deal_status_id"
                style="width: 100%; margin-top: 20px;"
                :options="dealStatusList"
                :size="'large'"
                @change="dealInfoChanged"
            >
          </a-select>

          <a-select
              v-model:value="dialogInfo.deal.responsible_user_id"
              style="width: 100%; margin-top: 20px;"
              :options="responsibleUserList"
              :size="'large'"
              @change="dealInfoChanged"
          >
          </a-select>

          <a-input-number
              style="width: 100%; margin-top: 20px;"
              :size="'default'"
              placeholder="Цена"
              v-model:value="dialogInfo.deal.deal_sum"
              :min="0"
              :max="999999999"
              :formatter="value => `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\₽\s?|(,*)/g, '')"
          />

          <a-divider orientation="left">Комментарии</a-divider>

          <a-textarea
              placeholder="Комментарий"
              v-model:value="dialogInfo.deal.comment"
              name="unresizable-textarea"
              class="sDash_unresizable"
              :rows="3"
          />
        </form>
        </sdCards>
      </CrmCardWrapper>
    </a-col>
  </a-row>

</template>
<script>
import moment from 'moment';
import {
  SingleChatWrapper,
  MessageList,
  Footer,
  ForwardList,
  MediumWrap,
  ReplyTemplatesWindow,
  DocumentPreviewList,
  CrmCardWrapper,
  AntMenuDropdown,
  DialogHeader,
  FoldersWrapper,
    ForwardSearchbar
} from '../style';
import { useStore } from 'vuex';
import {computed, onMounted, onBeforeUnmount, ref, watch, nextTick, createVNode, reactive} from 'vue';
import {message, Modal} from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import {SpinerWraperStyle} from "../../../uiElements/ui-elements-styled";
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import SingleMessage from "../components/SingleMessage";
import _ from 'lodash';
import { EntityMessage } from "../../../../utility/tgEntitiesParser";
import {ExclamationCircleOutlined, MessageOutlined} from "@ant-design/icons-vue";
import useSound from 'vue-use-sound';
import notificationSfx from '@/static/sent.mp3';
import { plural, getAvatarUrl } from "@/utility/utility.js";
import { BasicFormWrapper } from '@/view/styled';
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import {getFileUrl} from "../../../../utility/utility";

const SingleChat = {
  name: 'SingleChat',
  methods: {getFileUrl},
  components: {
    SingleMessage,
    SingleChatWrapper,
    MessageList,
    Footer,
    PerfectScrollbar,
    SpinerWraperStyle,
    ForwardList,
    MediumWrap,
    ReplyTemplatesWindow,
    BasicFormWrapper,
    DocumentPreviewList,
    CrmCardWrapper,
    AntMenuDropdown,
    DialogHeader,
    FoldersWrapper,
    ForwardSearchbar,
  },
  setup() {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const left = ref('left');


    const inputValue = ref('');
    const mediumEditorRef = ref();

    const fileListDocument = ref([]);
    const fileListImage = ref([]);
    const fileListVideo = ref([]);

    const isLoading = ref(true);
    const isLoadingMsg = ref(true);

    const dialogInfo = ref({});
    const messages = ref([]);

    const isMessageSending = ref(false);

    const lastMessageElem = ref();
    const perfectScroll = ref();

    const isLazyLoading = ref(true);
    const hasNext = ref(true);

    const scrollObserveContainer = ref();

    const isSelectMode = ref(false);
    const selectedIds = ref([]);

    const isForwardModalVisible = ref(false);
    const forwardScroll = ref();
    const isLoadingForwardList = ref(false);
    const forwardList = ref([]);
    const forwardHasNext = ref(true);
    const isForwardLazyLoading = ref(false);
    const forwardCurrentPageOffset = ref(0);
    const forwardMessageId = ref(null);

    const dialogContainerRef = ref();
    const dialogFooterRef = ref();
    const dialogContainerHeight = ref(null);
    const dialogFooterHeight = ref(null);
    const dialogHeaderHeight = ref(78.8);
    const dialogChatboxHeight = ref('calc(100% - 80px)');

    const replyTemplates = ref([]);
    const replyTemplatesShown = ref(false);
    const isReplyTemplateModalVisible = ref(false);
    const addReplyTemplateFormState = reactive({
      shortcode: '',
      text: '',
    });
    const isReplyTemplateFormLoading = ref(false);
    const suggestedReplyTemplates = ref([]);

    const dealStatusList = ref([]);
    const responsibleUserList = ref([]);

    const foldersList = ref([]);

    const innerWidth = ref(window.innerWidth);

    const onResize = function() {
      innerWidth.value = window.innerWidth;
    }

    const isMobile = computed(() => innerWidth.value <= 768);
    const isTablet = computed(() => innerWidth.value <= 1199 && innerWidth.value > 768);


    const startSelectMode = function(id) {
        selectedIds.value = [];

        if(id !== undefined)
            selectedIds.value.push(id);

        isSelectMode.value = true;
    }

    const stopSelectMode = function() {
        selectedIds.value = [];
        isSelectMode.value = false;
    }

    const selectItem = function(id) {
        if(!isSelectMode.value)
            return;

        if(selectedIds.value.includes(id)) {
            selectedIds.value = selectedIds.value.filter((e) => e !== id);

            if(selectedIds.value.length == 0)
                stopSelectMode();

            return;
        }

        selectedIds.value.push(id);
    }


    const getMessageMarkdown = function(msg)
    {
        if(msg.text_entities === null || msg.text_entities === undefined)
            return msg.text;

        if(msg.text_entities.length === 0)
            return msg.text;

        const formatter = new EntityMessage(msg.text, msg.text_entities);

        return formatter.html;
    }

    const deleteMessage = function(msg)
    {
        Modal.confirm({
            title:  'Вы точно хотите удалить это сообщение?',
            icon: createVNode(ExclamationCircleOutlined),
            content: (<div>После удаления оно пропадет у пользователя, но в CRM останется с пометкой "Удалено".<br/><br/><b>Хотите продолжить?</b></div>),
            okText: 'Да, удалить',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                axios.post('/chat/dialogs/messages/delete', {
                    project_id: activeProjectId(),
                    id: msg.id
                }).then(response => {
                    console.log(response);

                    if(response.data.status == 'error') {
                        message.error(response.data.message);
                        return;
                    }

                    messages.value = _.map(messages.value, function(a) {
                       return a.id === msg.id ? response.data.message : a;
                    });

                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const massDeleteMessages = function()
    {
      Modal.confirm({
        title:  'Вы точно хотите удалить эти сообщения?',
        icon: createVNode(ExclamationCircleOutlined),
        content: (<div>После удаления они пропадут у пользователя, но в CRM останутся с пометкой "Удалено".<br/><br/>Сообщения старше 48 часов не исчезнут у пользователя в Telegram.<br/><br/><b>Хотите продолжить?</b></div>),
        okText: 'Да, удалить',
        okType: 'danger',
        cancelText: 'Нет',
        onOk() {
          axios.post('/chat/dialogs/messages/delete/mass', {
            project_id: activeProjectId(),
            ids: selectedIds.value
          }).then(response => {
            console.log(response);
            stopSelectMode();

            if(response.data.status == 'error') {
              message.error(response.data.message);
              return;
            }

            response.data.messages.forEach(function(item) {
              messages.value = _.map(messages.value, function(a) {
                return a.id === item.id ? item : a;
              });
            });
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    }



    const handleSubmit = e => {
      if(e !== undefined)
        e.preventDefault();

      if(isMessageSending.value)
          return;

      if(isAnyFileUploading.value) {
        alert('Пожалуйста, дождитесь окончания загрузки файла!');
        return;
      }

      var countFiles = [...fileListVideo.value, ...fileListDocument.value, ...fileListImage.value].length;

      if(inputValue.value == '') {
        if(countFiles === 0)
          return;
      }

      var length = mediumEditorRef.value.editor.elements[0].innerText.length;

      if(countFiles == 0 && length >= 4096) {
        alert('Максимальная длина сообщения 4096 символов. Длина вашего сообщения '+length+' символов.');
        return false;
      }

      if(countFiles > 0 && length >= 1024) {
        alert('Максимальная длина сообщения с прикрепленным файлом 1024 символа. Длина вашего сообщения '+length+' символов.');
        return false;
      }

        isMessageSending.value = true;

        axios.post('/chat/dialogs/messages/send', {
            project_id: activeProjectId(),
            id: params.id,
            text: inputValue.value,
            document: fileListDocument.value,
            video: fileListVideo.value,
            image: fileListImage.value
        }).then(response => {
            console.log(response);

            messages.value.push(response.data.result);
            playSent();

          mediumEditorRef.value.editor.resetContent();

          inputValue.value = '';

            fileListImage.value = [];
            fileListVideo.value = [];
            fileListDocument.value = [];
        }).finally(() => {
            isMessageSending.value = false;
        });

    };


    const sortedMessages = computed(function() {
        return _.orderBy(messages.value, 'id');
    });

    const activeFolders = computed(function() {
      return dialogInfo.value.folders.map(item => item.id);
    });

    const interval = ref(null);
    const intervalSetRead = ref(null);

    const oldScrollHeight = ref(null);

    const resizeObserver = ref(null);
    const resizeDirection = ref('top');

      onMounted(function() {
        isLoading.value = true;

        axios.get('/chat/dialogs/info', {params: {project_id: activeProjectId(), id: params.id}})
              .then(response => {
                  dialogInfo.value = response.data.dialog;
                  isLoading.value = false;

                  isLoadingMsg.value = true;

                  axios.get('/chat/dialogs/messages', {params: {project_id: activeProjectId(), id: params.id}})
                      .then(response => {
                        isLoadingMsg.value = false;
                        messages.value = response.data.messages;
                        nextTick(function() {  scrollToBottom(); isLazyLoading.value = false;
                            oldScrollHeight.value = perfectScroll.value.$el.scrollHeight;

                            resizeObserver.value = new ResizeObserver(function(el) {
                               // console.log(perfectScroll.value.$el.scrollHeight);
                               // console.log(resizeDirection.value);
                               if(resizeDirection.value === 'top')
                                    perfectScroll.value.$el.scrollTop = perfectScroll.value.$el.scrollTop + (perfectScroll.value.$el.scrollHeight - oldScrollHeight.value);
                              // else perfectScroll.value.$el.scrollTop = perfectScroll.value.$el.scrollTop - (perfectScroll.value.$el.scrollHeight - oldScrollHeight.value);
                               oldScrollHeight.value = perfectScroll.value.$el.scrollHeight;
                            });
                            resizeObserver.value.observe(scrollObserveContainer.value);

                            dialogContainerHeight.value = dialogContainerRef.value.$el.clientHeight;
                            const observer = new ResizeObserver(function(el) {
                              dialogContainerHeight.value = dialogContainerRef.value.$el.clientHeight;
                              // console.log(el);
                              try {

                                dialogChatboxHeight.value = Math.round(dialogContainerHeight.value - dialogFooterHeight.value - dialogHeaderHeight.value) + 'px';
                              } catch(e) {
                                console.log('Dialog chatbox height observer, got an exception: ', e);
                              }
                            });
                            observer.observe(dialogContainerRef.value.$el);

                            dialogFooterHeight.value = dialogFooterRef.value.$el.clientHeight;
                            const observer2 = new ResizeObserver(function(el) {
                              dialogFooterHeight.value = dialogFooterRef.value.$el.clientHeight;
                              // console.log(el);

                              try {
                                dialogChatboxHeight.value = Math.round(dialogContainerHeight.value - dialogFooterHeight.value - dialogHeaderHeight.value) + 'px';
                              } catch(e) {
                                console.log('Dialog footer height observer, got an exception: ', e);
                              }
                            });
                            observer2.observe(dialogFooterRef.value.$el);

                          dialogChatboxHeight.value = Math.round(dialogContainerHeight.value - dialogFooterHeight.value - dialogHeaderHeight.value)+'px';
                        } );
                  });

                  interval.value = setInterval(function() {
                      axios.get('/chat/dialogs/messages/new', {params: {project_id: activeProjectId(), id: params.id, least_message_id: getLeastMessageId()}})
                          .then(response => {
                              handleNewMessages(response.data.messages, 'bottom');
                          });
                  }, 5000);
              });

        nextTick(() => {
          window.addEventListener('resize', onResize)
        });

      });

      nextTick(function() {

      });



      onBeforeUnmount(function() {
          clearInterval(interval.value);
          clearInterval(intervalSetRead.value);
          if(resizeObserver.value !== null && resizeObserver.value !== undefined)
            resizeObserver.value.disconnect();
          readedMsgSent.value = [];

          window.removeEventListener('resize', onResize);
      });




      const scrollToBottom = function()
      {
          perfectScroll.value.$el.scrollTop = perfectScroll.value.$el.scrollHeight;
      }

      const handleNewMessages = function(items, direction = 'top')
      {
          var oldLength = messages.value.length;

          var n = _.unionBy(items, messages.value, 'id');

          if(n.length != oldLength) {
              resizeDirection.value = direction;

              messages.value = n;

              // console.log('new message!');
          }
      }

      const getLastMessageId = function()
      {
          return _.minBy(messages.value, 'id').id;
      }

      const getLeastMessageId = function()
      {
          return _.maxBy(messages.value, 'id').id;
      }


      const handleLoadMore = function(isVisible) {
          if(!isVisible) return;

          if(isLazyLoading.value === true) return;

          if(!hasNext.value) return;

          isLazyLoading.value = true;

          var last_id = getLastMessageId();

          axios.get('/chat/dialogs/messages/history', {params: {project_id: activeProjectId(), id: params.id, last_message_id: last_id}})
              .then(response => {
                  handleNewMessages(response.data.messages);
                  isLazyLoading.value = false;
                  hasNext.value = response.data.has_more;
              });
      }

      const readedMsgPacked = ref([]);
      const readedMsgSent = ref([]);

      const handleMessageRead = function(isVisible, entry, item) {
          if(!isVisible) return;

          if(item.is_read)
              return;

          if(readedMsgSent.value.includes(item.id)) {
             // console.log('Already sent!', item.id);
          }

          readedMsgPacked.value.push(item.id);
      }

      intervalSetRead.value = setInterval(function() {
          if(readedMsgPacked.value.length == 0)
              return;

          axios.get('/chat/dialogs/messages/setRead', {params: {project_id: activeProjectId(), id: params.id, ids: readedMsgPacked.value}})
              .then(response => {
                  readedMsgPacked.value.forEach(function(item) {
                     readedMsgSent.value.push(item);
                  });

                  readedMsgPacked.value = [];
              });

      }, 3000);

      const setAllRead = function() {
          axios.get('/chat/dialogs/messages/setAllRead', {params: {project_id: activeProjectId(), id: params.id}})
              .then(response => {
                  // alert('All read!');
              });
      }

    const setUnread = function() {
      axios.get('/chat/dialogs/messages/setUnread', {params: {project_id: activeProjectId(), id: params.id}})
          .then(response => {
            // alert('All read!');
          });
    }


    const removeDialog = function() {
      Modal.confirm({
        title: 'Удалить и выйти из чата',
        icon: createVNode(ExclamationCircleOutlined),
        content: (<div>Хотите покинуть этот чат и навсегда удалить его?<br/><br/>Этот чат пропадет из CRM-системы и вы не будете получать новых сообщений от этого пользователя. Отменить это действие будет невозможно.</div>),
        okText: 'Выйти из чата',
        okType: 'danger',
        cancelText: 'Отмена',
        onOk() {
          axios.post('/chat/dialogs/remove', {
            project_id: activeProjectId(),
            id: dialogInfo.value.id
          }).then(response => {
            //console.log(response);

            if(response.data.status == 'error') {
              message.error(response.data.message);
              return;
            }

            alert('Диалог удален. Он более не будет отображаться в списке чатов.');
          });
        },
        onCancel() {

        }
      })
    };

      const setPinned = function() {
        Modal.confirm({
          title:  'Закрепить диалог',
          icon: createVNode(ExclamationCircleOutlined),
          content: (<div>Хотите закрепить этот диалог в списке?<br/><br/>Закрепленные диалоги отображаются одинаково для всех пользователей, это удобно использовать для командных чатов.</div>),
          okText: 'Закрепить',
          okType: 'success',
          cancelText: 'Нет',
          onOk() {
            axios.post('/chat/dialogs/pin', {
              project_id: activeProjectId(),
              id: dialogInfo.value.id
            }).then(response => {
              //console.log(response);

              if(response.data.status == 'error') {
                message.error(response.data.message);
                return;
              }

              dialogInfo.value = response.data.chat;
            });
          },
          onCancel() {
           // console.log('Cancel');
          },
        });
      }

    const [ playSent ] = useSound(notificationSfx)

    const hideForwardModal = function() {
        isForwardModalVisible.value = false;
    }

    const showForwardModal = function(message_id) {
        isForwardModalVisible.value = true;
        forwardMessageId.value = message_id;

        if(forwardList.value.length == 0) {
          loadForwardList();
        }
    }

    const loadForwardList = function() {
      isLoadingForwardList.value = true;
      forwardCurrentPageOffset.value = 0;

      axios.get('/chat/dialogs/forward/list', {params: {project_id: activeProjectId(), search: forwardSearchInput.text}})
          .then(response => {
                forwardList.value = response.data.dialogs;
                isLoadingForwardList.value = false;
                forwardHasNext.value = response.data.has_next;
              }
          );
    };

    const handleScrolledForwardModal = function(isVisible) {
      if(!isVisible) return;

      if(isForwardLazyLoading.value === true) return;

      if(!forwardHasNext.value) return;

      isLazyLoading.value = true;

      forwardCurrentPageOffset.value = forwardCurrentPageOffset.value + 1;

      axios.get('/chat/dialogs/forward/list', {params: {project_id: activeProjectId(), offset: forwardCurrentPageOffset.value, search: forwardSearchInput.text}})
          .then(response => {
            forwardList.value = response.data.dialogs;
            isForwardLazyLoading.value = false;
            forwardHasNext.value = response.data.has_next;
          });
    }

    const confirmForward = function(user) {
      Modal.confirm({
        title:  'Переслать сообщение?',
        icon: createVNode(MessageOutlined),
        content: (<div>Сообщения будут пересланы пользователю <b>{(user.type == 'private') ? user.first_name+' '+((user.last_name !== null) ? user.last_name : '') : user.title}</b>.<br/><br/><b>Хотите продолжить?</b></div>),
        okText: 'Да, переслать',
        okType: 'info',
        cancelText: 'Нет',
        onOk() {
          var ids = selectedIds.value.length > 0 ? selectedIds.value : [forwardMessageId.value];

          axios.post('/chat/dialogs/forward/send', {
            project_id: activeProjectId(),
            ids: ids,
            to_chat: user.telegram_id
          }).then(response => {
            //console.log(response);
            stopSelectMode();
            isForwardModalVisible.value = false;

            if(response.data.status == 'error') {
              message.error(response.data.message);
              return;
            }
          });
        },
        onCancel() {
          //console.log('Cancel');
        },
      });
    }

    const onChange = function(event)
    {
      inputValue.value = event;
      console.log(mediumEditorRef);


      mediumEditorRef.value.editor.subscribe("editableKeydownEnter", function (event, element) {
        if(event.ctrlKey) {
          if(inputValue.value.length == 0) return;

          handleSubmit();
          return;
        }

        if (event.shiftKey) {
          console.log('Editablekeydownenter', event, element);
          event.preventDefault()
          var node = mediumEditorRef.value.editor.selection.getSelectionStart(this.options.ownerDocument)
          mediumEditorRef.value.editor.util.insertHTMLCommand(this.options.ownerDocument, "<br>")
        }
      }.bind(mediumEditorRef.value.editor));

      var plainText = (mediumEditorRef.value.editor.elements[0].innerText).replace(/\&nbsp\;/gi, '').replace(/(?:\r\n|\r|\n)/g, '');

      if(replyTemplates.value.length > 0) {
        console.log('templates: ', replyTemplates.value);
        suggestedReplyTemplates.value = [];

        suggestedReplyTemplates.value = replyTemplates.value.filter(function(item) {
          return item.shortcode.toLowerCase() == plainText.toLowerCase();
        });

        console.log('suggested: ', suggestedReplyTemplates.value);
      }
    }

    const onEditorKeyup = function(event)
    {
      console.log('keyup: ', event);
    }

    const mediumEditorOptions = ref({
      toolbar: {
        buttons: ["bold", "italic", "underline", "strikethrough", "anchor"]
      },
      placeholder: {
        text: 'Введите сообщение...',
        hideOnClick: false
      },
      paste: {
        forcePlainText: false,
        cleanPastedHTML: true,
        cleanAttrs: ['class', 'style', 'dir'],
        cleanTags: ['meta', 'div', 'img', 'video', 'audio', 'iframe'],
        unwrapTags: ['span', 'h3', 'h2', 'h1', 'ol', 'ul', 'li']
      },
      imageDragging: false,
      spellcheck: false,
      autoList: false
    });

    const loadReplyTemplates = function()
    {
      axios.get('/chat/reply_templates/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            replyTemplates.value = response.data.templates;
          });
    }

    loadReplyTemplates();

    const useReplyTemplate = function(template, event)
    {
      if(event.target.classList.contains('reply-template-text') || event.target.classList.contains('reply-template-item')) {
        mediumEditorRef.value.editor.resetContent();
        mediumEditorRef.value.editor.setContent(template.text);
        replyTemplatesShown.value = false;
      }
    }

    const deleteReplyTemplate = function(template, event)
    {
      Modal.confirm({
        title:  'Вы точно хотите удалить этот скрипт?',
        icon: createVNode(ExclamationCircleOutlined),
        content: (<div>Скрипт исчезнет у всех пользователей системы.<br/><br/><b>Хотите продолжить?</b></div>),
        okText: 'Да, удалить',
        okType: 'danger',
        cancelText: 'Нет',
        onOk() {
          axios.post('/chat/reply_templates/delete', {
            project_id: activeProjectId(),
            id: template.id
          }).then(response => {
            console.log(response);

            if(response.data.status == 'error') {
              message.error(response.data.message);
              return;
            }

            replyTemplates.value = response.data.templates;
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    const switchReplyTemplatesMode = function()
    {
      replyTemplatesShown.value = !replyTemplatesShown.value;
    }

    const showReplyTemplateModal = function() {
      isReplyTemplateModalVisible.value = true;
    }

    const hideReplyTemplateModal = function() {
      isReplyTemplateModalVisible.value = false;
    }

    const handleSubmitReplyTemplateForm = function() {
      isReplyTemplateFormLoading.value = true;

      axios.post('/chat/reply_templates/add', {
        project_id: activeProjectId(),
        shortcode: addReplyTemplateFormState.shortcode,
        text: addReplyTemplateFormState.text
      }).then(response => {
        console.log(response);

        if(response.data.status == 'error') {
          message.error(response.data.message);
          return;
        }

        replyTemplates.value = response.data.templates;
        isReplyTemplateModalVisible.value = false;
        addReplyTemplateFormState.shortcode = null;
        addReplyTemplateFormState.text = '';

      }).finally(function() {
        isReplyTemplateFormLoading.value = false;
      });

    }

    const uploadFileRef = ref();

    const openUploadFile = function() {
      console.log(uploadFileRef.value);
      uploadFileRef.value.upload();
    }

    const getActionUrl = function(type) {
      if(type == 'image')
        return process.env.VUE_APP_API_BASE_URL +'file_manager/upload/image';

      if(type == 'video')
        return process.env.VUE_APP_API_BASE_URL +'file_manager/upload/video';

      if(type == 'document' || type === undefined)
        return process.env.VUE_APP_API_BASE_URL +'file_manager/upload/document';
    };
    const uploadData = ref({
      project_id: activeProjectId()
    });
    const uploadHeaders = ref({
      'Authorization': 'Bearer '+localStorage.getItem('accessToken')
    });

    const settingsAttachImage = ref({
      name: 'file',
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
          alert('В качестве изображения можно загрузить файлы формата .png, .jpg, .jpeg. Для других типов воспользуйтесь опцией Документ');
          return false;
        }


        if(file.size > 52428800) {
          alert('Максимальный размер файла для загрузки через веб-интерфейс - 50 Мб\n' +
              '\n' +
              'Если вам нужно загрузить файл большего размера, отправьте его в бота со своего аккаунта Telegram и перешлите это сообщение адресату.\n\n' +
              'Ограничение со стороны Telegram распространяется именно на ботов, поэтому мы никак не можем его обойти.');

          return false;
        }

        if(fileListImage.value.length > 1 || fileListVideo.value.length > 0 || fileListDocument.value.length > 0) {
          alert('За раз можно загрузить только один файл любого типа (включая видео и изображения). Удалите или отправьте другой файл для продолжения загрузки.');

          return false;
        }

        console.log('bu: ', file);
      },
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`Файл ${info.file.name} загружен успешно.`);
        } else if (info.file.status === 'error') {
          message.error(`Ошибка загрузки файла ${info.file.name}.`);
          fileListImage.value = [];
        }
      },
    });

    const settingsAttachVideo = ref({
      name: 'file',
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'video/quicktime';

        if (!isJpgOrPng) {
          alert('В качестве видео можно загрузить файлы формата .mp4, .mov. Для других типов воспользуйтесь опцией Документ');
          return false;
        }


        if(file.size > 52428800) {
          alert('Максимальный размер файла для загрузки через веб-интерфейс - 50 Мб\n' +
              '\n' +
              'Если вам нужно загрузить файл большего размера, отправьте его в бота со своего аккаунта Telegram и перешлите это сообщение адресату.\n\n' +
              'Ограничение со стороны Telegram распространяется именно на ботов, поэтому мы никак не можем его обойти.');

          return false;
        }

        if(fileListVideo.value.length > 1 || fileListImage.value.length > 0 || fileListDocument.value.length > 0) {
          alert('За раз можно загрузить только один файл любого типа (включая видео и изображения). Удалите или отправьте другой файл для продолжения загрузки.');

          return false;
        }

        console.log('bu: ', file);
      },
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`Файл ${info.file.name} загружен успешно.`);
        } else if (info.file.status === 'error') {
          message.error(`Ошибка загрузки файла ${info.file.name}.`);
          fileListVideo.value = [];
        }
      },
    });


    const settingsAttachDocument = ref({
      name: 'file',
      beforeUpload(file) {
        if(file.size > 52428800) {
          alert('Максимальный размер файла для загрузки через веб-интерфейс - 50 Мб\n' +
              '\n' +
              'Если вам нужно загрузить файл большего размера, отправьте его в бота со своего аккаунта Telegram и перешлите это сообщение адресату.\n\n' +
              'Ограничение со стороны Telegram распространяется именно на ботов, поэтому мы никак не можем его обойти.');

          return false;
        }

        console.log(fileListDocument.value);

        if(fileListDocument.value.length > 1 || fileListVideo.value.length > 0 || fileListImage.value.length > 0) {
          alert('За раз можно загрузить только один файл любого типа (включая видео и изображения). Удалите или отправьте другой файл для продолжения загрузки.');

          return false;
        }

        console.log('bu: ', file);
      },
      onChange(info) {
       if (info.file.status === 'done') {
          message.success(`Файл ${info.file.name} загружен успешно.`);
        } else if (info.file.status === 'error') {
          message.error(`Ошибка загрузки файла ${info.file.name}.`);
          fileListDocument.value = [];
        }
      },
    });

    const deleteAttachment = function(type)
    {
      if(type == 'document') {
        fileListDocument.value = [];
      }

      if(type == 'image') {
        fileListImage.value = [];
      }

      if(type == 'video') {
        fileListVideo.value = [];
      }
    }

    const isAnyFileUploading = computed(function() {
      var f = [...fileListDocument.value, ...fileListImage.value, ...fileListDocument.value];

      var i = f.filter(function(item) {
        return item.status !== "done";
      });

      return i.length > 0;
    });

    const loadCrmData = function() {
      axios.get('/chat/deals/status/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            // dealStatusList.value = response.data.list;

            var list = response.data.list;

            var items = [];

            list.forEach(function(item) {
              items.push({
                label: item.name,
                value: item.id
              });
            });

            dealStatusList.value = items;

          });

      axios.get('/chat/responsible/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            var list = response.data.list;

            var items = [{
              value: null,
              label: "Менеджер не выбран"
            }];

            list.forEach(function(item) {
              items.push({
                label: item.name,
                value: item.id
              });
            });

            responsibleUserList.value = items;

          });
    }

  const dealInfoChanged = function() {
     axios.post('/chat/deals/info/update', {
      project_id: activeProjectId(),
      id: dialogInfo.value.id,
      responsible_user_id: dialogInfo.value.deal.responsible_user_id,
      deal_status_id: dialogInfo.value.deal.deal_status_id,
      company_name: dialogInfo.value.deal.company_name,
      deal_sum: dialogInfo.value.deal.deal_sum,
      mobile_phone: dialogInfo.value.deal.mobile_phone,
      email: dialogInfo.value.deal.email,
      comment: dialogInfo.value.deal.comment
     }).then(response => {
       if(response.data.status == 'error') {
         message.error(`Ошибка обновления данных сделки`);
         return;
       }

       dialogInfo.value.deal = response.data.info.deal;
     });
  }

    loadCrmData();

    const loadFoldersList = function() {
      axios.get('/chat/folders/list', {params: {project_id: activeProjectId()}})
          .then(response => {
            foldersList.value = response.data.folders;
          });
    }

    loadFoldersList();

    const toggleDialogFolder = function(folder_id) {
      axios.get('/chat/folders/toggle', {
        params: {
          project_id: activeProjectId(),
          folder_id: folder_id,
          chat_id: dialogInfo.value.id
        }
      }).then(response => {
        dialogInfo.value = response.data.dialog;
      });
    }

    const openInNewTab = function(url) {
      window.open(url, '_blank', 'noreferrer');
    };

    const isDealStatusModalVisible = ref(false);
    const hideDealStatusModal = function() {
      isDealStatusModalVisible.value = false;
    }

    const showDealStatusModal = function() {
      isDealStatusModalVisible.value = true;
    }

    const isDialogFoldersModalVisible = ref(false);
    const hideDialogFoldersModal = function() {
      isDialogFoldersModalVisible.value = false;
    }

    const showDialogFoldersModal = function() {
      isDialogFoldersModalVisible.value = true;
    }

    const forwardSearchInput = reactive({text: ''});

    const resetForwardSearch = function() {
      forwardSearchInput.text = '';
      loadForwardList();
    }

    const onChangeForwardSearchInput = function(event) {
      // alert('search changed!');
      loadForwardList();
    }


    return {
      handleSubmit,
      left,
      inputValue,
      moment,

      isLoading,
      dialogInfo,
      isLoadingMsg,
      messages,
      lastMessageElem,
      perfectScroll,
      sortedMessages,
      handleLoadMore,
      isLazyLoading,
      scrollObserveContainer,
      handleMessageRead,
      setAllRead,
      setUnread,
      isMessageSending,

      getMessageMarkdown,
      deleteMessage,

      isSelectMode,
      startSelectMode,
      stopSelectMode,
      selectedIds,
      selectItem,

      plural,
      getAvatarUrl,

      massDeleteMessages,

      isForwardModalVisible,
      hideForwardModal,
      showForwardModal,
      forwardScroll,
      isLoadingForwardList,
      forwardList,
      handleScrolledForwardModal,
      isForwardLazyLoading,
      forwardCurrentPageOffset,
      confirmForward,
      forwardMessageId,
      setPinned,
      removeDialog,

      onChange,
      onEditorKeyup,
      mediumEditorRef,
      mediumEditorOptions,
      dialogContainerRef,
      dialogContainerHeight,
      dialogFooterRef,
      dialogFooterHeight,
      dialogChatboxHeight,

      replyTemplates,
      useReplyTemplate,
      deleteReplyTemplate,
      replyTemplatesShown,
      switchReplyTemplatesMode,
      isReplyTemplateModalVisible,
      showReplyTemplateModal,
      hideReplyTemplateModal,
      addReplyTemplateFormState,
      isReplyTemplateFormLoading,
      handleSubmitReplyTemplateForm,
      suggestedReplyTemplates,

      uploadFileRef,
      openUploadFile,
      getActionUrl,
      uploadData,
      uploadHeaders,

      settingsAttachImage,
      settingsAttachDocument,
      settingsAttachVideo,
      fileListDocument,
      fileListImage,
      fileListVideo,
      deleteAttachment,
      isAnyFileUploading,

      dealStatusList,
      responsibleUserList,

      dealInfoChanged,
      foldersList,
      activeFolders,
      toggleDialogFolder,

      openInNewTab,

      isMobile,
      isTablet,

      isDealStatusModalVisible,
      hideDealStatusModal,
      showDealStatusModal,

      hideDialogFoldersModal,
      isDialogFoldersModalVisible,
      showDialogFoldersModal,

      forwardSearchInput,
      resetForwardSearch,
      onChangeForwardSearchInput
    };
  },
};

export default SingleChat;
</script>
<style scoped>
.ps {
  height: 450px;
}

.custom-ant-upload {
  display: block !important;
}

.custom-ant-upload .ant-upload {
  display: block !important;
}

</style>
<style>
.ant-upload.ant-upload-select {
  display: block !important;
}
</style>
