<template>
  <sdPageHeader title="Платежные системы"> </sdPageHeader>
  <Main>
    <a-row :gutter="25">
      <a-col v-for="system in activeList" :key="system.id" :lg="6" :md="8" :sm="12" :xs="24">
        <sdCards headless>
          <div class="payment-card">
            <figure>
              <img :src="paymentMethods[system.id].image" alt="" style="max-width: 60%;" height="30" />
              <figcaption style="">
                <h3>{{ paymentMethods[system.id].name }}</h3>
                <span>{{ paymentMethods[system.id].description }}</span>
              </figcaption>
            </figure>
            <div class="user-info" style="justify-content: space-between; display: flex;">
              <router-link :to="'/settings/payments/'+system.id">
                <sdButton size="default" type="success">
                  Настройки
                </sdButton>
              </router-link>
            </div>
          </div>
        </sdCards>
      </a-col>

        <a-col v-for="system in inactiveList" :key="system.id" :lg="6" :md="8" :sm="12" :xs="24">
          <sdCards headless>
            <div class="payment-card">
              <figure>
                <img :src="paymentMethods[system.id].image" alt="" style="max-width: 60%;" height="30" />
                <figcaption style="">
                  <h3>{{ paymentMethods[system.id].name }}</h3>
                  <span>{{ paymentMethods[system.id].description }}</span>
                </figcaption>
              </figure>
              <div class="user-info" style="justify-content: space-between; display: flex;" v-if="!paymentMethods[system.id].disabled">
                <a :href="paymentMethods[system.id].about_link" target="_blank"><sdButton size="default" outlined="true" type="white">
                  Подробнее
                </sdButton></a>
                <router-link :to="'/settings/payments/'+system.id" >
                  <sdButton size="default" type="primary" style="">
                    Подключить
                  </sdButton>
                </router-link>
              </div>

              <div class="user-info" style="justify-content: space-between; display: flex;" v-else>
                <sdButton size="default" type="white" disabled="true" style="">
                  Интеграция скоро будет
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
  .payment-card {
    padding-top: 20px;
  }

  .payment-card h3 {
    margin-top: 30px;
  }
</style>
<script>
import { BasicFormWrapper, Main } from '../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { paymentMethods } from '@/config/enum';

const Payments = {
  name: 'Payments',
  components: {
    BasicFormWrapper,
    PlusOutlined,
    MinusOutlined,
    Main,
    SpinerWraperStyle
  },
  setup() {
    const { state, dispatch } = useStore();

    const inactiveList = ref([]);
    const activeList = ref([]);


    axios.get('/settings/payments/list', {
      params: {project_id: activeProjectId()}
    }).then(response => {
      inactiveList.value = response.data.inactive;
      activeList.value = response.data.active;
    });


    return {
      inactiveList,
      activeList,

      paymentMethods
    };
  },
};

export default Payments;
</script>
