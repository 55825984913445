<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Список пользователей">
        <!-- <template #subTitle>
        </template> -->
        <!-- <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Добавить тариф</a>
          </sdButton>
        </template> -->
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="24">
        <sdCards headless>
          <UserTableStyleWrapper>
            <div class="contact-table">
              <TableWrapper class="table-responsive">
                <a-table
                  :dataSource="packagesTableData"
                  :columns="packagesTableColumns"
                />
              </TableWrapper>
            </div>
          </UserTableStyleWrapper>
        </sdCards>
      </a-col>
      <sdModal
        :type="localState.modalType"
        title="Добавить новый тариф"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
                <a-form-item label="Название (будет отображаться в чат-боте)" name="name">
                  <a-input v-model:value="addFormState.name" placeholder="🔥 На месяц" />
                </a-form-item>

                <a-form-item label="Стоимость (в рублях)" name="price">
                  <a-input type="number" v-model:value="addFormState.price" placeholder="199" />
                </a-form-item>

                <a-form-item name="period" label="Период подписки">
                  <a-input-group compact>
                    <a-input style="width: 30%" v-model:value="addFormState.period_value" placeholder="7" :disabled="addFormState.period_unit == 'forever'"/>
                    <a-select v-model:value="addFormState.period_unit" style="width: 70%;">
                      <a-select-option value="days">дней</a-select-option>
                      <a-select-option value="months">месяцев</a-select-option>
                      <a-select-option value="years">лет</a-select-option>
                      <a-select-option value="forever">навсегда</a-select-option>
                    </a-select>
                  </a-input-group>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleOk" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Сохранить тариф'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
      <sdModal
        :type="localState.modalType"
        title="Редактировать тариф"
        :visible="localState.editVisible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contactEdit" :rules="rules" :model="editFormState" ref="editFormRef" >
                <a-form-item label="Название (будет отображаться в чат-боте)" name="name">
                  <a-input v-model:value="editFormState.name" placeholder="🔥 На месяц" />
                </a-form-item>

                <a-form-item label="Стоимость (в рублях)" name="price">
                  <a-input type="number" v-model:value="editFormState.price" placeholder="199" />
                </a-form-item>

                <a-form-item name="period" label="Период подписки">
                  <a-input-group compact>
                    <a-input style="width: 30%" v-model:value="editFormState.period_value" placeholder="7" :disabled="editFormState.period_unit == 'forever'"/>
                    <a-select v-model:value="editFormState.period_unit" style="width: 70%;">
                      <a-select-option value="days">дней</a-select-option>
                      <a-select-option value="months">месяцев</a-select-option>
                      <a-select-option value="years">лет</a-select-option>
                      <a-select-option value="forever">навсегда</a-select-option>
                    </a-select>
                  </a-input-group>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleEditOk" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Сохранить тариф'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<style lang="scss" scoped>
  .ant-table-column-title {
    font-size: 14px !important;
  }

  table tbody tr td {
    font-weight: normal !important;
  }
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';

import { periodUnits } from '@/config/enum';

import moment from 'moment';

const Users = {
  name: 'Users',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {
    const addFormState = reactive({
      name: '',
      price: '',
      period_unit: 'days',
      period_value: '1'
    });

    const editFormState = reactive({
      name: '',
      price: '',
      period_unit: '',
      period_value: '',
      id: ''
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(false);

    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      price: [
        {
          validator: (rule, value, callback) => {
            if(value === '' || value === undefined || value === null)
              return Promise.reject('Field is empty');

            return Promise.resolve();
          },
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur'
        }
      ],
      period_value: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        }
      ],
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.name = data.name;
      editFormState.id = data.id;
      editFormState.price = data.price;
      editFormState.period_unit = data.period_unit;
      editFormState.period_value = data.period_value;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const handleOk = values => {
      addFormRef.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios.post('/settings/packages/add', {
            project_id: activeProjectId(),
            name: addFormState.name,
            price: addFormState.price,
            period_unit: addFormState.period_unit,
            period_value: addFormState.period_value
          }).then(response => {
            usersList.value = response.data.packages;

            handleCancel();
          }).finally(() => {
            isLoading.value = false;
          });
        });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios.post('/settings/packages/edit', {
            project_id: activeProjectId(),
            id: editFormState.id,
            name: editFormState.name,
            price: editFormState.price,
            period_unit: editFormState.period_unit,
            period_value: editFormState.period_value
          }).then(response => {
            usersList.value = response.data.packages;

            handleCancel();
          }).finally(() => {
            isLoading.value = false;
          });
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onHandleDelete = id => {
      alert('Удаление тарифов пока недоступно, но мы работаем над этим. Вы можете удалить тариф через техническую поддержку или просто отредактировать его.');
    };

    const usersList = ref([]);
    const pkgs = computed(() => usersList.value);

    const renderUsername = function(username) {
      if(username == null)
        return <div></div>;

      return <a href={'https://t.me/'+username} target="_blank">{'@'+username}</a>;
    };

    const packagesTableData = computed(() => {
      return usersList.value
        .sort((a, b) => {
          return a.price - b.price;
        })
        .map(pkg => {
          const { id, telegram_id, first_name, last_name, username, start_tag, is_kicked, created_at } = pkg;

          return {
            key: id,
            telegram_id,
            name: (<div class="name">{first_name} {last_name}</div>),
            name: (
              <div class="user-info">
                <figure>
                  <img style={{ width: '40px', borderRadius: '50%' }} src={'https://ui-avatars.com/api/?background=355DFF&color=FFFFFF&name='+first_name+((last_name == null)?'':' '+last_name)+'&size=128'} alt="" />
                </figure>
                <figcaption>
                  <sdHeading class="user-name" as="h6" style="margin-bottom: 0px;">
                    {first_name} {last_name}
                  </sdHeading>
                  <span class="user-designation">
                    {renderUsername(username)}
                  </span>
                </figcaption>
              </div>
            ),
            start_tag,
            created_at: (<div>{moment(created_at).format('DD.MM.YYYY в HH:MM')}</div>),
            status: (is_kicked == 1) ? (<div>Заблокировал бот</div>) : (<div>Активен</div>),
            action: (
              <Action class="table-actions">
                <sdDropdown
                  class="wide-dropdwon"
                  overlay={
                    <>
                    </>
                  }
                  action={['click']}
                >
                  <sdButton class="btn-icon" type="info" to="#" shape="circle">
                    <sdFeatherIcons type="more-vertical" size={16} />
                  </sdButton>
                </sdDropdown>
              </Action>
            )
          };
        });
    });

    axios.get('/users/list', {params: {project_id: activeProjectId()}})
    .then(response => {
      usersList.value = response.data.users;
    });


    const packagesTableColumns = [
      {
        title: 'ID Telegram',
        dataIndex: 'telegram_id',
        key: 'telegram_id'
      },
      {
        title: 'Пользователь',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Метка UTM',
        dataIndex: 'start_tag',
        key: 'start_tag',
      },
      {
        title: 'Дата подписки',
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];


    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      packagesTableColumns,
      packagesTableData,
      usersList,
      pkgs,

      rules,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading
    };
  },
};

export default Users;
</script>
