<template>
  <div v-if="isTaskLoading">loading</div>
  <div v-else :class="taskId === id ? 'sDash_kanvan-task__editable' : ''">
    <h4 class="sDash_kanvan-task__title">
      <a @click="() => showModal(data)" href="#">
        {{ data.chat.first_name }} {{ data.chat.last_name }} {{ data.chat.title }}
      </a>
      <a href="#" class="btn-edit" @click="() => onBackShadow(id)">
        <sdFeatherIcons type="edit-2" size="12" />
      </a>
    </h4>

  </div>
</template>
<script>
import { computed, toRefs, ref } from 'vue';
import propTypes from 'vue-types';
import { useStore } from 'vuex';

const KanbanBoardItem = {
  name: 'KanbanBoardItem',
  props: {
    data: propTypes.object,
    showModal: propTypes.func,
    onBackShadow: propTypes.func,
    taskId: propTypes.string,
    onTaskTitleUpdate: propTypes.func,
    onTaskTitleDelete: propTypes.func,
  },
  setup(props) {
    const { state } = useStore();
    const isTaskLoading = computed(() => state.KanbanBoard.tsLoading);
    const { data } = toRefs(props);
    const { telegram_chat_id, id } = toRefs(data.value);
    const value = ref(telegram_chat_id.value);

    const onTaskTitleChange = e => {
      value.value = e.target.value;
    };

    return {
      onTaskTitleChange,
      isTaskLoading,
      telegram_chat_id,
      id,
      value,
      data
    };
  },
};

export default KanbanBoardItem;
</script>
