<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form :model="formState" layout="vertical" v-if="!isStep2">
        <sdHeading as="h3">Оставьте номер телефона</sdHeading>
        <p class="forgot-text">
          Отправим на него код для подтверждения регистрации. Будем присылать уведомления о выводе денег для обеспечения безопасности ваших средств.
        </p>

        <p class="forgot-text">
          Обещаем не беспокоить по пустякам, позвоним только в случае крайней необходимости.
        </p>

        <a-form-item label="Ваш телефон" name="phone" style="margin-top: 20px;">
          <vue-tel-input ref="phoneRef" :v-model="phoneNumber" defaultCountry="ru" :inputOptions="{placeholder: '+7 999 123 45 67'}" mode="international" @input="onInputTel" @blur="validateNumber"></vue-tel-input>
          <div class="ant-form-explain" style="color: red; margin-top: 15px" v-if="isValidNumber === false || phoneObject.country.iso2 != 'RU'">Пожалуйста, введите настоящий номер телефона. Сейчас поддерживается только РФ.</div>
        </a-form-item>

        <!-- {{ phoneObject }} -->

        <!-- {{ phoneNumber }} -->

        <!-- {{ user }} -->

        <a-form-item>
          <sdButton class="btn-reset" htmlType="submit" type="primary" size="large" @click="savePhone" :disabled="!phoneObject.valid || phoneObject.country.iso2 != 'RU' || isLoading">
            {{ isLoading ? 'Загрузка...' : 'Подтвердить телефон' }}
          </sdButton>
        </a-form-item>
      </a-form>

      <a-form @finish="handleSubmitStep2" :model="formStateStep2" :rules="rulesStep2" ref="formRef" layout="vertical" v-if="isStep2">
        <sdHeading as="h3">Введите код подтверждения</sdHeading>
        <p class="forgot-text">
          Звоним на ваш номер {{ phoneObject.formatted }}.
        </p>

        <p class="forgot-text">
          <b>Введите последние 4 цифры номера, с которого мы позвонили.</b>
        </p>

        <div v-if="step2Error" style="padding-top: 0px; padding-bottom: 20px">
          <sdAlerts
            :outlined="true"
            :closable="false"
            :showIcon="true"
            message=""
            :description="step2Error"
            type="error"

          />
        </div>


        <a-form-item name="code" label="Код подтверждения">
          <a-input type="number" v-model:value="formStateStep2.code" />
        </a-form-item>

        <a-form-item>
          <sdButton class="btn-reset" htmlType="submit" type="primary" size="large"  :disabled="formStateStep2.code == '' || isLoading">
            {{ isLoading ? 'Загрузка...' : 'Подтвердить телефон' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive } from 'vue';
import { AuthWrapper } from './style';
import { useState } from 'react';
import { computed, ref } from 'vue';
import axios from '@/config/axios.js'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const ForgotPassword = {
  name: 'ForgotPassword',
  components: { AuthWrapper },
  // data() {
  //   return {
  //     phoneObject: {
  //       formatted: '',
  //       valid: false,
  //       country: undefined,
  //     }
  //   }
  // },
  setup() {
    const { state, dispatch } = useStore();

    const router = useRouter();

    const phoneNumber = ref('+79994421883')
    const isValidNumber = ref(null);
    const isLoading = ref(false);

    const phoneRef = ref(null);

    const isStep2 = ref(false);
    const formRef = ref();
    const step2Error = ref(false);

    const formState = reactive({});

    const formStateStep2 = reactive({
      code: '',
    });

    const rulesStep2 = {
      code: [
        {
          required: true,
          min: 4,
          max: 4,
          message: 'Код подтверждения состоит из 4 символов',
          trigger: 'blur'
        }
      ],
    };

    const phoneObject = ref({
      formatted: '',
      valid: false,
      country: {
        iso2: "RU"
      },
      countryCallingCode: 7

    });

    const user = ref({});

    axios.get('/auth/me')
      .then(response => {
        console.log('>> VerifyMobile: Got user');
        user.value = response.data.user;

        phoneRef.value.phone = response.data.user.phone;

        if(response.data.user.is_phone_verified == 1) {
          router.push('/');
        }
      });

    // console.log(phoneObject.value);

    const onInputTel = (formattedNumber, obj) => {
      if(obj === undefined)
        return;

      phoneObject.value = obj;
    };

    const validateNumber = () => {
      // if(phoneObject === undefined)
      //   return;

      isValidNumber.value = phoneObject.value.valid;
    }

    const savePhone = async () => {
      isLoading.value = true;

      await axios
          .post('/auth/phoneConfirm', {phone: phoneObject.value.number})
          .then(response => {
            console.log(response);

            if(response.data.status == 'error' && response.data.error == 'already_verified') {
              router.push('/');
              return
            }

            if(response.data.status == 'ok')
              isStep2.value = true;
          })
          .catch(error => {

          })
          .finally(() => {
            isLoading.value = false;
          });
    };

    const handleSubmitStep2 = async () => {
      isLoading.value = true;

      console.log(formRef.value);
      console.log(formStateStep2);

      formRef.value
        .validate()
        .then(() => {
          step2Error.value = false;

          axios
              .post('/auth/phoneConfirmCode', {code: formStateStep2.code})
              .then(response => {
                console.log(response);

                if(response.data.status == 'error') {
                  step2Error.value = response.data.message;
                }

                if(response.data.status == 'ok') {
                  dispatch('checkAuth').then(() => {
                    console.log('finished!!!');
                    router.push('/');
                  });

                  return
                }
              })
              .catch(error => {

              })
              .finally(() => {
                isLoading.value = false;
              });
        })
        .catch(error => {
          console.log('error', error);
        }).finally(()=>{
            isLoading.value = false
        });

      return;
    };

    return {
      handleSubmitStep2,
      formState,
      formStateStep2,
      phoneNumber,
      onInputTel,
      phoneObject,
      isValidNumber,
      validateNumber,
      isLoading,
      savePhone,
      isStep2,
      rulesStep2,
      formRef,
      user,
      phoneRef,
      step2Error
    };
  },
};

export default ForgotPassword;
</script>
<style lang="css">
  .vue-tel-input {
    border-radius: 4px !important;
    padding: 7px 11px  !important;
    color: #272B41  !important;
    font-size: 14px  !important;
    border: 1px solid #E3E6EF !important;
  }
</style>
