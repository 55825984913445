<template>
  <div>
    <sdPageHeader title="Social Media Dashboard">
      <template v-slot:buttons>
        <div class="page-header-actions">
          <sdCalendarButton />
          <sdExportButton />
          <sdShareButton />
          <sdButton size="small" type="primary">
            <sdFeatherIcons type="plus" size="14" />
            Add New1
          </sdButton>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <a-row justify="center" :gutter="25">
        <a-col :xxl="8" :lg="24" :xs="24">
          <Suspense>
            <template #default>
              <SocialMediaOverview />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :xxl="16" :xs="24">
          <Suspense>
            <template #default>
              <FacebookOverview />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :xxl="8" :xs="24">
          <Suspense>
            <template #default>
              <YoutubeSubscribers />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :xxl="8" :md="8" :xs="24">
          <Suspense>
            <template #default>
              <TwitterOverview />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :xxl="8" :md="8" :xs="24">
          <Suspense>
            <template #default>
              <InstagramOverview />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :xxl="8" :md="8" :xs="24">
          <Suspense>
            <template #default>
              <LinkedinKeyMetrics />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col :xxl="16" :xs="24">
          <Suspense>
            <template #default>
              <SocialTrafficMetrics />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { defineAsyncComponent } from 'vue';

import axios from '@/config/axios'

const SocialMediaOverview = defineAsyncComponent(() => import('./overview/index/SocialMediaOverview'));
const FacebookOverview = defineAsyncComponent(() => import('./overview/index/FacebookOverview'));
const YoutubeSubscribers = defineAsyncComponent(() => import('./overview/index/YoutubeSubscribers'));
const TwitterOverview = defineAsyncComponent(() => import('./overview/index/TwitterOverview'));
const InstagramOverview = defineAsyncComponent(() => import('./overview/index/InstagramOverview'));
const LinkedinKeyMetrics = defineAsyncComponent(() => import('./overview/index/LinkedinKeyMetrics'));
const SocialTrafficMetrics = defineAsyncComponent(() => import('./overview/index/SocialTrafficMetrics'));

export default {
  name: 'SocialMedia',
  components: {
    Main,
    SocialMediaOverview,
    FacebookOverview,
    YoutubeSubscribers,
    TwitterOverview,
    InstagramOverview,
    LinkedinKeyMetrics,
    SocialTrafficMetrics,
  },
  async setup() {
    axios.get('/user').then(response => {
      console.log(response);
    });


  },
};
</script>
